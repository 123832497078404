.appointBoxM {
  .ant-drawer-mask {
    background: white;
  }

  .select-size {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
  }

  .info {
    word-break: break-all;
  }

  .proviceS {
    width: 100%;
    height: 40px;
    border: 1px solid #e0e0e0;
    padding: 0 16px;
  }

  .leftPic {
    width: 132px;
    height: 132px;
    margin-right: 16px;
  }

  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .bottom {
    margin-bottom: 17px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .price {
    font-family: 'Zona Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .gray {
    margin-right: 8px;
    text-decoration: line-through;
    color: rgba(33, 33, 33, 0.4);
  }

  .final_price {
    color: rgba(193, 40, 27, 1);
  }

  .right_content {
    position: relative;

    .label {
      color: #757575;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .ant-form-item-label>label::after {
      content: '';
    }

    .ant-form-item-label .ant-form-item-required::after {
      content: '*';
      display: inline-block !important;
      color: #c1281b;
      font-size: 0.22222rem;
    }

    .ant-form-item {
      .ant-row {
        flex-direction: column;

        .ant-form-item-required {
          width: 100%;
          font-size: 13px;
          color: #757575;
        }
      }
    }

    .mb16 {
      margin-bottom: 16px;
    }

    .fw600 {
      font-weight: 600;
    }

    .fw700 {
      font-weight: 700;
    }

    .color4242 {
      color: #424242;
    }

    .w384 {
      width: 100%;
      height: 56px;
    }

    .w160 {
      width: 160px;
    }

    .mr16 {
      margin-right: 16px;
    }

    .provice {
      position: relative;

      .surname {
        position: absolute;
        left: 16px;
        top: 10px;
      }
    }

    .ant-input-affix-wrapper {
      padding: 3px 16px;
    }
  }

  .ant-drawer-body {
    padding: 0 20px;
  }

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-header {
    padding: 13px 0 10px 0;
    border: none;

    .ant-drawer-close {
      color: #212121;
    }
  }

  .ant-select-selector {
    border: 1px solid #e0e0e0 !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #e0e0e0 !important;
    box-shadow: none;
  }

  .ant-input {
    &::placeholder {
      color: #757575 !important;
      font-size: 13px;
    }
  }
}