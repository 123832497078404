.pc-login {
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';
  
  .logintitle {
    height: 27px;
    line-height: 27px;
  }

  .login-box {
    margin: 45px auto 100px;
    padding: 0 150px;
    box-sizing: border-box;
  }

  .otherLogin {
    height: 15px;
    line-height: 15px;

    a {
      margin-left: 20px;
    }

    i {
      font-size: 20px;
    }
  }

  .left {
    margin-right: 100px;
  }

  .right {
    margin-left: 100px;
    text-align: left;

    .list {
      padding-bottom: 40px;
      font-size: 13px;

      li {
        margin-bottom: 24px;
      }
    }

    .signBtn {
      display: block;
      width: 400px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background-color: #212121;
      text-align: center;
    }
  }

  .left,
  .right {
    width: 400px;

    .loginForm,
    .orderForm,
    .codeLoginForm {
      text-align: left;
      margin-top: 21px;

      .ant-form-item {
        display: inline-block;
        width: 400px;
        vertical-align: middle;
      }

      img,
      button {
        width: 100px;
        height: 40px;
        vertical-align: middle;
      }
    }
    .isRemeber {
      .ant-form-item-control-input {
        height: 24px;
        min-height: 24px;
      }
    }
  }

  .divider {
    margin-top: 70px;
    height: 307px;
    border-left: 1px solid #ddd;
  }
}
