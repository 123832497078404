.pc-hot-area {
    position: relative;
    overflow: hidden;

    video {
        display: block;
    }

    .mobATagBgStyle {
        display: block;
        text-align: center;
        border-radius: 2px;
        background-color: rgba(255, 0, 0, .3);
    }

    .mob_play_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        font-size: 52px;
        text-align: center;
        color: #ffffff;
    }
}