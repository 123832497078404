@import '@/style/mixin';

.GoodDetaileM {
  .ant-drawer-mask{
    background: white;
  }
  .ant-drawer-content-wrapper {
    position: relative;
  }
  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .map-dialog-M {
    .shop-nav-M {
      display: flex;
      align-items: center;
      margin-top: 32px;

      .item {
        display: flex;
        align-items: center;
        width: 50%;
        line-height: 18px;
        font-size: 14px;
        color: #212121;
        font-weight: 500;
      }
    }

    .ant-modal-main {
      margin-top: 27px;
      padding: 0;

      .section-left {
        margin-top: 24px;
        height: 412px;
      }

      .section-right {
        overflow: auto;

        @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

        .name {
          line-height: 24px;
          font-size: 24px;
          color: rgba(33, 33, 33, 1);
          font-weight: 600;
        }

        .address {
          line-height: 18px;
          font-size: 13px;
          color: rgba(66, 66, 66, 1);
        }

        .info {
          line-height: 18px;
          font-size: 13px;
          color: rgba(33, 33, 33, 1);

          .item {
            display: flex;
            align-items: center;

            &:not(:nth-of-type(1)) {
              margin-top: 14px;
            }
          }
        }

        .time {
          .title {
            line-height: 18px;
            font-size: 16px;
            color: rgba(33, 33, 33, 1);
            font-weight: 600;
          }

          .main {
            margin-top: 8px;
            line-height: 18px;
            font-size: 13px;
            color: rgba(66, 66, 66, 1);
          }
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }
  .ant-drawer-header {
    padding: 13px 0 0 0;
    border: none;
    .ant-drawer-close {
      color: #212121;
    }
  }
}
