.appointOrderPC {
  height: inherit;
  flex: 1;
  margin-bottom: 220px;

  .container_address {
    width: 350px;
    margin: auto;
    height: 100%;
    position: relative;
    margin-bottom: 220px;
    .addAddress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #212121;
      color: #fff;
      font-family: 'FZLanTingHei-M-GBK';
    }
    .nullAdress {
      margin: auto;
      font-family: 'FZLanTingHeiS-R-GB';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #212121;
    }
  }

  .addApponit {
    width: 103px;
    height: 50px;
    background: #212121;
    color: #ffffff;
  }
  .books {
    .opa6 {
      opacity: 0.6;
    }
    .bookItem {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 29px 24px 19px 24px;
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}
.appointInfo {
  .horizontal {
    padding: 13px 0;
    border-top: 1px solid #212121;
    border-bottom: 1px solid #212121;
  }
  .ant-modal-body {
    padding: 60px 20px 50px 20px;
  }
  .ant-modal-close-x {
    color: #212121;
  }
  .goodInfo {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    .leftPic {
      width: 132px;
      height: 132px;
      margin-right: 16px;
    }
    .fw600 {
      font-weight: 600;
    }
    .fw700 {
      font-weight: 700;
    }
    .color4242 {
      color: #424242;
    }
    .colorBD {
      color: #bdbdbd;
    }
    .price {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.466667px;

      color: #212121;

      &.gray {
        margin-right: 8px;
        text-decoration: line-through;
        color: rgba(33, 33, 33, 0.4);
      }

      &.final_price {
        color: rgba(193, 40, 27, 1);
      }
    }
  }
}
.appointDelect {
  height: 500px;
  margin: auto;
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn {
    width: 120px;
    height: 50px;
    background: #fff;
    border: 1px solid #212121;
    color: #212121;
    &:first-child {
      margin-right: 16px;
    }
    &:last-child {
      background-color: #212121;
      color: #fff;
    }
  }
  .ant-modal-close-x {
    color: #212121;
  }
}
.noContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  .noappi {
    margin: auto;
    width: 350px;
  }
}
