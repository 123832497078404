.checkout-invoice {
    margin-top: 24px;
    .input376 {
        width: 100%;
    }
    .filter{
        margin-bottom: 24px;
      }
    .fx-sb {
        .ant-form-item {
            width: 49%;
        }
    }
    .ant-form-item:last-child{
        margin-bottom: 0;
    }
    .ant-radio-wrapper{
        margin-right: 50px;
    }
}