.pc-cart {
  margin: auto;
  color: #212121;
  padding: 24px 12px !important;
  box-sizing: border-box !important;

  .cart-header {
    &-text {
      &-1 {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #242424;
      }

      &-2,
      &-3,
      &-4 {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #757575;
      }

      &-2 {
        margin-left: 8px;
      }

      &-4 {
        margin-left: 4px;
      }

      &-3 {
        margin-left: 4px;
        font-family: 'Zona Pro';
        position: relative;
        top: 1px;
      }
    }

  }

  .go-shopping {
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    border-bottom: 1px solid currentColor;
    cursor: pointer;
  }

  .cart-footer {
    margin-top: 24px;
  }
}