.media_block {
  position: relative;
  margin: auto;
  overflow: hidden;
  margin: 0 4px;
  width: auto;

  .mediaBox {
    width: auto;
    overflow: hidden;
    height: auto;
    cursor: pointer;
    display: block;
    margin: auto;
    // margin-bottom: 30px;

    .media {
      height: auto;
      overflow: hidden;
      width: 100%;
    }
  }

  .scale {
    // width:100%;
    transition: all 0.5s ease-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}