@import '@/style/mixin';

.shop-container {
  margin: 0 auto;
  width: 74%;
  padding: 36px 0 50px;

  .shop-main {
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    padding-top: 50px;
    width: 100%;

    .section-left {
      width: 30%;
      min-width: 270px;

      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      letter-spacing: 0.466667px;

      color: #212121;

      .title {
        height: 21px;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.466667px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        color: #212121;
      }

      .city-box {
        margin-top: 8px;
        padding-right: 40px;

        .item {
          height: 48px;
          line-height: 60px;
          line-height: 48px;
          border-bottom: 1px solid #eee;
          font-size: 13px;
          color: #212121;

          .ant-dropdown-trigger {
            display: block;
            width: 100%;

            .ant-space {
              display: flex;
              align-items: center;
              width: 100%;

              .ant-space-item:nth-of-type(1) {
                flex: 1;
              }
            }
          }

          &.search-box {
            position: relative;
            display: flex;
            align-items: flex-end;
            align-items: center;
            width: 100%;

            // line-height: 34px;
            .ant-input-affix-wrapper {
              width: 90%;
              padding-left: 0;
              border: none;

              // padding: 0 0 8px;
              box-shadow: none;
            }

            .icon-sousuo-btn {
              position: absolute;
              top: 0;
              right: -4px;
            }
          }
        }

        .error-tip {
          color: #c1281b;
          font-size: 10px;
        }
      }

      .result-box {
        margin-top: 32px;
        padding-right: 8px;

        .title {
          height: 16px;
          line-height: 16px;
          margin-bottom: 15px;
          font-size: 14px;
        }

        .list {
          padding-right: 28px;
          height: 376px;
          overflow: auto;
          @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

          .item {
            padding: 20px 0;
            border-bottom: rgba(0, 0, 0, 0.08) 1px solid;

            .city-info {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              font-family: 'Zona Pro';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 15px;
              letter-spacing: 0.466667px;

              color: rgba(33, 33, 33, 0.6);

              .name {
                font-family: 'FZLanTingHei-M-GBK';
                line-height: 16px;

                color: #212121;
              }
            }

            .address,
            .business-hours {
              margin-top: 8px;

              font-family: 'Zona Pro';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;

              letter-spacing: 0.466667px;

              color: rgba(33, 33, 33, 0.6);
            }

            .contact-way {
              margin-top: 20px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .section-right {
      flex: 1;
      height: 596px;
    }
  }
}

.map-dialog-wrap {
  z-index: 9999999;
}

.map-dialog {
  .ant-modal-body {
    display: flex;
    align-items: center;
    padding: 0;

    .section-left {
      width: 594px;
      height: 594px;
    }

    .section-right {
      padding: 24px;
      overflow: auto;
      width: 432px;
      height: 594px;

      @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

      .name {
        line-height: 24px;
        font-size: 24px;
        color: rgba(33, 33, 33, 1);
        font-weight: 600;
      }

      .address {
        line-height: 18px;
        font-size: 13px;
        color: rgba(66, 66, 66, 1);
      }

      .info {
        line-height: 18px;
        font-size: 13px;
        color: rgba(33, 33, 33, 1);

        .item {
          display: flex;
          align-items: center;

          &:not(:nth-of-type(1)) {
            margin-top: 14px;
          }
        }
      }

      .time {
        .title {
          line-height: 18px;
          font-size: 16px;
          color: rgba(33, 33, 33, 1);
          font-weight: 600;
        }

        .main {
          margin-top: 8px;
          line-height: 18px;
          font-size: 13px;
          color: rgba(66, 66, 66, 1);
        }
      }
    }
  }
}

.top35 {
  margin-top: 35px;
}

.err-notice {
  position: absolute;
  left: 0;
  bottom: -35px;
  color: rgb(239, 77, 77);
  font-size: 12px;
}

.ant-dropdown-placement-bottomLeft {
  z-index: 999;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;


  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #e5e5e5;
  }
}