.pc-full-screen-swiper-base {
  position: relative;
  width: 100%;

  .menu-left {
    left: 14px;
  }

  .menu-right {
    right: 0;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 31px;
    height: 93px;
    line-height: 93px;
  }

  .ant-carousel {
    width: 100%;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .slick-dots {
      z-index: 9;
    }

    .media_content .media {
      min-height: 306px;
    }
  }

  .slick-dots {
    bottom: -32px;
    padding: 0 14px;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    margin-right: 0;

    li {
      width: 6px !important;
      height: 6px !important;

      .dot-item {
        margin: 0;
        margin-right: 10px;
        width: 6px !important;
        height: 6px !important;
        border-radius: 50%;
        background-color: rgba(33, 33, 33, 0.2) !important;

        &.dot-video {
          width: 0 !important;
          height: 0 !important;
          border: 3px solid transparent;
          border-left-color: rgba(33, 33, 33, 0.2);
          border-left-width: 6px;
          border-radius: 0;
          background: transparent !important;
        }
      }
    }

    .slick-active {
      .dot-item {
        background-color: rgba(33, 33, 33, 1) !important;

        &.dot-video {
          border-left-color: rgba(33, 33, 33, 1);
        }
      }
    }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;
  }
}