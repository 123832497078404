.listProduct {
  width: 100%;

  .jc-carousel {
    position: relative;
  }

  .swiper-menu {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 5px;

    &.external {
      left: -40px;
    }
  }

  .menu-right {
    right: 10px;

    &.external {
      right: -40px;
    }
  }
}