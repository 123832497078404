.mapCard-container,
.mapCard-page {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-info1,.map-info2{
  width:200px;
  white-space:initial;
  text-align:left;
  p{
    line-height: 1.2;
    margin-bottom: 6px;
  }
  p:last-child{
    margin-bottom: 0;
  }
}
.map-info2{
  width: 250px;
}
.mapCard-page>div {
  z-index: 3 !important;
}

.mapCard-container {
  #searchPart {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    line-height: initial;
  }
}