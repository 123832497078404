.OrderDetailPC {
  margin-top: 26px;
  max-width: 1440px;
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';

  .mumberName {
    font-family: 'Zona Pro';
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    display: flex;
    justify-content: center;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;

    .leftDtaile {
      width: 931px;
      margin-right: 40px;

      .giftCard {
        padding: 12px 16px;
        height: 80px;
        margin-top: 24px;
        width: 100%;
      }

      .topInform {
        padding: 0 20px;
        height: 40px;
        width: 100%;
        background-color: #fafafa;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'FZLanTingHeiS-R-GB';
      }

      .bottomInform {
        .order-card {
          padding: 24px 20px;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
        }

        .to-cart-tips {
          margin-top: 8px;
          font-size: 13px;
          font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
        }

        .ItemBlock {
          display: flex;

          .w180 {
            width: 180px;
            height: 180px;
          }

          .selectInform {
            flex: 1;
            margin-left: 16px;
            display: flex;
            justify-content: space-between;

            .left_Item {
              position: relative;
            }

            .name {
              font-family: 'Zona Pro';
              margin-bottom: 8px;
            }

            .sku {
              color: rgba($color: #212121, $alpha: 0.44);
              margin-bottom: 8px;
            }

            .shortBlock {
              padding: 3px 8px;
              background: #fff;
              border: 1px solid #D7D7D7;
              font-family: 'FZLanTingHeiS-R-GB';
              font-size: 12px;
              line-height: 18px;
              color: #212121;
              margin-right: 8px;
            }

            .data {
              opacity: 0.8;
            }

            .select_color {
              position: absolute;
              bottom: 13px;
              font-family: 'Zona Pro';
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }

            .select_size {
              position: absolute;
              bottom: 0;
              font-family: 'Zona Pro';
            }

            .color {
              margin-right: 10px;
            }

            .right_price {
              position: relative;
              font-family: 'FZLanTingHeiS-R-GB';

              .price {
                position: absolute;
                bottom: 0;
                font-family: 'Zona Pro';
                right: 0;
              }
            }
          }
        }
      }

      .payList {
        font-family: 'FZLanTingHeiS-R-GB';

        .mt26 {
          margin-top: 26px;

          // .ant-radio-input:focus + .ant-radio-inner {
          //   box-shadow: none; //按钮点击去除投影效果
          // }
          // .ant-radio-wrapper {
          //   display: flex;
          //   align-items: center;
          //   margin-right: 50px;
          // }
          .icon {
            width: fit-content;
            margin-right: 9px;
          }

          .icon1 {
            width: 20px;
            margin-right: 9px;
          }
        }

        .payed {
          cursor: pointer;
          background: #212121;
          margin: auto;
          width: 352px;
          height: 50px;
          margin-top: 53px;
          font-family: 'FZLanTingHei-M-GBK';
          line-height: 50px;
          text-align: center;
        }
      }
    }

    .rightDetaile {
      width: 423px;

      .righttop {
        background: #fafafa;
        padding: 20px 16px 0 32px;
        margin-bottom: 6px;
      }

      .top {
        padding-bottom: 26px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      }

      .mt22 {
        margin-bottom: 22px;
      }

      .font {
        font-family: 'FZLanTingHeiS-R-GB';
      }

      .fontZoo {
        font-family: 'Zona Pro';
      }

      .center {
        width: 100%;
        padding: 26px 0;
        font-family: 'FZLanTingHeiS-R-GB';
        display: inline-flex;
        flex-direction: column;
        gap: 8px;
      }

      .texunder {
        text-decoration: underline;
        cursor: pointer;
      }

      .h68 {
        height: 68px;
        line-height: 68px;
        text-align: center;
        font-family: 'FZLanTingHeiS-R-GB';
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.5s ease-out;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.08);
      }

      .h42 {
        height: 42px;
        transition: all 0.5s ease-out;
      }
    }
  }
}

.orderModal {
  .ant-modal {
    width: 1026px !important;

    .mt110 {
      margin-top: 110px;
    }

    .ant-modal-body {
      padding: 24px 32px 40px;

      .btns {
        display: flex;
        margin-top: 20px;

        .pack_btn {
          width: 200px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-family: 'FZLanTingHei-M-GBK';
          margin-right: 8px;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.1);
          color: rgba($color: #212121, $alpha: 0.6);
        }

        .packSelect {
          background-color: #212121;
          color: #fff;
        }
      }

      .itemPicture {
        margin-right: 20px;
        margin-top: 32px;
        width: 120px;
        height: 120px;
      }

      .express {
        height: 330px;
        background-color: #fafafa;

        .noTrack {
          height: 100%;

          .iconfont {
            font-size: 52px;
          }
        }

        .expressInform {
          width: 100%;
          height: 49px;
          border-bottom: 1px solid rgba($color: #212121, $alpha: 0.1);
          font-family: 'FZLanTingHeiS-R-GB';
          padding: 0 25px;

          .copy {
            width: 57px;
            height: 30px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.27);
            text-align: center;
            line-height: 30px;
            cursor: pointer;
          }


        }

        .timeLine {
          position: relative;
          padding: 32px 47px;
          height: 280px;
          overflow-y: scroll;

          .ant-timeline {
            position: absolute;
            left: 47px;
            top: 32px;

            .ant-timeline-item {
              padding-bottom: 40px;
              display: block;
              height: auto;

              &:nth-child(2) {
                .ant-timeline-item-label {
                  div {
                    color: #212121;
                  }
                }

                .ant-timeline-item-content {
                  div {
                    color: #212121;
                  }
                }

                .ant-timeline-item-head {
                  background-color: #212121 !important;
                  color: #212121 !important;
                  border: #212121;
                }
              }
            }

            .ant-timeline-item-tail {
              left: 10%;
            }

            .ant-timeline-item-label {
              top: -15px;
              width: auto;
            }

            .ant-timeline-item-content {
              width: 723px;
              left: calc(10% - 4px);
            }

            .color216 {
              color: rgba($color: #212121, $alpha: 0.6);
            }

            .small {
              transform: translateX(4px);

              p {
                transform: scale(0.9);
              }
            }

            .status {
              transform: translateY(-9px);
            }

            .tans8 {
              transform: translateY(-8px);
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #000000, $alpha: 0.2);
          }

          .ant-timeline-item-head {
            left: 10%;
            width: 7px;
            height: 7px;
            margin-left: -2px;
            background-color: #b3b3b3;
          }

          .lastDot {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #212121;
            line-height: 20px;
            text-align: center;
            color: #fff;
            position: absolute;
            left: -3px;
            top: -5px;

            div {
              margin: auto;
              font-size: 12px;
              transform: scale(0.8);
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}

.notShow {
  display: none;
}

.jc-store-pickup {
  display: flex;
  flex-direction: row;

  span {
    &:first-child {
      flex: none;
    }
  }
}

.mb11 {
  margin-bottom: 11px;
}