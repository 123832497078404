.appointOrderMOB {
  height: inherit;

  .ant-drawer-mask {
    background: white;
  }

  .addAddress {
    width: 100%;
    line-height: 0;
    background-color: #212121;
  }

  .appinotBtn {
    background-color: #212121;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }

  .container_address {
    margin: auto;
    height: 100%;

    .nullAdress {
      height: 136px;
      line-height: 136px;
      text-align: center;
    }
  }

  .addApponit {
    width: 103px;
    height: 50px;
    background: #212121;
    color: #ffffff;
  }

  .books {
    .opa6 {
      opacity: 0.6;
    }

    .bookItem {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 19px 14px;

      &:hover {
        background-color: #f3f3f3;
      }

      .w50 {
        width: 17%;
      }
    }
  }
}

.appointInfoM {
  height: 100vh;

  .goodInfo {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    .leftPic {
      width: 132px;
      height: 132px;
      margin-right: 16px;
    }

    .fw600 {
      font-weight: 600;
    }

    .fw700 {
      font-weight: 700;
    }

    .color4242 {
      color: #424242;
    }

    .colorBD {
      color: #bdbdbd;
    }

    .price {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.466667px;

      color: #212121;

      &.gray {
        margin-right: 8px;
        text-decoration: line-through;
        color: rgba(33, 33, 33, 0.4);
      }

      &.final_price {
        color: rgba(193, 40, 27, 1);
      }
    }
  }

  .horizontal {
    padding: 13px 0;
    border-top: 1px solid #212121;
    border-bottom: 1px solid #212121;
  }

  .ant-modal-body {
    padding: 60px 20px 50px 20px;
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .ant-drawer-body {
    padding: 0 20px;
  }

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-header {
    padding: 13px 0 0 0;
    border: none;

    .ant-drawer-close {
      color: #212121;
    }
  }
}

.noContainer {
  height: 200px;
  margin: auto;
  text-align: center;
}