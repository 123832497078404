.pc_email_subscript {
    color: rgba($color: #212121, $alpha: 0.6);

    .left_img {
        width: 348px;
        vertical-align: top;
    }

    .mb12 {
        margin-bottom: 12px;
    }

    .eamil {
        position: relative;

        .ant-form-item {
            width: 100%;
        }

        .icon-changjiantou {
            position: absolute;
            right: 16px;
            top: 32px;
        }
    }

    .inputWidth {
        display: inline-block;
        margin-right: 16px;
        text-align: left;

        &:nth-child(3) {
            margin-right: 0;
        }
    }

    .ant-form-item-control-input-content {
        display: flex;

        .input-select.date-select {
            width: 88px;
            margin-right: 8px;
            background: none !important;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ant-form-item-label {
        height: 24px;
        line-height: 12px;

        label {
            font-size: 12px;
            color: rgba($color: #212121, $alpha: 0.6);
        }

    }

    .sbumitBtn {
        width: 100%;
    }

    .step2 {
        .phoneCode {
            width: 100%;
            height: 39px;
            margin-right: 8px;
            border: 1px solid #707070;
            padding: 10px 15px;
            box-sizing: border-box;
        }
    }

    .step3 {
        button {
            width: 218px;
        }
    }


}