@import '@/style/mixin';

.appointData {
  height: 626px;
  padding-bottom: 0;

  .ant-modal-content {
    height: 626px;
  }

  .unSelect {
    color: #f3f3f3;
  }

  .w367 {
    width: 367px;
    background: #f5f5f5;
    height: 626px;
    overflow-y: scroll;
    @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.2));

    .h341 {
      height: 341px;
    }
  }

  .color4242 {
    color: #424242;
  }

  .w254 {
    width: 254px;
    background-color: #212121;
    color: #fff;
    height: 56px;
  }

  .page {
    position: absolute;
    bottom: 17px;
    right: 13px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .caldata {
    width: 704px;

    .month {
      margin: auto;
    }
  }

  thead {
    border-bottom: 2px solid #212121;
    text-align: center;
  }

  .ant-picker-calendar-date-value {
    height: 34px;
    line-height: 34px !important;
  }

  .ant-picker-calendar-date-content {
    display: none;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date {
    background: #f5f5f5;
  }


  .ant-picker-cell-today {
    .ant-picker-calendar-date-today {
      background-color: #fff !important;
    }
  }

  .ant-picker-calendar-date-today {
    .ant-picker-calendar-date-value {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .week-tr {
    th {
      line-height: 34px !important;
      font-size: 14PX;
    }
  }
}