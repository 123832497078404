@import '@/style/mixin';

.pc-home-pdp-pc {
  padding: 26px 40px 0;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 600px;

  .swiper-container {
    padding: 16px 6px 16px 12px;
    box-sizing: border-box;
  }

  .pdp-container {
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left-box {
      flex: 1;
      box-sizing: border-box;
      margin-right: 24px;

      .item-img {
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .right-box {
      position: sticky;
      top: 116px;
      padding-bottom: 30px;
      width: 564px;
      // overflow: hidden;
      height: fit-content;
      text-align: left;
      @include scrollbar-color(transparent, transparent);

      .info {
        .title-box {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .title {
            font-family: 'Zona Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.466667px;
            color: #212121;
            word-break: break-word;
            overflow: hidden;
          }

          .share-box {
            flex-shrink: 0;
            margin-left: 16px;
          }
        }

        .desc {
          margin-top: 8px;
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.466667px;
          color: #797979;
        }

        .tag-list {
          margin-bottom: 8px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .product-tag {
            padding: 5px;
            text-align: center;
            background: #FAFAFA;
            font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #212121;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .tag {
              display: inline-block;
            }

            .tag-icon {
              width: auto;
              height: 12px;
              vertical-align: middle;
              display: inline-block;
              margin-right: 5px;
            }
          }
        }

        .price-box {
          margin-top: 8px;

          .price {
            font-family: 'Zona Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            letter-spacing: 0.466667px;

            color: #212121;

            &.gray {
              margin-right: 8px;
              text-decoration: line-through;
              color: rgba(33, 33, 33, 0.4);
            }

            &.final_price {
              color: rgba(193, 40, 27, 1);
            }
          }
        }
      }

      .color-box {
        margin-top: 24px;

        &.color-box-type-0,
        &.color-box-type-2,
        &.color-box-type-3 {
          display: flex;
          justify-content: space-between;

          .color-title {
            white-space: pre-wrap;
            margin-right: 24px;
          }

          .color-list {
            justify-content: flex-end;
          }
        }

        &.color-box-type-0 {
          .color-list {
            flex-shrink: 0;
          }
        }

        &.color-box-type-2 {
          .color-title {
            flex-shrink: 0;
          }
        }

        //3-global站
        &.color-box-type-3 {
          flex-wrap: wrap;

          .color-title {
            flex-basis: calc(100% - 176px);
            margin-right: 0;
            flex-grow: 1;
            margin-bottom: 8px;
          }

          .color-list {
            justify-content: flex-start;
          }
        }

        &.color-box-type-1 {
          .color-list {
            margin-top: 16px;
          }
        }


        .color-title {
          display: flex;

          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.466667px;
          color: rgba(33, 33, 33, 0.6);

          .title {
            color: #212121;
            font-weight: bold;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }

        .color-list {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;

          .item {
            box-sizing: border-box;
            width: 56px;
            height: 56px;
            border-bottom: 1px solid transparent;

            &.active {
              border-bottom-color: #797979;
            }
          }
        }
      }

      .size-box {
        margin-top: 24px;

        .size-title {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0.466667px;

          .title {
            color: #212121;
            font-weight: bold;
          }

          .size-guide {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;

            .icon-size-guide {
              font-size: 8px;
              margin-left: 10px;
            }
          }
        }

        .size-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 8px;
          gap: 10px;

          .item {
            box-sizing: border-box;
            width: calc((100% - 80px) / 9);

            background: #ffffff;
            border: 1px solid #ebebeb;
            font-family: 'Zona Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 44px;

            text-align: center;
            letter-spacing: 0.466667px;

            color: #212121;

            &:hover {
              color: #5f5f5f;
            }

            &.active {
              border: 1px solid #212121 !important;
              font-weight: 700;
            }

            &.gray {
              background: #f6f6f6;
              border: 1px solid #c8c8c8;
              color: #797979;
            }
          }
        }

        .size-tip-box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 8px;

          .pick-size-tip {
            width: 100%;
            height: 15px;

            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;

            letter-spacing: 0.466667px;

            color: rgba(33, 33, 33, 0.8);
            background-color: #fff;
          }

          .size-tip {
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            height: 18px;
            letter-spacing: 0.466667px;
            color: #797979;
          }

          .size-description {
            flex-shrink: 0;
            color: #797979;
            font-family: FZLanTingHeiS-R-GB;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.466667px;
          }
        }
      }

      .delivery-date {
        position: relative;
        margin-top: 32px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        letter-spacing: 0.466667px;

        color: rgba(33, 33, 33, 0.8);

        .button-above-box {
          .delivery-date-item:last-child {
            margin-bottom: 16px;
          }
        }
      }

      .share-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .iconfont {
          font-size: 24px;
          line-height: 1;
        }

        .ant-popover-inner,
        .ant-popover-inner-content {
          width: fit-content;
        }
      }

      .arrival-date,
      .arrival-date-mto,
      .to-cart-tips {
        margin-top: 8px;
        color: #797979;
        font-size: 14px;
        font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
        line-height: 18px;
        letter-spacing: 0.466667px;
      }

      .product-family {
        margin: 32px 0 84px;
      }

      .button-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        .button-item {
          width: 100%;
          height: 52px;
          font-family: 'FZLanTingHei-M-GBK';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 52px;
          text-align: center;
          letter-spacing: 1.25px;
          box-sizing: border-box;

          &.unselected {
            border: 1px solid #212121;
            color: #212121;
          }

          &.is-soldout {
            color: #c8c8c8;
            border: 1px solid #c8c8c8;
            pointer-events: none;
          }

          &:first-child.active {
            color: #fff;
            background: #212121;
          }

          &:not(:first-child).active {
            border: 1px solid #000000;
            color: #212121;
          }
        }
      }
    }
  }

  .delBtn {
    width: 160px;
  }

  .collapse-box {
    font-family: Zona Pro, FZLanTingHeiS-R-GB;
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    letter-spacing: 0.25px;

    color: #424242;
    border-top: 0.01389rem solid #e0e0e0;
    margin-top: 32px;

    .ant-collapse-header {
      padding: 16px 0 !important;
      position: relative;

      .ant-collapse-arrow {
        right: 0 !important;
        font-size: 16px !important;
        line-height: 1 !important;
      }
    }

    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .ant-collapse-item {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .ant-collapse-header-text {
      line-height: 18px;
      letter-spacing: 1.5px;
    }

    .panel-container {
      padding-left: 20px;

      &.t30 {
        margin-top: 24px;
      }

      .item {
        position: relative;
        line-height: 24px;

        &::after {
          content: '';
          width: 2px;
          height: 2px;
          background-color: #212121;
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }

        .chat-btn {
          text-decoration: underline;
        }
      }
    }

    ul {
      li {
        position: relative;
        padding-left: 20px;

        &::after {
          content: '';
          position: absolute;
          left: 8px;
          top: 8px;
          width: 2px;
          height: 2px;
          background: #212121;
        }
      }
    }
  }

  .popover-content-box-m {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      &:nth-of-type(1) {
        margin-right: 16px;
      }
    }
  }

  .size-group-dialog {
    .size-group-page {
      width: 300px;
      min-width: 100%;
    }
  }

  .noPro {
    .delBtn {
      width: 160px;
    }
  }

  .swiper-banner-4 {
    .loveWay-list {
      .item {
        font-size: 14px;

        &.active {
          font-weight: bold
        }
      }
    }
  }
}

.invalid-tip {
  text-align: center;
  font-family: 'FZLanTingHeiS-R-GB';
  font-size: 14px;

  .btn {
    width: 160px;
    height: 50px;
    background: #212121;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}