.load_video {
  max-height: 644px !important;
}

.media_content {
  // position: relative;

  // .jcm-task-video-play-btn {
  //   z-index: 999;
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateY(-50%);
  //   transform: translateX(-50%);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;
  //   height: 100%;

  //   .jcm-task {
  //     width: 48px;
  //     height: 48px;
  //     border-radius: 50%;
  //     background: #fff;
  //     opacity: .2;
  //   }

  //   .iconfont {
  //     position: absolute;
  //     // left: 35%;
  //     border-radius: 1px;
  //     font-size: 18px;
  //     color: #fff;
  //   }
  // }
}