.mapCard-container-m,
.mapCard-page {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-info1,.map-info2{
  width:200px;
  white-space:initial;
  text-align:left;
  p{
    line-height: 1.2;
    margin-bottom: 6px;
  }
  p:last-child{
    margin-bottom: 0;
  }
}

#searchPart {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}