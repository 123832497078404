.pc-register {
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';

  .title {
    margin-left: 30px;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sub-title {
    font-family: 'FZLanTingHei-M-GBK';
    // margin-left: -30px;
  }

  .inputW,
  .sbumitBtn {
    width: 310px;
  }

  .leftImg {
    width: 100%;
    height: auto;
  }
}