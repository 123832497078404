.Mon-login {
  width: 100%;
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';
  .mb16 {
    margin-bottom: 16px;
  }
  .mt32 {
    margin-top: 32px;
  }
  .left .fx-sc{
    span:first-child{
      margin-left: 10px;
    }
  }
  .grid {
    margin: 0 24px;
    color: rgba(97, 97, 97, 0.4);
  }
  .login-box {
    box-sizing: border-box;
  }
  .otherLogin {
    a {
      margin-left: 20px;
      padding-top: 3px;
    }
    img {
      width: 16px;
      height: 13px;
    }
  }
  .left {
    padding: 0 20px;
  }
  .right {
    text-align: left;
    padding: 0 20px;
    .mt8 {
      margin-top: 8px;
    }
    .mt12 {
      margin-top: 12px;
    }
    .list {
      padding-bottom: 8px;
      font-size: 13px;
      li {
        margin-bottom: 24px;
      }
    }
    .signBtn {
      display: block;
      width: 335px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background-color: #212121;
      text-align: center;
    }
  }
  .left,
  .right {
    .loginForm,
    .orderForm,
    .codeLoginForm {
      text-align: left;
      margin-top: 24px;
      .ant-form-item {
        display: inline-block;
        width: 335px;
        vertical-align: middle;
      }
      img,
      button {
        width: 100px;
        height: 40px;
        vertical-align: middle;
      }
    }
    .count {
      width: 100px;
      background-color: #cecece;
      height: 100%;
    }
  }
}
