.appointDataM {

  //安卓浏览器地址栏推上去的时候优化表现
  .ant-drawer-mask {
    background: white;
  }

  .ant-drawer-content-wrapper {
    position: relative;
  }

  .h341 {
    width: 132px;
    height: 132px;
    margin-right: 16px;
  }

  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .bottom {
    margin-bottom: 17px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .color4242 {
    color: #424242;
  }

  .w254 {
    width: 100%;
    background-color: #212121;
    color: #fff;
    height: 56px;
  }

  .unSelect {
    color: #f3f3f3;
  }

  .page {
    position: absolute;
    bottom: 18px;
    right: 17px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .ant-drawer-body {
    padding: 0 20px;
  }

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-header {
    padding: 13px 0 12px 0;
    border: none;

    .ant-drawer-close {
      color: #212121;
    }
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .caldata {
    width: 704px;

    .month {
      margin: auto;
    }
  }

  thead {
    border-bottom: 2px solid #212121;
    text-align: center;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date {
    background-color: #f5f5f5;
  }


  .ant-picker-cell-today {
    .ant-picker-calendar-date-today {
      background-color: #fff !important;
    }
  }

  .ant-picker-calendar-date-today {
    .ant-picker-calendar-date-value {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .ant-picker-calendar-date-value {
    height: 34px;
    margin: 10px 0;
    line-height: 34px !important;
  }

  .ant-picker-calendar-date-content {
    display: none;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0;
  }

  .week-tr {
    th {
      line-height: 34px !important;
      font-size: 14PX;
    }
  }
}