.PcPersonLeft {
  width: 204px;
  min-width: 204px;
  height: auto;

  .bor000 {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
  }

  .logo {
    width: 10%;
  }

  .logo8 {
    width: 8%;
  }
}

.login_box {
  .PcPersonLeft {
    width: 100%;
    min-width: 204px;
    height: auto;

    .bor000 {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    }

    .logo {
      width: 5.6%;
    }

    .logo8 {
      width: 8%;
    }
  }
}