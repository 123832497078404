.mob-register {
  margin: auto;
  margin-top: 24px;
  font-family: 'FZLanTingHeiS-R-GB';

  .mb32 {
    margin-bottom: 32px;
  }

  .container {
    padding: 0 20px;
  }

  .sub-title {
    margin-top: 16px;
    font-family: 'FZLanTingHei-M-GBK';
  }

  .inputW,
  .sbumitBtn {
    width: 100%;
  }

  .leftImg {
    width: 100%;
    height: auto;
    margin-top: 8px;
  }

  .ant-checkbox-wrapper {
    font-size: 13px;
    color: rgba(33, 33, 33, 0.6);
    letter-spacing: 0.47px;
  }
}