.mob_email_subscript {
  color: rgba($color: #212121, $alpha: 0.6);
  z-index: 10003;

  .ant-select-dropdown {
    z-index: 99;
  }

  .mb12 {
    margin-bottom: 12px;
  }

  .eamil {
    position: relative;

    .ant-form-item {
      width: 100%;
    }

    .icon-changjiantou {
      position: absolute;
      right: 16px;
      top: 32px;
    }
  }


  .infoInput {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    display: flex;

    .input-select.date-select {
      width: calc((100% - 16px) / 3);
      margin-right: 8px;
      background: none !important;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-form-item-label {
    height: 24px;
    line-height: 12px;

    label {
      font-size: 12px;
      color: rgba($color: #212121, $alpha: 0.6);
    }

  }

  .sbumitBtn {
    width: 100%;
  }

  .step2 {
    width: 100%;
    margin: 0 auto 64px;

    .phoneCode {
      width: 106px;
      height: 39px;
      margin-right: 8px;
      border: 1px solid #707070;
      padding: 10px 15px;
      box-sizing: border-box;
    }
  }

  .step3 {
    button {
      width: 218px;
    }
  }


}

.mob-email-dialog {
  position: fixed;
  top: 63px;
  left: 0;
  // z-index: 990;
  background-color: rgba(0, 0, 0, 0.4);
  height: calc(100vh - 62px);
}

.mob-email-dialog-height {
  top: 93px;
  height: 100%;
}