.mob-checkout-invoice {
  .input376 {
    width: 100%;
  }

  .ant-radio-wrapper {
    width: 100px;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item:last-child {
    margin-bottom: 0 !important;
  }
}