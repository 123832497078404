@import '@/style/mixin';

.appointShopPerson {
  height: 594px;
  overflow-y: hidden;
  padding-bottom: 0;

  .mr24 {
    margin-right: 24px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .fw600 {
    font-weight: 600;
  }

  .fw700 {
    font-weight: 700;
  }

  .color4242 {
    color: #424242;
  }

  .colorBD {
    color: #bdbdbd;
  }

  .mapSize {
    width: 594px;
    height: 594px;
  }

  .scroll {
    overflow-y: scroll;
    height: 400px;
    @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1));
  }

  .bottom {
    position: absolute;
    bottom: 17px;
    right: 16px;
  }

  .shopItem {
    .w377 {
      width: 377px;
      background: #ffffff;
      color: #212121;
      border: 1px solid #e0e0e0;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .ant-pagination-item {
    a {
      color: #757575;
    }
  }

  .ant-pagination-item-active {
    border: none;

    a {
      color: #212121;
    }
  }
}