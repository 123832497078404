.jcm-contact-us-submit {
	width: 92%;
	margin: auto;
	background: #FFF;
	padding-bottom: 94px;

	.contact-us-submit-top {
		color: #212121;

		.icon-zhifuchenggong {
			font-size: 56px;
		}

		.top-result {
			margin-top: 33px;
			margin-bottom: 24px;
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 0.467px;
		}

		.top-tips {
			margin-bottom: 80px;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.467px;
		}
	}

	.contact-us-submit-bottom {
		margin-top: 16px;
		gap: 60px;

		.layer_title {
			color: #000;
			text-align: center;
			font-size: 24px;
			line-height: 24px;
			font-weight: 600;
			letter-spacing: 0.467px;
			margin-top: 40px;
			margin-bottom: 16px;
		}

		.layer_link_title {
			width: 100%;
		}

		.default-btn {
			display: block;
			text-align: center;
			height: 40px;
			color: #212121;
			font-family: 'FZLanTingHei-M-GBK';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 40px;
			letter-spacing: 0.466667px;
			border: 1px solid #989898;
			background: #FFF;
			cursor: pointer;
		}
	}

	.fcc {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.fcr {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}
}