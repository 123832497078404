.content-right {
  padding: 30px;
  flex: 1;
  background-color: white;

  .customer-html {
    min-height: 100%;
  }

  .size-group-page {
    width: 300px;
    min-width: 100%;
  }
}

.p0 {
  padding: 0;
}
