.pay-methods {
    .iconfont {
        font-size: 20px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: sub;
    }

    .icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 8px;
    }

    .ant-radio-wrapper {
        margin-right: 50px;
        margin-top: 24px;
    }

    .ant-radio+span {
        transform: translateY(-2px);
        padding-left: 13px;
        padding-right: 0;

        .methods-name {
            display: inline-block;
            vertical-align: middle;
        }
    }

    ul {
        li {
            width: 256px;
            height: 120px;
            display: inline-block;
            margin-right: 16px;
            border: 1px solid rgba(33, 33, 33, 0.2);
            padding: 13px 16px;
            box-sizing: border-box;
            margin-top: 25px;
            text-align: left;

            .price {
                height: 30px;
                line-height: 30px;
            }

            .color61 {
                color: #616161;
                text-decoration: line-through;
            }

            .line {
                margin-top: 15px;
                width: 100%;
                border-top: 1px solid rgba(33, 33, 33, 0.2);
            }

            :last-child {
                margin-right: 0;
            }

        }

        .active {
            border: 1px solid #212121;
        }
    }

    .mt60 {
        margin-top: 30px;
        color: #212121;
        text-align: center;
    }
}