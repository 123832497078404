.SearchDrawer {
  position: fixed;

  // bottom: auto;
  // height: 100vh;
  .icon-guanbi {
    float: right;
  }

  .jc-drawer-content {
    overflow-y: scroll;
    padding-top: 12px;

    .search-input-prefix-icon {
      font-size: 22px;
    }
  }

  .ant-drawer-content-wrapper {
    height: -webkit-fill-available !important;
    overflow: scroll;
  }

  .ant-drawer-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-input-affix-wrapper {
    // padding-left: 0;
    border: none;
    border-bottom: 1px solid #757575;
    padding: 0;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .ant-input {
    font-size: 14px;
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;
    border: 0;
    // border-bottom: 1px solid #757575;
  }

  .hot-history {
    border-top: 1px solid #eee;
  }

  li {
    width: 100%;

    a {
      display: block;
    }

    span {
      word-wrap: break-word;
    }
  }

  .ant-tabs-nav-wrap {
    // border-bottom: 1px solid #f0f0f0;
    margin-left: 20px;

  }

  .ant-tabs-tab {
    font-size: 14px;
    background-color: unset !important;
    padding: 8px 0;
  }


  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #757575;
      margin: auto;
      text-shadow: none !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #212121;
      text-shadow: none !important;
      font-weight: 600;
    }
  }


  .ant-tabs-ink-bar {
    height: 0.018rem !important;
  }

  .ant-tabs-nav:before {
    border: none !important;
  }

  .fw600 {
    font-weight: 600;
  }
}

.top64 {
  top: 64px;
}

.top94 {
  top: 94px;
}