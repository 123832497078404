@import '@/style/mixin';

.minisearch {
  width: 750px;
  position: fixed;
  left: 0;
  transition: all 500ms ease-in;
  z-index: 1001;

  .positionel {
    padding: 16px 32px 32px;
    background: #fff;
    text-align: left;
    min-height: 366px;
    overflow-y: scroll;
    @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));
    max-height: calc(100vh - 150px);
    scrollbar-width: auto !important;
    scrollbar-color: auto !important;

    .search-top {
      position: relative;
    }

    .searchIcon {
      position: absolute !important;
      left: 0px;
      top: 3px;
      z-index: 2;
    }

    .icon-guanbi {
      position: absolute;
      right: 0px;
      top: -2px;
    }

    input {
      border: 0;
      border-bottom: 1px solid #757575;
      padding-left: 30px;
    }

    .search-key-value {
      margin-top: 32px;
      line-height: 20px;

    }

    .container {
      width: 100%;
      overflow: hidden;
      margin-top: 32px;
      height: fit-content;
    }


    .left {
      width: 180px;
      font-size: 14px;
      border-right: 1px solid #eee;

      .title {
        line-height: 16px;
        font-size: 14px;
      }

      .search-null {
        line-height: 24px;
      }

      .search-null-line {
        height: 1px;
        margin: 32px 16px 32px 0;
        background-color: #eee;
      }

      ul {
        width: 100%;
        margin-bottom: 24px;
        margin-top: 10px;
      }

      .list {
        width: 144px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EEEEEE;
        box-sizing: border-box;
      }

      li {
        line-height: 24px;
        color: #212121;
        padding-right: 10px;
        box-sizing: border-box;

        span {
          word-wrap: break-word;
        }
      }
    }

    .right {
      margin-left: 32px;
      width: 455px;
      min-width: 455px;

      .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
        background-color: unset;
        padding: 2px 0;
      }

      .ant-tabs-nav::before {
        border-bottom: none
      }
    }

    .sousuoimg {
      font-size: 10px;
      color: #212121;
      margin-right: 6px;
    }
  }



  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #757575;
      margin: auto;
      font-size: 14px;
      text-shadow: none !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #212121;
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    height: 0.012rem !important;
  }

  .tab-single {
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: #212121;
        font-weight: 600;
      }
    }
  }
}

.minisearch.top110 {
  top: 110px;
}

.minisearch.top80 {
  top: 80px;
}

.ant-tabs-content-holder {
  min-height: 230px;
}