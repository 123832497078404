.pay-methods_mob {
  .ant-radio-group {
    flex-wrap: wrap;
    width: 100%;
  }

  .ant-radio-wrapper {
    width: calc((100% - 20px) / 2);
  }

  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
  }

  ul {
    li {
      width: 100%;
      height: 108px;
      display: inline-block;
      border: 1px solid rgba(33, 33, 33, 0.2);
      padding: 13px 16px;
      box-sizing: border-box;
      margin-bottom: 10px;

      .innstallment {
        height: 22px;
        line-height: 22px;
      }


      .color61 {
        color: #616161;
        text-decoration: line-through;
      }

      .line {
        margin: 12px 0 12px;
        width: 100%;
        border-top: 1px solid rgba(33, 33, 33, 0.2);
      }
    }

    .active {
      border: 1px solid #212121;
    }
  }
}

.tip-container {
  text-align: center;

  .confiram {
    width: calc((100% - 15px) / 2);
  }
}