@import '@/style/mixin';

.shop-container-m {
  margin: 0 auto;
  padding: 24px 0 32px;

  .title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: #212121;
  }

  .city-box {
    margin-top: 8px;

    .item {
      height: 48px;
      line-height: 60px;
      line-height: 48px;
      border-bottom: 1px solid #eee;
      font-size: 13px;
      color: rgba(33, 33, 33, 0.6);

      &.flex {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        .name {
          flex: 1;
          color: #212121;
        }
      }

      &.search-box {
        position: relative;
        display: flex;
        align-items: flex-end;
        align-items: center;
        width: 100%;

        // line-height: 34px;
        .ant-input-affix-wrapper {
          width: 90%;
          padding-left: 0;
          border: none;

          // padding: 0 0 8px;
          box-shadow: none;
        }

        .icon-sousuo-btn {
          position: absolute;
          top: 0;
          right: -4px;
        }
      }
    }

    .error-tip {
      color: #c1281b;
      font-size: 10px;
    }
  }

  .map-base {
    margin-top: 24px;
    height: 234px;
  }

  .title-h2 {
    margin-top: 32px;

    height: 16px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
  }

  .shop-main {
    box-sizing: border-box;
    width: 100%;

    .section-left {
      flex: 1;

      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      letter-spacing: 0.466667px;

      color: #212121;

      .result-box {
        .list {
          .item {
            padding: 20px 0 10px;

            .city-info {
              font-family: 'Zona Pro';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 15px;
              letter-spacing: 0.466667px;

              color: rgba(33, 33, 33, 0.6);

              .name {
                height: auto;
                min-height: 16px;

                font-family: 'FZLanTingHei-M-GBK';
                line-height: 16px;

                color: #212121;
                font-weight: 600;
              }

              .distance {
                margin-top: 8px;
                line-height: 18px;
                font-size: 13px;
                color: rgba(33, 33, 33, 0.6);


              }
            }

            .address,
            .business-hours {
              margin-top: 8px;

              font-family: 'Zona Pro';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;

              letter-spacing: 0.466667px;

              color: rgba(33, 33, 33, 0.6);

            }

            .contact-way {
              margin-top: 14px;
              display: flex;
              align-items: center;
            }

            .subscribe-btn {
              margin-top: 20px;
              height: 56px;
              background: #5F5F5F;
              text-align: center;
              line-height: 56px;
              font-size: 14px;
              color: #FAFAFA;
              pointer-events: none;


              &.active {
                background: #FFFFFF;
                color: #212121;
                border: 1px solid #E0E0E0;
                pointer-events: visible;
              }
            }
          }
        }
      }
    }

    .section-right {
      margin-top: 16px;
      height: 234px;
    }
  }

  .help {
    padding: 20px;

    .title {
      line-height: 18px;
      font-size: 16px;
      color: #212121;
      font-weight: 600;
    }

    .main {
      .item {
        display: flex;
        align-items: center;
        margin-top: 22px;

        .label {
          display: flex;
          align-items: center;
          width: 150px;

          .font {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }

        .num {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 13px;
          line-height: 20px;
          color: #616161;
        }
      }
    }
  }
}

.shop-nav {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding: 0 20px;

  .item {
    display: flex;
    align-items: center;
    width: 50%;
    line-height: 18px;
    font-size: 14px;
    color: #212121;
    font-weight: 500;
  }
}

.mt0 {
  margin-top: 0;
}

.map-dialog-m {
  .ant-modal-main {
    padding: 0;

    .section-left {
      margin-top: 24px;
      height: 234px;
    }

    .section-right {
      padding: 24px;
      overflow: auto;

      @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

      .name {
        line-height: 24px;
        font-size: 24px;
        color: rgba(33, 33, 33, 1);
        font-weight: 600;
      }

      .address {
        line-height: 18px;
        font-size: 13px;
        color: rgba(66, 66, 66, 1);
      }

      .info {
        line-height: 18px;
        font-size: 13px;
        color: rgba(33, 33, 33, 1);

        .item {
          display: flex;
          align-items: center;

          &:not(:nth-of-type(1)) {
            margin-top: 14px;
          }
        }

      }

      .time {
        .title {
          line-height: 18px;
          font-size: 16px;
          color: rgba(33, 33, 33, 1);
          font-weight: 600;
        }

        .main {
          margin-top: 8px;
          line-height: 18px;
          font-size: 13px;
          color: rgba(66, 66, 66, 1);
        }
      }
    }
  }
}

.top35 {
  margin-top: 35px;
}

.p20 {
  padding: 0 20px;
}

.underline {
  text-decoration: underline;
}

.err-notice {
  position: absolute;
  left: 0;
  bottom: -35px;
  color: rgb(239, 77, 77);
  font-size: 10px;
}

.rv-popup {
  z-index: 99999 !important;
}