.tabs-page-help {
  width: 100%;

  font-family: 'FZLanTingHeiS-R-GB';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: 0.466667px;

  color: #212121;

  .tabs-container {
    .name {
      margin: 0 4px;
    }

    .align-c:nth-last-of-type(1) {
      .name {
        color: #616161;
      }
    }

  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .title {
      line-height: 27px;
      font-size: 24px;
      // font-weight: 600;
    }

    .help {
      color: rgba(33, 33, 33, 0.8);
    }
  }

}