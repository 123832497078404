.pc-forget {
    margin: auto;

    .forgetForm {
        width: 500px;
        margin: 0 auto 100px;
    }

    .w500 {
        width: 500px;
    }

    .flexItem {
        .ant-form-item {
            display: inline-block;
            width: 210px;
            margin-bottom: 0;
            vertical-align: middle;
        }

        img,
        button {
            width: 100px;
            height: 40px;
            vertical-align: middle;
        }
    }
}