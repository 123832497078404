.pc-fiveSeat {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  // margin-bottom: 20px;
  width: 100%;

  .ant-carousel {
    margin-top: 20px;
  }

  .title {
    text-decoration: none;
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #212121;
    margin-bottom: 40px;
  }

  .describe {
    margin-bottom: 20px;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-list {
    margin: auto;
    box-sizing: border-box;

    .slick-track {
      margin: auto;
      box-sizing: content-box;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 0px;
  }

  .menu-right {
    right: 0px;
  }

  .fiveSeat_block {
    .leg6 {
      margin-right: 8px;
    }
  }
}