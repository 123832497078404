.twoSeat_title_top {
  // margin-bottom: 40px;
  padding: 0 20px;
}

.twoSeat_title {
  margin: auto;
}

.twoSeat_mob {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: auto;

  .jcm-two-seat-items {
    .slick-slide {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .main_title {
    // margin-top: 20px;
    margin-bottom: 20px;
    color: #212121;
    font-family: 'FZLanTingHei-M-GBK';
    text-align: center;
    color: #212121;
  }

  .Subtitle {
    text-align: center;
    margin-bottom: 20px;
    color: #212121;
    font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro', sans-serif;
  }

  .onePicture {
    .slick-track {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .slick-slide {
        width: 100% !important;
      }

      // .media_block_mob {
      //   margin-right: 0;
      // }
    }
  }

  .caroulelBox {
    position: relative;

    .slick-track {
      display: flex;
      align-items: center;
      margin: auto;
    }

    .swiper-menu {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 24px;
    }

    .menu-left {
      left: 20px;
    }

    .menu-right {
      right: 20px;
    }

  }
  .slick-list{
    .slick-slide{
        pointer-events: auto;
    }
  }
}
