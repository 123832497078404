.mob-pay {
    padding-bottom: 30px;

    .await-tip {
        margin-top: 30px;
        text-align: center;

    }

    .notShow {
        width: 0;
        height: 0;
        opacity: 0;
    }

    .valet-pay {
        text-align: center;

        .valet-pay-tip {
            opacity: 0.8;
            color: #212121;
            margin: 30px auto 0;

        }

        .price {
            margin: 26px auto 40px;
            color: #212121;
            font-family: 'FZLanTingHeiS-R-GB';
            opacity: 0.8;

            .price-txt {
                color: #C1281B;
            }
        }

        .pay-method {
            color: #212121;
            font-family: 'FZLanTingHei-M-GBK';
        }

        .scan {
            opacity: 0.8;
        }

        .paycode {
            width: 200px;
            height: 200px;
            margin: 25px auto;
        }
    }
}

.mob-paymethod {
    width: 90% !important;

    .ant-modal-content {
        .ant-modal-body {
            width: 100%;
            max-height: 100%;
            overflow: scroll;
        }
    }

    .select-pay-container {
        width: 100%;
        height: fit-content;

        ul {
            text-align: left;
        }

    }
}