.pc-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .notShow{
    width:0;
    height:0;
    opacity:0;
}
}
