.PcPersonCenter {
  max-width: 1440px;
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';
  .mumberName {
    font-family: 'Zona Pro';
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    display: flex;
    justify-content: center;
  }
  .notShow{
    width:0;
    height:0;
    opacity:0;
  }
  .container {
    margin: 40px 0;
    width: 100%;
    display: flex;
    .leftSlect {
      width: 204px;
      height: auto;
      .cursor {
        cursor: pointer;
      }
      .bor000 {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      }
      .logo {
        width: 10%;
      }
    }
  }
}
