.mob-no-result {
  margin: auto;
  .ant-input-affix-wrapper {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid #757575;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  .ant-input {
    font-size: 14px;
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;
  }

  .letter {
    letter-spacing: 0.18px;
  }
  .color75 {
    color: #757575;
    letter-spacing: 0.4px;
  }
  .helpBlock {
    width: 100%;
    background: #fafafa;
    padding: 16px 0;
  }
  .mt16 {
    margin-top: 16px;
  }
  .w49 {
    width: 49.5%;
    margin-bottom: 2px;
    position: relative;
    .texts {
      color: #fffefe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
