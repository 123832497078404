.jc-product-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .product-title {
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    color: #212121;
    word-wrap: break-word
  }

  .product-describe {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.466667px;
    color: #212121;
    opacity: .6;
    margin-top: 25px;
    word-wrap: break-word;
    padding: 0 20px;
  }

  .product-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    // justify-content: center;
    // gap: 0 10px;
    // padding-left: 10px;
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    // flex: 1;
    padding: 0 3px;

    img {
      max-width: 460px;
    }

    .a-img {
      display: flex;
      position: relative;

      .sales-short-tips {
        position: absolute;
        left: 0;
        top: 0;
        padding: .06944rem .11111rem;
        background: #fafafa;
        font-style: normal;
        font-weight: 400;
        font-size: .16667rem;
        line-height: .19444rem;
        color: #212121;
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 35px;

      .item-name {
        font-family: 'Zona Pro';
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
      }

      .item-price {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.466667px;
        color: #212121;
        margin-top: 8px;
      }

      .item-color {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        margin-top: 16px;
      }

      .color-dot {
        width: 12px;
        height: 12px;
        border: 1px solid transparent;
        margin-right: 8px;
        line-height: 1;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}