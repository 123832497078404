@import '@/style/mixin';

.cartIndex {
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;

    a{
        display: block;
    }

    .empty {
        margin: 80px 0 0 0;
        text-align: center;

        button {
            width: 128px;
            height: 50px;
            margin: 24px 0 0;
            background: #FFFFFF;
            color: #000;
            border: 1px solid #989898;
            box-shadow: none;
            text-shadow: none;
        }
    }

    .cart-list {
        margin-top: 24px;

        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                margin: 0;
            }

            .cart-check {
                display: flex;
                align-items: center;
                font-size: 13px;
            }
        }

        .sub-left {
            flex: 1 1 872/1440*100%;

            .cart-left {
                display: flex;
                height: max-content;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                border-top: 1px solid rgba(0, 0, 0, 0.08);
                margin-top: 12px;
                padding: 16px 0 8px;

                .cart-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .disabled {
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }

                .cart-item {
                    width: 100%;
                    margin-bottom: 16px;
                    margin-left: 16px;
                    flex: 1;

                    &.invalid {

                        .product-img,
                        .product-info-detail {
                            opacity: 0.5;
                        }
                    }

                    .cart-item-main {
                        background-color: #fff;
                        border-radius: 4px;
                        position: relative;
                        overflow: hidden;
                    }

                    .txt-left {
                        p {
                            word-break: break-all;
                            word-wrap: break-word;
                            white-space: pre-wrap;
                        }
                    }

                    .product-img {
                        width: 312px;
                        height: 312px;
                    }

                    .product-info {
                        flex: 1;
                        padding: 74px 16px 12px;
                    }

                    .name {
                        font-family: 'Zona Pro';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 28px;
                        color: #242424;
                        word-break: break-all;
                    }

                    .sku-label {
                        font-family: 'Zona Pro';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        zoom: 0.85;
                        line-height: 12px;
                        letter-spacing: 0.2px;
                        color: #757575;
                        margin-bottom: 16px;
                    }

                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #FAFAFA;
                        padding: 5px 8px;
                        font-family: 'FZLanTingHeiS-R-GB';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #212121;
                    }

                    .price {
                        font-family: 'Zona Pro';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #242424;
                    }

                    .oldPrice {
                        text-decoration-line: line-through;
                        opacity: 0.4;
                    }

                    .finalPrice {
                        margin-left: 8px;
                        color: #C1281B;
                    }

                    .swatch-item {
                        margin-bottom: 8px;

                        font-family: 'FZLanTingHeiS-R-GB';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #242424;

                        span:first-child {
                            margin-right: 8px;
                        }

                        .swatch_image {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            margin-right: 4px;
                        }
                    }

                    .handle-area {
                        line-height: 1;
                        color: #242424;
                        font-size: 12px;
                        line-height: 16px;

                        .iconfont {
                            position: relative;
                            top: 1px;
                        }
                    }

                    .cart-item-buttom-tip {
                        margin-top: 8px;
                        font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.2px;
                        color: #242424;

                        &+.cart-item-buttom-tip {
                            margin-top: 4px;
                        }
                    }
                }

                .invalid-cart-title {
                    margin: 24px 0 16px;

                    .title {
                        font-family: 'FZLanTingHeiS-R-GB';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 22px;
                        letter-spacing: 0.15px;
                        color: rgba(0, 0, 0, 0.87);

                    }

                    .delete-invalid {
                        font-family: 'FZLanTingHeiS-R-GB';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #242424;
                        border-bottom: 1px solid currentColor;
                    }

                }

            }

            .error-item-list {
                background: #F5F5F5;
                font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #242424;
                border: 1px solid #616161;
                padding: 24px;
                margin-bottom: 24px;
                position: relative;

                .title {
                    // font-weight: 600;
                    margin-top: 16px;
            
                    &:first-of-type {
                      padding-right: 30px;
                      margin-top: 0;
                    }
                  }

                .go-invalid {
                    position: absolute;
                    right: 24px;
                    top: 24px;
                    font-size: 12px;
                    line-height: 16px;
                    text-decoration-line: underline;
                    color: #242424;
                    cursor: pointer;
                }

                .error-item {
                    margin-top: 8px;
                    margin-left: 18px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #757575;

                    .iconfont {
                        line-height: 1;
                    }

                    .product_name {
                        font-weight: 600;
                        color: #242424;
                    }

                    .swatch-item {
                        margin-left: 8px;
                    }

                }
            }

            .coupon {
                width: 100%;
                box-sizing: border-box;

                .coupon-form {
                    width: 100%;
                }

                .coupon-input {
                    display: inline-block;
                    width: calc(100% - 77px);
                }

                .ant-form-inline .ant-form-item {
                    margin-right: 0;
                }

                .ant-input-affix-wrapper {
                    padding: 0 16px;
                }


                .coupon-content {
                    width: 100%;
                    background: #fff;

                    .couponselect {
                        height: 56px;
                        line-height: 56px;
                        padding: 0 16px;
                    }

                    input {
                        height: 42px;
                        border-color: #E0E0E0;
                        font-size: 14px;

                        &::placeholder {
                            color: #757575;
                        }
                    }

                    .ant-form-item-with-help {
                        margin-bottom: 0;
                    }

                    .place {
                        color: #757575;
                    }

                    .icon-xiajiantou {
                        transition: all 300ms ease-in;
                    }

                    .couponIcon {
                        transform: rotateX(180deg);
                    }

                    .coupon-list-box {
                        transition: all 300ms ease-in;
                        overflow: hidden;
                    }

                    .coupon-list {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 16px;
                        box-sizing: border-box;
                        border-top: 1px solid #eee;
                        overflow-y: scroll;
                        max-height: 350px;
                        @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));

                        .list-item {
                            width: calc((100% - 20px) / 2);
                            padding: 16px 20px;
                            box-sizing: border-box;
                            border: 1px solid #E7E7E7;
                            margin-top: 16px;

                            .name {
                                height: 25px;
                            }

                            &:nth-child(2n+1) {
                                margin-right: 20px;
                            }

                        }

                        .active {
                            border-color: rgba(0, 0, 0, 1);
                        }


                    }
                }
            }
        }

        .sub-right {
            flex: 1 1 520/1440*100%;
            margin-left: 24px;
            font-size: 16px;
            font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
            color: #212121;

            .sub-info {
                padding: 24px;
                background: #FFFFFF;
                box-shadow: 0px -6px 32px rgba(0, 0, 0, 0.04);

                p {
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                }

                .line {
                    margin: 32px 0;
                    border-bottom: 1px solid #eee;
                }

                .total-price {
                    font-weight: 600;
                    margin-bottom: 34px;
                }

                .btn {
                    width: 100%;
                    height: 56px;
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    color: #FAFAFA;
                }

                .illegal {
                    background: #FAFAFA;
                    border: 1px solid #D7D7D7;
                    color: #BDBDBD;
                    text-shadow: none;
                    box-shadow: none;
                }

                .pay-icon-list {
                    margin-top: 16px;

                    .iconfont {
                        font-size: 24px;
                        margin-right: 20px;
                    }
                }
            }
        }

    }



}

.delPop {
    width: 145px;
    text-align: center;
    margin: 10px auto;
}

.ant-popover-message-title {
    width: 100%;
}


.ant-popover-buttons {

    button {
        width: 262px;
        height: 40px;
        display: block;
        margin: 0px auto 20px;
    }

    .ant-btn-default {
        color: #fff;
        border-color: #212121;
        background: #212121;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, .05);
    }

    .ant-btn-primary {
        background: #fff;
        border: 1px solid #212121;
        color: #212121;

    }

    button:not(:first-child) {
        margin-left: 0px;
    }
}

.valet-order {
    text-align: center;

    .title {
        margin-top: 15px;
        text-align: left;
        font-family: 'FZLanTingHeiS-R-GB';
        font-size: 14px;
        line-height: 24px;
        color: #212121;
    }

    .btn {
        width: 200px;
        height: 50px;
        margin-top: 40px;
        font-weight: 600;

        &+.btn {
            margin-left: 30px;
        }
    }

    .contine {
        background-color: #212121;
        color: #fff;
    }
}

.ant-modal-title {
    text-align: center;
    font-family: 'FZLanTingHei-M-GBK', 'Zona Pro', sans-serif;
    font-weight: 600;
}

.coupon-detail {
    .code {
        width: 180px;
        height: 180px;
        margin: 20px auto;
    }

    li {
        width: 100%;
        min-height: 40px;
        line-height: 40px;
        border-bottom: 1px solid rgba(33, 33, 33, 0.1);
        color: #212121;
        font-size: 12px;
        font-family: 'FZLanTingHeiS-R-GB';
        display: flex;
        flex-wrap: nowrap;


        .key {
            display: inline-block;
            width: 70px;
            margin-right: 10px;
        }

        .text {
            flex: 1;
        }
    }
}