.pictureProduct {
  .w600 {
    width: 600px;
    height: 600px;
  }

  .w50 {
    width: 50%;
  }

  .w80 {
    width: 80%;
  }

  .jc-title {
    line-height: 40px;
  }

  .jc-describe {
    line-height: 20px;
  }

  .right-p {
    position: relative;
    width: 80%;
    margin-top: 35px;
  }

  .swiper-menu {
    height: 40px;
    position: absolute;
    top: 35%;
  }

  .menu-left {
    left: -25px;

    &.external {
      left: -40px;
    }
  }

  .menu-right {
    right: -20px;

    &.external {
      right: -40px;
    }
  }
}

.reverse_listProduct {
  flex-direction: row-reverse;
}