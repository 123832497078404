.pc-home-plp {
  padding: 26px 0;
  min-height: 600px;

  .plp-top-ad {
    display: block;
    width: calc(100% - 48px);
    margin: 19px 24px 0;

    img {
      width: 100%;
    }
  }

  .good-collection {
    margin-top: 32px;

    .collection-name {
      margin: 0 0 20px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      color: #212121;
    }

    .swiper-container {
      padding-top: 0;
    }

    .load-more {
      width: fit-content;
      border-radius: 0;
      font-size: 13px;
      line-height: 1;
      font-weight: 600;
      padding: 15px;
      margin: 0 auto;
      color: #212121;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #ebebeb;

      &:hover {
        background: #ebebeb;
        border-color: #ebebeb;
        color: #212121;
      }
    }

  }

  .discur {
    cursor: auto;
  }

  .swiper-container {
    padding: 16px 24px;
    box-sizing: border-box;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 6px;
    grid-auto-flow: row;
  }

  .no-data {
    height: 256px;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 256px;
    letter-spacing: 0.466667px;
    text-align: center;

    color: #212121;
  }

  .loading-more {
    margin: 0 auto;
  }
}