.pc-threeSeat {
  margin: auto;
  height: auto;
  // margin-bottom: 60px;

  .title {
    font-family: 'FZLanTingHei-M-GBK';
    font-size: 30px;
    margin: auto;
    margin-bottom: 18px;
    color: #212121;
    text-align: center;
  }

  .describe {
    margin-bottom: 15px;
  }

  .pictures {
    display: flex;
    width: 100%;
  }

  .threeSeat_block {
    flex: 1;
    // margin: auto;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    .threeSeat_media {
      // width: 608px;
      height: auto;

      .lower_left_corner {
        bottom: 10%;
      }
    }
  }
}