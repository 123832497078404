.mob-MoreSeat {
  // margin-bottom: 35px;
  width: 100%;

  .MoreSeat_block {
    position: relative;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: space-between;

    .img_video_two {
      margin: auto;
      position: relative;
    }

    .fle {
      display: flex;
    }

    .icon_word {
      margin: auto;
      width: 30%;
      justify-content: center;

      .one-icon {
        margin-bottom: 15px;
      }

      .iconWord {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }

    .describe {
      text-align: left;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 24px;
  }

  .menu-left {
    left: 0px;
  }

  .menu-right {
    right: 0px;
  }

  .icon-Frame2 {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 28px;
  }

  .icon-a-huaban13 {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 28px;
  }
}

.popBox_mob {
  transform: translate(-5px 10px) !important;
  padding-left: 0;
  padding-top: 22px;

  .colorBox {
    width: 268px;
    height: fit-content;
    background-color: #fff;
    padding: 15px 10px;
    margin: auto;

    .firstImg {
      width: 120px;
      height: 80px;
    }

    .blockOne {
      margin-right: 20px;
      margin-bottom: 30px;

      .colorImg {
        width: 40px;
        cursor: pointer;
      }
    }
  }

  .ant-popover-buttons {
    display: none;
  }

  .anticon-exclamation-circle {
    display: none;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-content {
    transform: translate(-18px, 0px);
  }

  .ant-popover-message {
    padding: 0;
  }
}

.ant-popconfirm {
  z-index: 90;
}