.pc-orderStatus {
	margin: auto;

	.pc-tourist-order-detail {

		display: flex;
		flex-direction: row;
	}

	.notShow {
		width: 0;
		height: 0;
		opacity: 0;
	}

	.fontRGB {
		font-family: 'FZLanTingHeiS-R-GB';
	}

	.orderStatus-container {
		// width: 1000px;
		flex: 0 1 70%;
		margin-right: 50px;

		.order-title {
			padding: 0 20px;
			background-color: #fafafa;
		}

		.order-itemMob {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			font-size: 16px;
		}

		.pc-order-item-box {
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
			padding-bottom: 24px;
		}

		.to-cart-tips {
			margin-top: 8px;
			font-size: 13px;
			padding: 0 20px;
			box-sizing: border-box;
			font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
		}

		.payBtn {
			width: 352px;
			height: 50px;
			margin: 50px auto;
		}
	}

	.rightDetaile {
		width: 423px;

		.righttop {
			background: #fafafa;
			padding: 20px 16px 0 32px;
			margin-bottom: 6px;
		}

		.top {
			padding-bottom: 26px;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
		}

		.mt22 {
			margin-bottom: 22px;
		}

		.font {
			font-family: 'FZLanTingHeiS-R-GB';
		}

		.fontZoo {
			font-family: 'Zona Pro';
		}

		.center {
			width: 100%;
			padding: 26px 0;
			font-family: 'FZLanTingHeiS-R-GB';
			display: inline-flex;
			flex-direction: column;
			gap: 8px;
		}

		.texunder {
			text-decoration: underline;
			cursor: pointer;
		}

		.h68 {
			height: 68px;
			line-height: 68px;
			text-align: center;
			font-family: 'FZLanTingHeiS-R-GB';
			cursor: pointer;
			text-decoration: underline;
			transition: all 0.5s ease-out;
			border-top: 1px solid rgba($color: #000000, $alpha: 0.08);
		}

		.h42 {
			height: 42px;
			transition: all 0.5s ease-out;
		}
	}

	.jc-store-pickup {
		display: flex;
		flex-direction: row;

		span {
			&:first-child {
				flex: none;
			}
		}
	}

	.mb11 {
		margin-bottom: 11px;
	}

	.orderModal {
		.ant-modal {
			width: 1026px !important;

			.ant-modal-body {
				padding: 24px 32px 40px;

				.btns {
					display: flex;
					margin-top: 20px;

					.pack_btn {
						width: 200px;
						height: 50px;
						line-height: 50px;
						text-align: center;
						font-family: 'FZLanTingHei-M-GBK';
						margin-right: 8px;
						cursor: pointer;
						border: 1px solid rgba(0, 0, 0, 0.1);
						color: rgba($color: #212121, $alpha: 0.6);
					}

					.packSelect {
						background-color: #212121;
						color: #fff;
					}
				}

				.itemPicture {
					margin-right: 20px;
					margin-top: 32px;
					width: 120px;
					height: 120px;
				}

				.express {
					height: 330px;
					background-color: #fafafa;

					.noTrack {
						height: 100%;

						.iconfont {
							font-size: 52px;
						}
					}

					.expressInform {
						width: 100%;
						height: 49px;
						border-bottom: 1px solid rgba($color: #212121, $alpha: 0.1);
						font-family: 'FZLanTingHeiS-R-GB';
						padding: 0 25px;

						.copy {
							width: 57px;
							height: 30px;
							background: #ffffff;
							border: 1px solid rgba(0, 0, 0, 0.27);
							text-align: center;
							line-height: 30px;
							cursor: pointer;
						}
					}

					.timeLine {
						position: relative;
						padding: 32px 47px;
						height: 280px;
						overflow-y: scroll;

						.ant-timeline {
							position: absolute;
							left: 47px;
							top: 32px;

							.ant-timeline-item {
								padding-bottom: 40px;
								display: block;
								height: auto;

								&:nth-child(2) {
									.ant-timeline-item-label {
										div {
											color: #212121;
										}
									}

									.ant-timeline-item-content {
										div {
											color: #212121;
										}
									}

									.ant-timeline-item-head {
										background-color: #212121 !important;
										color: #212121 !important;
										border: #212121;
									}
								}
							}

							.ant-timeline-item-tail {
								left: 10%;
							}

							.ant-timeline-item-label {
								top: -15px;
								width: auto;
							}

							.ant-timeline-item-content {
								width: 723px;
								left: calc(10% - 4px);
							}

							.color216 {
								color: rgba($color: #212121, $alpha: 0.6);
							}

							.small {
								transform: translateX(4px);

								p {
									transform: scale(0.9);
								}
							}

							.status {
								transform: translateY(-9px);
							}

							.tans8 {
								transform: translateY(-8px);
							}
						}

						&::-webkit-scrollbar-thumb {
							background-color: rgba($color: #000000, $alpha: 0.2);
						}

						.ant-timeline-item-head {
							left: 10%;
							width: 7px;
							height: 7px;
							margin-left: -2px;
							background-color: #b3b3b3;
						}

						.lastDot {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							background-color: #212121;
							line-height: 20px;
							text-align: center;
							color: #fff;
							position: absolute;
							left: -3px;
							top: -5px;

							div {
								margin: auto;
								font-size: 12px;
								transform: scale(0.8);
								width: 12px;
								height: 12px;
							}
						}
					}
				}
			}
		}
	}
}

.mt110 {
	margin-top: 110px;
}