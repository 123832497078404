.jc-many-seat {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .many-seat-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
  }

  .many-seat-title {
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    color: #212121;
    word-wrap: break-word
  }

  .many-seat-describe {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.466667px;
    color: #212121;
    opacity: .6;
    margin-top: 25px;
    word-wrap: break-word;
    padding: 0 20px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;

    .jc-title-h1 {
      margin-top: 19px;
    }

    img {
      max-width: 460px;
    }

    .item-content {
      display: flex;
      flex-direction: column;

      .item-name {
        font-family: 'Zona Pro';
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
        cursor: pointer;

        &:hover {
          color: #585757;
        }
      }
    }
  }
}