.mob-orderStatus {
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';

  .pc-tourist-order-detail {

    display: flex;
    flex-direction: column;
  }

  .notShow {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .fontZoo {
    font-family: 'Zona Pro';
  }

  .orderStatus-container {
    width: 100%;
    margin: auto;

    .order-title {
      padding: 0 20px;
      background-color: #fafafa;
    }

    .order-list {
      padding: 0 20px;
    }

    .pay-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
    }

    .order-item {
      width: 100%;
      padding: 20px 0;
      box-sizing: border-box;
      font-size: 16px;
      border-bottom: 1px solid #ccc;

      .opa4 {
        color: rgba($color: #212121, $alpha: 0.4);
      }
    }

    .mob-order-item-box {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      padding: 24px 0;
    }

    .to-cart-tips {
      margin-top: 8px;
      font-size: 13px;
      font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
    }

    .payBtn {
      width: 100%;
      height: 50px;
      margin: 50px auto;
    }
  }

  .rightDetaile {
    width: 100%;
    padding: 0 20px;

    .top {
      padding: 20px 15px;
      background-color: #fafafa;
      padding-bottom: 24px;
    }

    .border {
      margin: 0 15px;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: 0.08);
    }

    .mt22 {
      margin-bottom: 22px;
    }

    .font {
      font-family: 'FZLanTingHeiS-R-GB';
    }

    .fontZoo {
      font-family: 'Zona Pro';
    }

    .center {
      background-color: #fafafa;
      padding: 20px 15px 0;
      font-family: 'FZLanTingHeiS-R-GB';
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      gap: 8px;
    }

    .fontWeight {
      font-weight: 600;
    }

    .texunder {
      text-decoration: underline;
      cursor: pointer;
    }

    .h60 {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.08);
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-family: 'FZLanTingHeiS-R-GB';
      text-decoration: underline;
      transition: all 0.5s ease-out;
    }

    .h0 {
      height: 0;
      transition: all 0.5s ease-out;
    }
  }

  .jc-store-pickup {
    display: flex;
    flex-direction: row;

    span {
      &:first-child {
        flex: none;
      }
    }
  }

  .mb11 {
    margin-bottom: 11px;
  }

  .orderDrawerMob {
    .ItemBlock {
      padding: 20px 0 22px 0;
      display: flex;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);

      .img {
        width: 100px;
        height: 100px;
      }

      .selectInform {
        flex: 1;
        margin-left: 16px;
        display: flex;
        justify-content: space-between;

        .left_Item {
          position: relative;
        }

        .name {
          font-family: 'Zona Pro';
        }

        .sku {
          color: rgba($color: #212121, $alpha: 0.44);
          margin-bottom: 8px;
        }

        .shortBlock {
          padding: 5px 6px;
          background: #e0e0e0;
          font-family: 'FZLanTingHeiS-R-GB';
          color: rgba($color: #212121, $alpha: 0.6);
          margin-right: 8px;
        }

        .data {
          opacity: 0.8;
        }

        .select_color {
          font-family: 'Zona Pro';
        }

        .poab {
          position: absolute;
          bottom: 30px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }

        .color {
          margin-right: 10px;
        }

        .price {
          font-family: 'Zona Pro';
        }
      }
    }

    .ant-drawer-content-wrapper {
      height: 100% !important;

      .ant-drawer-header {
        padding-bottom: 0;
        border-bottom: none;
      }

      .icon {
        width: 25px;
        height: 25px;
      }

      .itemPicture {
        width: 100px;
        height: 100px;
      }

      .copy {
        width: 15px;
        height: 15px;
      }
    }

    .timeLine {
      position: relative;

      .noTrack {
        color: rgba($color: #212121, $alpha: 0.6);
        height: 100%;

        .iconfont {
          font-size: 33px;
        }
      }

      .ant-timeline {
        position: absolute;
        left: 47px;
        top: 32px;

        .ant-timeline-item {
          padding-bottom: 40px;
          display: block;
          height: auto;

          &:first-child {
            .ant-timeline-item-content {
              width: 232px;
            }
          }

          &:nth-child(2) {
            .ant-timeline-item-label {
              div {
                color: #212121;
              }
            }

            .ant-timeline-item-content {
              div {
                color: #212121;
              }
            }

            .ant-timeline-item-head {
              background-color: #212121 !important;
              color: #212121 !important;
              border: #212121;
            }
          }
        }

        .ant-timeline-item-tail {
          left: 10%;
        }

        .ant-timeline-item-label {
          top: -15px;
          left: -47px;
          width: auto;
        }

        .ant-timeline-item-content {
          width: 190px;
          left: calc(10% - 4px);
        }

        .color216 {
          color: rgba($color: #212121, $alpha: 0.6);
        }

        .small {
          transform: translateX(4px);

          p {
            transform: scale(0.9);
          }
        }

        .status {
          transform: translateY(-9px);
        }

        .tans8 {
          transform: translateY(-8px);
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }

      .ant-timeline-item-head {
        left: 10%;
        width: 7px;
        height: 7px;
        margin-left: -2px;
        background-color: #b3b3b3;
      }

      .lastDot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #212121;
        line-height: 20px;
        text-align: center;
        color: #fff;
        position: absolute;
        left: -3px;
        top: -5px;

        div {
          margin: auto;
          font-size: 12px;
          transform: scale(0.8);
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.mt110 {
  margin-top: 110px;
}