.mobile-member-purchase-limit {
    .ant-modal-body {
        padding: 24px 20px;
        box-sizing: border-box;
    }

    .ant-modal-close-x {
        color: #212121;
    }

    .tip-content {
        text-align: center;

        .tip {
            width: 100%;
            margin: 24px auto 40px;
            color: #616161;
        }

        .chat-btn {
            color: #212121;
            cursor: pointer;
            text-decoration: underline;
        }

        button {
            width: 200px;
            height: 50px;
            line-height: 50px;
            padding: 0;

            &+button {
                margin-left: 20px;
            }
        }
    }

}