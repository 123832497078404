.NbitmapMOB {
  .swiperMob {
    position: relative;
  }
  .imgBlock {
    position: relative;
  }
  .swiper-menu {
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }
  .menu-left {
    left: 5px;
    &.external {
      left: -40px;
    }
  }
  .menu-right {
    right: 5px;
    &.external {
      right: -40px;
    }
  }
}
