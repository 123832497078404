.mobile-home {
  width: 100%;
  .notShow{
    width:0;
    height:0;
    opacity:0;
}
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: fixed;
  top: 13%;
  left: 0;
  z-index: 100002;
  width: 100%;
  pointer-events: none;
}