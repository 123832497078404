.jc-store-self-collection-com {
  .jc-tips {
    color: #212121;
    opacity: 0.6;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .jc-from {
    display: flex;
    flex-direction: column;

    .jc-from-items {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      min-width: 740px;
    }
    .jc-form-item-flex-40 {
      flex: 0 1 32%;
    }
    .jc-form-item-flex-1 {
      display: flex;
      flex: 0 1 20%;
      justify-content: space-between;

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
    .jc-form-bottom {
      justify-content: flex-start;
    }

    .jc-color-red {
      color: red;
    }

    .mb-18{
      margin-bottom: 18px;
    }
  }

  .jc-address-div {
    display: flex;
    align-content: center;
    flex-direction: column;
		gap: 9px;

    .jc-address-span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .jc-self-address-btn {
    width: 198px;
    align-content: center;
    line-height: 10px;
  }

  .icon-a-fasongdaohang-m{
    margin-right: 10px;
  }

  .jc-content {
    margin-top: 20px;
  }

  .jc-address-item {
    margin-bottom: 30px;
  }

  .jc-address-content {
    flex: -1%;
  }

  .jc-address-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .jc-address-icon {
    margin-right: 20px;
  }

  .jc-flex-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .jc-font-s {
    font-size: 14px;
    font-weight: 400;
  }

  .jc-font {
    color: #212121;
    opacity: 0.6;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }

  .jc-font-f {
    font-family: 'Zona Pro';
  }

  .jc-tb-border {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    opacity: 0.6;
    padding: 12px 0;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .jc-btn-w100 {
    width: 100px;
  }

  .jc-mb-6 {
    margin-bottom: 6px;
  }

  .jc-mt-8 {
    margin-top: 8px;
  }

  .jc-mg-20 {
    margin: 20px 0;
  }

  .jc-mt-20 {
    margin-top: 20px;
  }

  .jc-mr-20 {
    margin-right: 20px;
    width: 30px;
  }

  .ant-collapse-header {
    padding: 0 !important;
    position: relative;

    .ant-collapse-arrow {
      right: 0 !important;
    }
  }

  .ant-collapse-item {
    padding: 20px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .ant-collapse-header-text {
    font-weight: 400;
    font-size: 18px;
    color: #212121;
  }

  .ant-form:nth-child():last-child {
    padding: 0 !important;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-input[disabled] {
    -webkit-text-fill-color: #212121;
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
    color: #212121;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-select-selector {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-input {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-select-selection-placeholder {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: rgba(#212121, 0.5);
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-input {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: #212121;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #f3f3f3;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    border: none;
  }

  .ant-select-selection-item {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.466667px;
    color: #212121;
  }
}
