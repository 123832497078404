.mob-resetPassword {
  margin: auto;
  padding: 0 20px;
  .resteForm {
    width: 100%;
    margin: auto;
    button {
      width: 100%;
      height: 50px;
    }
  }
}
