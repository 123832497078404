@import '@/style/mixin';

.cartIndex_mob {
  position: relative;
  font-family: 'FZLanTingHeiS-R-GB';
  color: #212121;

  .wordBreakAll {
    word-break: break-all;
  }

  a{
    display: block;
  }

  .empty {
    margin: 56px 0 80px;
    text-align: center;

    button {
      width: 128px;
      height: 50px;
      margin: 24px 0px 0px;
      background: #FFFFFF;
      color: #000;
      border: 1px solid #989898;
      box-shadow: none;
      text-shadow: none;
    }
  }

  .cart-list {

    .cart-left {
      display: flex;
      height: max-content;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      margin-top: 12px;
      padding: 16px 0 8px;

      .cart-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .cart-item {
        width: 100%;
        margin-bottom: 16px;
        flex: 1;

        &.invalid {

          .product-img,
          .product-info-detail {
            opacity: 0.5;
          }
        }

        .cart-item-main {
          background-color: #fff;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
        }

        .txt-left {
          p {
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }

        .product-img {
          width: 150px;
          height: 150px;
        }

        .product-info {
          flex: 1;
          padding: 16px;
        }

        .name {
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #242424;
          word-break: break-all;
        }

        .sku-label {
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          zoom: 0.85;
          line-height: 12px;
          letter-spacing: 0.2px;
          color: #757575;
          margin-bottom: 8px;
        }

        .tag {
          position: absolute;
          top: 0;
          left: 0;
          background: #FAFAFA;
          padding: 5px 8px;
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #212121;
        }

        .price {
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #242424;
          margin-bottom: 10px;
        }

        .oldPrice {
          text-decoration-line: line-through;
          opacity: 0.4;
        }

        .finalPrice {
          margin-left: 8px;
          color: #C1281B;
        }

        .swatch-item {
          &+.swatch-item {
            margin-top: 8px;
          }

          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #242424;

          span:first-child {
            margin-right: 8px;
          }

          .swatch_image {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 4px;
            position: relative;
            top: 2px;
          }
        }

        .handle-area {
          line-height: 1;
          color: #242424;
          margin-top: 8px;
          margin-bottom: 14px;
          font-size: 12px;
          line-height: 16px;

          .iconfont {
            position: relative;
            top: 1px;
          }
        }

        .cart-item-buttom-tip {
          margin-top: 20px;
          font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #242424;

          &+.cart-item-buttom-tip {
            margin-top: 4px;
          }
        }
      }

      .invalid-cart-title {
        margin-bottom: 16px;

        .title {
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);

        }

        .delete-invalid {
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #242424;
          border-bottom: 1px solid currentColor;
        }

      }

    }

    .error-item-list {
      background: #F5F5F5;
      font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: #242424;
      border: 1px solid #616161;
      padding: 16px;
      margin-bottom: 24px;
      position: relative;

      .title {
        font-weight: 400;
        line-height: 24px;
        font-style: normal;
        margin-top: 16px;

        &:first-of-type {
          padding-right: 30px;
          margin-top: 0;
        }
      }

      .go-invalid {
        position: absolute;
        right: 16px;
        top: 19px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        border-bottom: solid 1px currentColor;
        color: #242424;
        cursor: pointer;
      }

      .error-item {
        margin-top: 8px;
        margin-left: 18px;
        font-size: 12px;
        line-height: 16px;
        color: #757575;

        .iconfont {
          line-height: 1;
        }

        .product_name {
          font-weight: 600;
          color: #242424;
        }

        .swatch-item {
          font-weight: 400;
          margin-left: 8px;
        }

      }
    }

    .coupon {
      border-bottom: 1px solid #f0f0f0;


      .coupon-content {
        .couponselect {
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          padding: 17px 16px;
          background: #fff;
        }


        .place {
          color: #757575;
        }

        .icon-xiajiantou {
          font-size: 16px;
          transition: all 300ms ease-in;
        }

        .couponIcon {
          transform: rotateX(180deg);
        }
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox {
        transform: scale(0.67);
      }

      margin-right: 8px;
      flex-shrink: 0;
    }

    .ant-checkbox-group {
      .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin: 0;
      }

      .jcm-cart-items {
        .ant-checkbox-wrapper {
          position: relative;
          width: 24px;

          .ant-checkbox {
            position: absolute;
            top: 60px;
          }

          .ant-checkbox-checked {
            position: absolute;
            top: 60px;
          }
        }
      }

      .cart-check {
        // display: flex;
        // align-items: center;
        font-size: 13px;
      }
    }

    .sub-right_mob {
      width: 100%;
      font-size: 14px;
      font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
      color: #212121;


      .sub-info {
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px -6px 32px rgba(0, 0, 0, 0.04);

        p {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
        }

        .line {
          margin: 24px 0;
          border-bottom: 1px solid #eee;
        }

        .total-price {
          font-weight: 600;
          margin-bottom: 24px;
        }

        .btn {
          width: 100%;
          height: 56px;
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 1px;
          text-transform: capitalize;
          color: #FAFAFA;
        }

        .illegal {
          background: #FAFAFA;
          border: 1px solid #D7D7D7;
          color: #BDBDBD;
          text-shadow: none;
          box-shadow: none;
        }

        .pay-icon-list {
          margin-top: 16px;

          .iconfont {
            font-size: 24px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.coupon-form {
  width: 100%;

  .coupon-input {
    display: inline-block;
    width: calc(100% - 59px);

    input {
      height: 40px;

    }
  }

  button {
    height: 40px;
    width: 59px;
  }

  .ant-form-item {
    margin-right: 0;
  }
}

.mob-coupon-list {
  width: 100%;
  padding: 16px 16px 0;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));

  input {
    border-color: rgba(0, 0, 0, 0.4);
  }

  .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .list-item {
    padding: 16px 20px;
    box-sizing: border-box;
    border: 1px solid #E7E7E7;
    margin-top: 20px;
    &+.list-item {
      margin-top: 16px;
    }
    .name {
      height: 25px;
    }
  }

  .active {
    border-color: rgba(0, 0, 0, .48);
  }
}

.mob-coupon-detail {
  height: 70vh;
  overflow-y: scroll;
  @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));

  .code {
    width: 130px;
    height: 130px;
    margin: 20px auto;
  }

  li {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    color: #212121;
    font-size: 12px;
    font-family: 'FZLanTingHeiS-R-GB';
    display: flex;
    flex-wrap: nowrap;


    .key {
      display: inline-block;
      width: 70px;
      margin-right: 10px;
    }

    .text {
      flex: 1;
    }
  }
}