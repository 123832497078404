.flow-model {
  width: 100%;
  height: fit-content;
  text-align: center;

  .layer_button {
    padding: 1px;

    .w100pc {
      width: 100%;
    }

    a {
      display: inline-block;
      color: #212121;
      // padding: 10px 30px;
    }

    a+a {
      margin-left: 24px;
    }
  }
}

.position-float {
  width: 100%;
  position: absolute;
  padding: 0 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;

  .layer_button {
    padding: 1px;

    a {
      display: inline-block;
      background: #fff;
      color: #212121;
      // padding: 10px 30px;
    }

    a+a {
      margin-left: 20px;
    }
  }
}

.flow-model,
.position-float {

  h1,
  h2,
  p {
    margin-top: 10px;
    font-family: 'FZLanTingHei-M-GBK';
    color: #212121;
  }
}

.need-seat {
  height: 20px;
}