.mobile-footer {
  width: 100%;
  background: #FAFAFA;

  .mobile-eamil-box {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    background: #FAFAFA;

    h4 {
      font-size: 28px;
      line-height: 33px;
    }

    .sub-title {
      text-align: center;
    }

    .step2 {
      margin-top: 80px;
      margin-bottom: 0;
    }

    .step3 {
      text-align: center;
    }
  }

  .mobile-footer-content {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 16px 14px;
    background-color: #212121;
  }

  .mobile_colla {
    width: 100%;
    height: 100%;
    user-select: none;

    .ant-collapse-borderless {
      background-color: #212121;
    }

    .ant-collapse-header {
      padding: 28px 0 !important;
    }

    .ant-collapse-header-text {
      width: 100%;
    }

    .ant-collapse-borderless>.ant-collapse-item {
      border-bottom: 1px solid #424242;
    }

    .ant-collapse-content-box {
      padding-left: 0;
    }
  }

  .head {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .iconfont {
      color: #fff;
    }

    .icon-shangjiantou,
    .icon-xiajiantou {
      font-size: 13px !important;
      font-weight: bolder;
    }

    .left {
      display: flex;
      align-items: center;

      .iconfont {
        margin-right: 9px;
        font-size: 12px;
      }



      .head_title {
        color: #fff;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .content {
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: #fff;
    text-align: left;
    padding-top: -10px;
    user-select: none;

    .two_tav {
      margin-bottom: 16px;
      display: block;
      user-select: none;
      outline: none;
      width: 4.5rem;
      min-width: 4.5rem;
      max-width: 4.5rem;

      div {
        user-select: none;
        width: 4.5rem;
        min-width: 4.5rem;
        max-width: 4.5rem;
      }

      &:hover {
        color: #fff;
        text-decoration: underline #fff;
      }
    }

    .two_tav:active {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-user-select: none;
      opacity: 1;
      color: #fff;
    }
  }

  .mobile_center {
    .share {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      .iconfont {
        font-size: 26px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .logo {
      margin-top: 65px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #e0e0e0;
      }

      .w70 {
        width: 50px;
      }
    }
  }

  .mobile_bottom {
    margin-top: 31px;
    margin-bottom: 8px;

    li {
      display: flex;
      margin-top: 16px;

      .police {
        margin-right: 8px;
        width: fit-content;
        height: fit-content;
      }

      .detail {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #e0e0e0;
      }
    }
  }

  .code {
    width: 100%;
  }
}