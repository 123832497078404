.OrderMob {
  width: 100%;
  height: inherit;
  .noOrder {
    font-family: 'FZLanTingHeiS-R-GB';
    height: 136px;
    text-align: center;
    line-height: 136px;
  }
  .haveOrder {
    width: 100%;
    .OrderBlock_mob {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      font-family: 'FZLanTingHeiS-R-GB';
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .mr14 {
        margin-right: 14px;
        width: 103px;
        height: 103px;
      }
      .rightInform {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'FZLanTingHeiS-R-GB';
        .mb8 {
          margin-bottom: 8px;
        }
        .textunder {
          text-decoration: underline;
          cursor: pointer;
        }
        .blackBlock {
          width: 92px;
          height: 40px;
          background: #212121;
          text-align: center;
          line-height: 40px;
        }
        .account {
          font-family: 'Zona Pro';
        }
        .mt12 {
          margin-top: 12px;
          width: 100%;
        }
      }
    }
  }
}
