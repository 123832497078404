.mob-fourSeat {
  margin: auto;
  height: auto;
  // margin-bottom: 40px;
  width: 100%;

  .jcm-title-mb{
    margin-bottom: 25px;
  }

  .jc-carousel{
    margin-top: 25px;
  }

  .four_title_top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ant-carousel .slick-list .slick-slide>div>div {
    vertical-align: top;
  }

  .fourCarou {
    position: relative;
    // padding: 0 15px 0 20px;

    .fourSeat_block {
      .good_media_mob {
        width: calc(100% - 5px);
      }
    }
  }

  .leg5 {
    // margin-right: 8px;
    margin-bottom: 25px;
  }

  // .slick-track {
  //   height: 50%;
  // }
  // .slick-current {
  //   .fourSeat_block {
  //     padding-right: 5px;
  //   }
  // }
  .slick-dots-bottom {
    bottom: 0 !important;
  }

  .title {
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 12px;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .describe {
    margin-bottom: 15px;
    text-align: center;
    font-size: 12px;
    font-family: "FZLanTingHeiS-R-GB","Zona Pro",sans-serif;
  }

  .slick-dots {
    margin: auto;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #e0e0e0;
    }

    button {
      background-color: #212121 !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;
    }

    .slick-active {
      width: 8px !important;
      background-color: #212121;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 41%;
    transform: translateY(-50%);
    width: 12px;
    height: 24px;
  }

  .menu-left {
    left: 20px;
  }

  .menu-right {
    right: 20px;
  }

  .lessFour {
    position: relative;

    // .antCarou {
    //   margin-bottom: 20px;
    // }

    .good_card_mob {
      padding: 0 5px;
    }

    .slick-track {
      .slick-slide {
        &:last-child {
          .leg5 {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.left_alignment {
  .title {
    text-align: left !important;
  }

  .describe {
    text-align: left !important;
  }
}

.right_alignment {
  .title {
    text-align: right !important;
  }

  .describe {
    text-align: right !important;
  }
}

.noMargin {
  margin-bottom: 0 !important;
  padding-bottom: 1px;
}