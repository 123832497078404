#loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    opacity: .7;
    z-index: 9998;
    display: none;
    img{
        width: 70px;
        height: 70px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
    }
    
}