.pc-sixSeat {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  // margin-bottom: 60px;
  position: relative;
  width: 95%;
  text-align: center;

  .leg7 {
    margin-right: 8px;
  }

  .title {
    text-decoration: none;
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #212121;
    margin: auto;
    margin-bottom: 25px;
  }

  .describe {
    margin-bottom: 25px;
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616161;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-list {
    width: 94%;
    margin: auto;
    box-sizing: border-box;

    .slick-track {
      width: 94%;
      margin: auto;
      box-sizing: content-box;

      .slick-slide {
        &:last-child {
          .media_block {
            margin-right: 0;
          }
        }
      }
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 80px;
  }

  .menu-right {
    right: 80px;
  }

  .sixSeat_block {
    width: fit-content;
  }
}