.addressformMob {
  .input335 {
    width: 335px;
  }

  .sbumitBtn {
    width: 100%;
    height: 50px;
    font-family: 'FZLanTingHei-M-GBK';
    font-size: 14px;
    letter-spacing: 0.466667px;
  }

  .cancel_btn {
    margin-right: 13px;
  }

  .btns {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
    }
  }

  .dob .ant-form-item-control-input-content {
    display: flex;
  }

  .dob .ant-form-item-control-input-content {
    display: flex;

    .input-select {
      width: 131px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .w120 {
    width: 120px;
    margin-right: 8px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .ant-input[disabled] {
    -webkit-text-fill-color: #212121;
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-family: 'Zona Pro';
    color: #212121;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-select-selector {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-input {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-select-selection-placeholder {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: rgba(#212121, 0.5);
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-input {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: #212121;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    border: none;
  }

  .ant-select-selection-item {
    font-family: 'FZLanTingHeiS-R-GB';
    line-height: 15px;
    color: #212121;
  }

  // .ant-form-item:last-child {
  //   margin-bottom: 0;
  //   .ant-btn{
  //     margin-bottom: 0;
  //   }
  // }
}