@import '@/style/mixin';


.pc-header-content {

  width: 100%;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  // position: sticky;
  z-index: 1002;

  .jc-world-tabs {
    display: flex;
    align-items: flex-start;
  }

  .scroll-is-show-head {
    position: absolute;
    top: -240px;
    transition: top 0.5s ease;
  }

  .top_black_block {
    // width: 100%;
    // width: 99vw;
    height: 26px;
    display: flex;
    z-index: 1001;
    // position: fixed;
    // top: 0;
    // transition: top 0.5s ease;

    .left_inform {
      position: relative;
      background-color: #212121;
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFF;

      .block_sale {
        padding: 0 220px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        // fixbug: 2023-1-3 11:57
        a:hover {
          color: #fff;
        }

        h2 {
          font-size: 12px;
        }
      }

      .icon-guanbi {
        position: absolute;
        top: 0;
        right: 314px;
        font-size: 20px;
        cursor: pointer;
        color: #e0e0e0;
      }

      .shipping-right,
      .shipping-left {
        height: 26px;
        // width: 310px;
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      .right_shop-box,
      .left_shop-box {
        // position: absolute;
        font-size: 12px;
        line-height: 26px;
        flex: 0 1 50%;
        height: 100%;

        .icon-jingpindian {
          margin-right: 10px;
          font-size: 14px;
        }


        .right_shop,
        .left_shop {
          // width: 155px;
          height: 100%;
          color: #fff;
          background-color: #212121;
          padding: 0 32px;
          word-break: keep-all;
          white-space: nowrap;
          // border-left: 1px solid #797979;

          .shop {
            margin-left: 8px;
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.466667px;
            transform: translateY(-1px);
          }
        }

        .right_shop {
          border-left: 1px solid #797979;
        }

        .left_shop {
          border-right: 1px solid #797979;
        }
      }
    }
  }

  .hide {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: close;
  }

  .out_block {
    position: relative;
  }

  .closeTab {
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: closeAll;
  }

  .open_banner {
    width: 100%;
    position: relative;
    z-index: 1001;
    transition: top 0.5s ease;
  }

  .no_hover {
    pointer-events: none;
  }

  .tab_pc {
    width: 100%;
    // height: 80px;
    overflow: visible;
    position: fixed;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    // padding: 0 16px 0 24px;
    box-sizing: border-box;

    .left_tab {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      z-index: 1001;

      .tab_list {
        margin-left: 40px;
        display: flex;
        height: 100%;
        align-items: center;
        gap: 32px;

        .tab_item {
          padding: 24px 17.5px;
          height: 100%;
          // font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 600;
          color: #212121;
          display: flex;
          align-items: center;

          .red {
            color: #c1281b !important;

            &:hover {
              border-bottom: 1px solid #c1281b;
            }
          }

          .hover-underline {
            // transform: translateY(4px);
            border-bottom: 1px solid transparent;
            min-width: 29px;
            border-bottom: 1px solid #212121;
            color: #212121;

            &.red {
              border-bottom: 1px solid #c1281b;
            }

            // &:hover {
            //   border-bottom: 1px solid #616161;
            //   color: #616161;
            // }
          }
        }
      }
    }

    .right_images {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .hotarea {
        height: 100%;
        padding: 0 6px;
        position: relative;

        &:hover {
          color: #616161 !important;

          .iconfont {
            color: #616161 !important;
          }

          .tipsWish {
            width: 50px;
            overflow: hidden;
            transition: all 0.3s ease-in;

            a {
              color: #616161 !important;
            }
          }

          // opacity: 0.7;
        }

        .tipsWish {
          width: 0;
          overflow: hidden;
          transition: all 0.3s ease-in;
          height: 20px;
          font-weight: 600;
          color: #212121;
          font-size: 14px;
        }
      }

      .username {
        display: inline-block;
        min-width: 30px;
      }

      .minicart,
      .miniLogin {
        position: absolute;
        z-index: 2;
        top: 76px;
        right: -20px;
        z-index: 999;
        color: #212121;
        text-align: center;
        background: transparent;
        //transform: translateY(100%);

        .positionel {
          margin: 12px 8px 8px;
          box-sizing: border-box;
          background: #fff;
          height: fit-content;
          box-shadow: 0 3px 9px #bbb;

          &::before {
            content: '';
            position: absolute;
            top: 2px;
            right: 30px;
            width: 0;
            height: 0;
            border-color: transparent transparent #fff;
            border-style: solid;
            border-width: 0 10px 10px;
            filter: drop-shadow(0 -1px 1px rgba(65, 64, 64, 0.2));
          }
        }
      }

      .minicart {
        right: -18px;
      }

      .cart {
        background-color: initial;

        &:hover .minicart {
          // max-height: 600px;
          max-height: calc(100vh - 150px);
          transition: all 500ms ease-in;
        }
      }

      .login {
        @media screen and (max-height: 800px) {
          &:hover .miniLogin {
            max-height: 510px;
            transition: all 500ms ease-in;
          }

          .showminiLogin {
            max-height: 510px;
            transition: all 500ms ease-in;
          }
        }

        @media screen and (min-height: 800px) {
          &:hover .miniLogin {
            max-height: calc(100vh - 1.08333rem);
            transition: all 500ms ease-in;
          }

          .showminiLogin {
            max-height: 1000px;
            transition: all 500ms ease-in;
          }
        }

      }

      .search {
        .icon-sousuo {
          position: relative;
        }

        &:hover .icon-sousuo {
          color: #616161;
        }

        .hover-search {
          color: #616161;
        }

        .hover-search::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #616161;
          bottom: 9px;
          left: 0;
        }

        &:hover .tips {
          width: 30px;
          opacity: 1;
          overflow: hidden;
          transition: width 0.3s ease-in;
          color: #616161 !important;
        }

        .tips {
          width: 0;
          overflow: hidden;
          opacity: 0;
          transition: all 0.3s ease-in;
          height: 20px;
          font-weight: 600;
          color: #212121;
          font-size: 14px;
        }
      }

      .minicart,
      .miniLogin {
        max-height: 0;
        overflow: hidden;
      }

      .showMinCart .minicart {
        // max-height: calc(100vh - 150px);
        // transition: all 600ms ease-in;
      }
    }
  }

  .closeTwo_tab {
    display: none;
  }

  .top80 {
    top: 80px;
  }

  .top110 {
    top: 110px;
  }


  .showTwo_tab {
    // overflow: hidden;
    // position: absolute;
    padding: 36px 24px;
    width: 100%;
    z-index: 1001;
    left: 0;
    height: fit-content;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: openTwo_tab;
    background-color: #fff;
    border-top: 1px solid #eee;
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    // transition: top .1s ease-out;
    .tabList {
      width: 100%;
      height: max-content;
      text-align: left;
      display: flex;
      overflow-x: auto;

      .swiper_picture {
        width: 226px;
        margin-right: 40px;
        padding-bottom: 20px;

        .h20 {
          line-height: 20px;
        }

        .picture {
          width: 226px;
          height: 226px;
        }

        .slick-dots {
          width: fit-content;
          right: 0 !important;
          bottom: 5px;
          margin: 0;
          left: auto;

          li {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #e0e0e0;
          }

          button {
            background-color: #212121 !important;
            width: 6px !important;
            height: 6px !important;
            border-radius: 50% !important;
          }

          .slick-active {
            width: 6px !important;
            background-color: #212121;
          }
        }
      }

      .tile_picture {
        display: flex;

        .pictureBlock {
          width: 226px;
          margin-right: 40px;

          .h20 {
            height: 20px;
          }

          .picture {
            width: 226px;
            height: auto;
          }
        }
      }

      .new-menu {
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: auto;

        &-item {
          width: 160px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid #EEE;
          padding-bottom: 16px;
        }
      }

      .two_list {
        width: 160px;
        margin-right: 32px;
        height: auto;
      }

      .two_name {
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #212121;
        margin-bottom: 12px;
      }

      .jc-world-name span {
        height: 16px;
        display: inline-block;
      }

      .jc-world-name:hover span {
        border-bottom: 1px solid #212121;
      }

      .three_Name {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #616161;
        margin-bottom: 4px;

        a {
          &:hover span {
            border-bottom: 1px solid #616161;
            color: #616161;
          }
        }
      }
    }
  }

  .fixedTab {
    position: fixed;
    top: 0;
  }

  @keyframes toTop {
    0% {
      top: 110px;
    }

    100% {
      top: 0;
    }
  }


  @keyframes close {
    0% {
      height: 30px;
      opacity: 1;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }

  @keyframes closeAll {
    0% {
      top: 0;
      opacity: 1;
      height: 110px;
    }

    100% {
      opacity: 0;
      top: -110px;
      height: 0;
    }
  }

  @keyframes openAll {
    0% {
      top: -110px;
      opacity: 0;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes openTwo_tab {
    0% {
      opacity: 0;
      height: 0;
    }

    100% {
      opacity: 1;
      height: fit-content;
    }
  }
}

.logo {
  width: 234px;
}

.bag_tab {
  margin: auto;
  height: 56px;
  display: flex;
  align-items: center;
  font-family: 'FZLanTingHeiS-R-GB';
  position: relative;

  .left {
    display: flex;
    align-items: center;

    .backBag {
      margin-left: 13px;
    }
  }

  .centerLogo {
    flex: 1;

    .logo {
      margin: auto;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .contactUs {
      margin-right: 10px;
    }
  }
}

.bag_tab::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100vw;
  height: 1px;
  background-color: #ebebeb;
}

.tip_swiper {
  height: 26px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 20px;

  .tip_transform {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

}

.other-style {
  text-indent: 12px; // 缩进 居中
}

.scroll-item {
  text-align: center;
  color: #fff;
  font-family: 'FZLanTingHeiS-R-GB';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  box-sizing: border-box;
  white-space: nowrap;
  width: fit-content;
  min-width: 100%;
  white-space: nowrap;
  // padding: 0 30px 0 30px; //需要全局居中 所以左侧需要留出右边精品店的210px
  // padding-left: 20px;
  cursor: pointer;

  a {
    color: #fff;
    display: inline-block;
    width: 100%;

    :link {
      color: #fff;
      text-decoration: none;
    }

    :visited {
      color: #fff;
      text-decoration: none;
    }

    :hover {
      color: #fff;
      text-decoration: none;
    }

    :active {
      color: #fff;
    }
  }

  a::after {
    color: #fff;
  }
}

// 轮播 修改
// .bq-title-width{
//   overflow-wrap: anywhere;
//   overflow: hidden
// }
// .slick-dots-bottom{
//   height: 70px;
//   display: flex;
//   place-items: flex-end;
// }

.search_mark {
  position: absolute;
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 111px, rgba(0, 0, 0, .18) 0, rgba(0, 0, 0, .18));
  z-index: 1000;
  // opacity: 0;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 111px, rgba(0, 0, 0, .18) 0, rgba(0, 0, 0, .18));
  z-index: 1000;
  // opacity: 0;
}