.pc-pay-result {
    margin: auto;
    font-family: 'FZLanTingHeiS-R-GB';
    .notShow{
        width:0;
        height:0;
        opacity:0;
    }
}
.pc-pay-result.w1440{
    padding-bottom: 160px;
}