.two_drawer {
  width: 100%;
  height: 100%;


  .new-menu {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    // gap: 16px;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      // gap: 10px;
      margin-top: 32px;

      // border-bottom: 1px solid #EEE;
      // padding: 10px 0;
    }
  }

  .top_banner_twoDraw {
    position: relative;
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;
    height: 64px;
    margin: 0 24px;
    border-bottom: 1px solid #bdbdbd;

    .backIcon {
      font-size: 12px;
      font-weight: 600;
    }

    .logo {
      position: absolute;
      right: 0;
      width: 17px;
      height: 22px;
      object-fit: contain;
    }

    .back {
      margin-left: 22px;
    }
  }

  .drawer_content {
    padding: 26px;
    // font-family: 'FZLanTingHei-M-GBK';

    .category_title {
      font-size: 16px;
    }

    .category_name {
      margin-top: 32px;
      position: relative;

      .anticon-right {
        position: absolute;
        right: 0;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }

  .center_pictureBox {
    padding: 0 26px;
    // padding-top: 0;
  }

  .ant-drawer-content-wrapper {
    width: 314px !important;
  }
}