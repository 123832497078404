.mob-oneSeat {
  // margin-bottom: 35px;
  width: 100%;

  .oneSeat_block {
    position: relative;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: space-between;

    .img_video_two {
      margin: auto;
    }

    .fle {
      display: flex;

      &.img_video_two {
        .media_content {
          width: 70%;
        }
      }
    }

    .icon_word {
      margin: auto;
      width: 30%;
      justify-content: center;

      .one-icon {
        margin-bottom: 15px;
        width: 100%;
        height: auto;
      }

      .iconWord {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }

    .describe {
      text-align: left;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 24px;
  }

  .menu-left {
    left: 0px;
  }

  .menu-right {
    right: 0px;
  }
}