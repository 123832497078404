.appointShopM {
  .ant-drawer-mask{
    background: white;
  }
  .ant-drawer-content-wrapper {
    position: relative;
  }
  .goodImg {
    width: 132px;
    height: 132px;
    margin-right: 16px;
  }
  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .bottom {
    margin-bottom: 17px;
    .colorBD {
      color: #bdbdbd;
    }
  }
  .mr16 {
    margin-right: 16px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .fw600 {
    font-weight: 600;
  }
  .fw700 {
    font-weight: 700;
  }
  .color4242 {
    color: #424242;
  }
  .colorBD {
    color: #bdbdbd;
  }
  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.466667px;

    color: #212121;

    &.gray {
      margin-right: 8px;
      text-decoration: line-through;
      color: rgba(33, 33, 33, 0.4);
    }

    &.final_price {
      color: rgba(193, 40, 27, 1);
    }
  }

  .shopItem {
    .w240 {
      width: 100%;
      height: 56px;
      background: #ffffff;
      color: #212121;
      border: 1px solid #e0e0e0;
    }
  }

  .section-right {
    height: 230px;
  }

  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }
  .ant-drawer-header {
    padding: 13px 0 12px 0;
    border: none;
    .ant-drawer-close {
      color: #212121;
    }
  }

  .ant-pagination-item {
    a {
      line-height: 26px;
      color: #757575;
    }
  }
  .ant-pagination-item-active {
    border: none;
    a {
      line-height: 26px;
      color: #212121;
    }
  }
  .ant-pagination-next {
    line-height: 24px;
    .ant-pagination-item-link {
      line-height: 24px;
    }
  }
}
