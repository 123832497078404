@import '@/style/mixin';

.appointPerson {
  height: 626px;
  padding-bottom: 0;

  .ant-modal-content {
    height: 626px;
  }

  .w367 {
    width: 367px;
    background: #f5f5f5;
    overflow-y: scroll;
    @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1));

    .h341 {
      height: 341px;
    }
  }

  #is_sign_help,
  #is_agree_help {
    padding-left: 32px
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .privacy {
    font-size: 13px;
    color: rgba(33, 33, 33, 0.6);

    span {
      padding-right: 0;
    }
  }

  .color4242 {
    color: #424242;
  }

  .mr16 {
    margin-right: 16px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .w254 {
    width: 254px;
    height: 56px;
    background-color: #212121;
    color: #fff;
  }

  .name {
    position: relative;

    .surname {
      position: absolute;
      top: 12px;
      left: 16px;
      color: #757575;
    }
  }

  .w290 {
    width: 290px;

    .ant-form-item-control-input-content {
      position: relative;
    }
  }

  .pore {
    position: relative;
    flex: 1;
  }

  .page {
    position: absolute;
    bottom: 17px;
    right: 14px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .pore_box {
    height: 92%;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 24px;
  }

  .pore {
    padding-top: 46px;
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;

    .pore_box::-webkit-scrollbar {
      width: 0.06944rem;
      height: 0.06944rem;
      background-color: white;
    }

    .pore_box::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 1);
      -webkit-transition: all .2s;
      transition: all .2s;
      border-radius: 0.06944rem;
    }
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .ant-input {
    &::placeholder {
      color: #757575 !important;
    }
  }

  .ant-select-selection-placeholder {
    color: #757575 !important;
    font-size: 13px;
  }

  .ant-form {
    padding-bottom: 30px;
  }

  .ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-label>label::after {
      content: '';
    }

    .ant-form-item-label .ant-form-item-required::after {
      content: '*';
      display: inline-block !important;
      color: #c1281b;
      font-size: 0.22222rem;
    }

    .ant-row {
      flex-direction: column;

      .ant-form-item-control-input-content {
        .ant-checkbox-wrapper {
          width: fit-content !important;
        }
      }

      label {
        height: 30px;
        width: 100%;
        font-size: 13px;
        color: #757575;
      }
    }
  }
}