.share-list-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    background: #F5F5F5;
                }
            }
        }
    }
}

.share-list-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-body {
                padding: 0;
                font-family: 'FZLanTingHeiS-R-GB';

                .share-list-top {
                    box-sizing: border-box;
                    width: 100vw;
                    // height: 248px;
                    padding: 30px 22px 20px;
                    background: #F5F5F5;

                    .left-title {
                        color: #212121;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 13px;
                    }

                    .link-operate-area {
                        box-sizing: border-box;
                        height: 124px;
                        background: #ffffff;
                        padding: 14px 14px 6px;

                        .link-title {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 22px;
                            color: #757575;
                        }

                        .link-and-copy-wrapper {
                            box-sizing: border-box;
                            border: 1px solid #E0E0E0;
                            margin: 6px 0px;
                            padding: 8px 12px;
                            display: flex;
                            justify-content: space-between;

                            .link-box {
                                flex: 1;
                                font-family: 'Zona Pro';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12.6812px;
                                line-height: 20px;
                                color: #242424;
                            }

                            .copy-icon-wrapper {
                                // width: 32px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .operate-icons {
                            display: flex;

                            span.icon-item {
                                cursor: pointer;
                                width: 32px;
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 16px;

                                i.iconfont {
                                    width: 100%;
                                    height: 100%;
                                    font-size: 24px;
                                }

                                a {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    i.iconfont {
                                        width: 100%;
                                        height: 100%;
                                        font-size: 24px;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0px;
                                }
                            }
                        }
                    }
                }

                .share-list-bottom {
                    box-sizing: border-box;
                    width: 100vw;
                    padding: 24px 14px 64px;
                    background: #FFFFFF;
                    margin-bottom: 40px;

                    .form-wrapper {
                        .ant-form {
                            .ant-form-item {
                                margin-bottom: 12px !important;

                                .ant-row {
                                    .ant-col {
                                        .ant-form-item-control-input {
                                            min-height: fit-content;

                                            // .ant-form-item-control-input-content {
                                            //     .plain-text {
                                            //         font-size: 12px;
                                            //     }

                                            //     .top-plain-text {
                                            //         font-size: 24px;
                                            //         font-weight: 400;
                                            //         // line-height: 36px;
                                            //         line-height: 25px;
                                            //         text-align: center;
                                            //         letter-spacing: 0.18px;
                                            //     }
                                            // }
                                        }
                                    }
                                }
                            }

                            .form-item-btn {
                                margin-bottom: 0 !important;
                                padding-bottom: 0 !important;
                            }


                        }


                    }
                }
            }
        }
    }
}

.share-list-drawer-second {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-body {
                padding: 0;
                font-family: 'FZLanTingHeiS-R-GB';

                .share-list-content {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 30px 14px 0px;

                    .share-list-title {
                        margin: 0 10px;

                        color: #212121;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 36px;
                    }

                    .link-and-copy-wrapper-second {
                        box-sizing: border-box;
                        margin: 80px 10px;
                        border: 1px solid #E0E0E0;
                        padding: 8px 12px;
                        display: flex;
                        justify-content: space-between;

                        .link-box {
                            flex: 1;

                            font-family: 'Zona Pro';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12.6812px;
                            line-height: 20px;
                            color: #242424;
                        }

                        .copy-icon-wrapper {
                            // width: 32px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

.mob-wish-list-operate-btns {
    font-family: 'FZLanTingHeiS-DB-GB';
    display: flex;
    flex-direction: column;

    button.wish-btn {
        padding: 17px auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1px;
        width: 100%;
        height: 50px;
    }

    button.generate-wish-btn {

        color: #fafafa;
        background: #242424;

        border: 1px solid #242424;

        &:hover {
            color: #bdbdbd;
        }

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    button.cancel-wish-btn {
        margin-top: 8px !important;
        color: #242424;
        background: #ffffff;

        border: 1px solid #D7D7D7;
    }
}

.mob-weixin-overlay-class,
.mob-douyin-overlay-class {

    .ant-popover-content {
        .ant-popover-inner {
            .ant-popover-inner-content {
                width: 48vw;
                height: auto;
                box-sizing: border-box;
                position: relative;
                padding: 9px 18px 12px;

                span.iconfont {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                .weixin-template,
                .douyin-template {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-family: 'FZLanTingHeiS-R-GB';

                    font-weight: 400;


                    .pop-title {
                        font-size: 12.6px;
                        line-height: 22px;
                        color: #212121;
                        margin-bottom: 9px;
                    }

                    .pop-image {
                        display: block;
                        width: 30.4vw;
                        // height: 126px;
                    }

                    .pop-desc {
                        margin-top: 11px;
                        text-align: center;
                        color: #242424;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.mob-weixin-overlay-class {
    top: 215px !important;
    left: 22px !important;

    .ant-popover-content {
        .ant-popover-arrow {
            left: 42% !important;
        }
    }
}

.mob-douyin-overlay-class {
    top: 215px !important;
    left: 22px !important;

    .ant-popover-content {
        .ant-popover-arrow {
            left: 62% !important;
        }
    }
}