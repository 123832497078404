.mob-search {
  max-width: 1440px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 16px;

  .swiper-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 49.5%);
    grid-gap: 0 1%;
    overflow: hidden;
  }

  .filtrate-page-m {
    padding: 0;
    // transition: top 0.5S ease;
  }
}