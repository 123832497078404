@import '@/style/mixin';

.pc-home-pdp-m {
  max-width: 1400px;
  margin: 0 auto;
  min-height: 600px;

  .swiper-banner-4 {
    margin-top: 36px !important;
  }

  .swiper-container {
    padding: 16px 6px 16px 12px;
    box-sizing: border-box;
  }

  .pdp-container {
    width: 100%;

    .left-box {
      box-sizing: border-box;

      .item-img {
        margin-top: 20px;
      }
    }

    .right-box {
      margin-top: 48px;
      padding: 0 16px 0;
      text-align: left;

      .info {

        .desc {
          margin-top: 16px;
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #797979;
        }

        .tag-list {
          margin-bottom: 16px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .product-tag {
            padding: 5px;
            text-align: center;
            background: #FAFAFA;
            font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #212121;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .tag {
              display: inline-block;
            }

            .tag-icon {
              width: auto;
              height: 12px;
              vertical-align: middle;
              display: inline-block;
              margin-right: 5px;
            }
          }
        }

      }

      .color-box {
        margin: 32px 0;

        &.color-box-type-0,
        &.color-box-type-2 {
          display: flex;
          justify-content: space-between;

          .color-title {
            white-space: pre-wrap;
            margin-right: 12px;
          }

          .color-list {
            justify-content: flex-end;
          }
        }

        &.color-box-type-0 {
          .color-list {
            flex-shrink: 0;
          }
        }

        &.color-box-type-2 {
          .color-title {
            flex-shrink: 0;
          }
        }

        &.color-box-type-1 {
          .color-list {
            margin-top: 16px;
          }
        }


        .color-title {
          display: flex;

          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #797979;

          .title {
            color: #212121;
            font-weight: bold;
            margin-right: 12px;
            flex-shrink: 0;
          }
        }

        .color-list {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;

          .item {
            box-sizing: border-box;
            width: 52px;
            height: 52px;
            border-bottom: 1px solid transparent;

            &.active {
              border-bottom-color: #797979;
            }
          }
        }
      }


      .size-box {
        margin: 32px 0;

        .size-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #212121;

          .title {
            color: #212121;
            font-weight: bold;
          }

          .size-label {
            .iconfont {
              font-size: 12px;
              margin-left: 8px;
            }
          }
        }

        .size-tip {
          margin-top: 8px;
          font-family: Zona Pro, FZLanTingHeiS-R-GB;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.46666666865348816px;
          color: #797979;
        }
      }

      .sticky-box {

        &.is-sticky {
          z-index: 90; //不能超过100 左侧菜单是100
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: #fff;
          box-shadow: 0px -6px 10px 0px #0000000F;
          padding: 12px 16px;
          box-sizing: border-box;
          margin: 0;
        }

        .title-price {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .title {
            font-family: Zona Pro;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.46666666865348816px;
            text-align: left;
            word-break: break-word;
            overflow: hidden;
          }

          .price-box {
            flex-shrink: 0;
            margin-left: 12px;

            .price {
              font-family: 'Zona Pro';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;

              letter-spacing: 0.466667px;

              color: #212121;

              &.gray {
                margin-right: 12px;
                text-decoration: line-through;
              }

              &.final_price {
                color: rgba(193, 40, 27, 1);
              }
            }
          }
        }
      }

      .delivery-date {
        margin: 32px 0;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.466667px;
        color: rgba(33, 33, 33, 0.8);

        .button-above-box {
          .delivery-date-item:last-child {
            margin-bottom: 16px;
          }
        }
      }

      .arrival-date,
      .arrival-date-mto,
      .to-cart-tips {
        margin-top: 8px;
        color: #797979;
        font-size: 12px;
        font-family: 'Zona Pro', 'FZLanTingHeiS-R-GB', sans-serif;
        line-height: 18px;
        letter-spacing: 0.466667px;
      }

      .button-list {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .button-item {
          width: 100%;
          height: 52px;
          font-family: 'FZLanTingHei-M-GBK';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 52px;
          text-align: center;
          letter-spacing: 1.25px;
          box-sizing: border-box;

          &.unselected,
          &:not(:first-child).active {
            border: 1px solid #000000;
            color: #212121;
          }

          &.is-soldout {
            color: #c8c8c8;
            border: 1px solid #c8c8c8;
            pointer-events: none;
          }

          &:first-child.active {
            color: #fff;
            background: #212121;
          }

        }
      }

      .share-box {
        margin: 32px 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .ant-popover {
          z-index: 2; //swiper zindex:1
        }

        .ant-popover-content,
        .ant-popover-inner,
        .ant-popover-inner-content {
          width: 200px;
        }
      }

      .product-family-mobile {
        margin: 32px 0;
        margin-right: -16px;
      }
    }
  }

  .swiper-banner-4 {
    .loveWay-list {
      .item {
        font-size: 12px;

        &.active {
          font-weight: bold
        }
      }
    }
  }
}

.collapse-box-m {

  .ant-collapse-item {
    border-top: 1px solid rgba(224, 224, 224, 1);

    &:last-child {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .ant-collapse-header {
      padding: 16px 0;

      .ant-collapse-header-text {
        font-family: FZLanTingHeiS-R-GB;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 1.5px;
        color: #212121;
      }
    }

    .ant-collapse-content {

      .ant-collapse-content-box {
        padding: 4px 0 16px;
        font-family: Zona Pro, FZLanTingHeiS-R-GB;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #757575;
      }
    }
  }

  .panel-container {

    &.t30 {
      margin-top: 24px;
    }

    .item {
      position: relative;
      line-height: 24px;
      padding-left: 20px;

      &::after {
        content: '';
        width: 2px;
        height: 2px;
        background-color: #212121;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      .chat-btn {
        text-decoration: underline;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &::after {
        content: '';
        position: absolute;
        left: 8px;
        top: 8px;
        width: 2px;
        height: 2px;
        background: #212121;
      }
    }
  }
}

.actionSheet-box-m {
  z-index: 1200;

  .adm-action-sheet-button-item-disabled {
    pointer-events: all;
  }

  .adm-popup-body {
    border-radius: inherit;

    .adm-action-sheet {
      height: 226px;
      display: flex;
      flex-direction: column;

      .adm-action-sheet-extra {
        display: block;
        padding: 20px 0 0;
        border-bottom: none;

        .title {
          padding: 16px 20px;
          border-top: 1px solid rgba(0, 0, 0, 0.08);
          font-family: 'FZLanTingHeiS-R-GB';
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.46666666865348816px;
          color: #212121;
          font-weight: 600;
        }
      }

      .adm-action-sheet-button-list {
        flex: 1;
        // padding-right: 8px;
        overflow: auto;
        @include scrollbar-color(#fff, rgba(33, 33, 33, 0.1));

        .adm-action-sheet-button-item-wrapper {
          border-bottom: none;
        }

        .adm-action-sheet-button-item {
          display: flex;
          justify-content: space-between;
          padding: 9px 20px;

          .adm-action-sheet-button-item-name,
          .adm-action-sheet-button-item-description {
            font-family: 'Zona Pro';
            line-height: 16px;
            font-size: 12px;
            letter-spacing: 0.46666666865348816px;
            color: #212121;
          }
        }
      }
    }
  }
}

.popover-content-box-m {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    &:nth-of-type(1) {
      margin-right: 16px;
    }
  }
}

.drawer-cart {
  // margin-top: 102px;
  // box-shadow: 0px -2px 6px 0 #bbb;

  .ant-drawer-content-wrapper {
    // margin: 0 auto;
    // width: 94%;
    height: auto !important;

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.size-popup {
  padding: 16px 16px 20px;

  .title {
    margin-bottom: 24px;
    font-family: FZLanTingHei-M-GBK;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #000000
  }

  .adm-popup-close-icon {
    color: #333;
  }

  .size-guide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.88px;
    letter-spacing: 0.7px;
    color: #212121;

    .iconfont {
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .size-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 10px;

    .item {
      box-sizing: border-box;
      width: calc((100% - 50px) / 6);

      background: #ffffff;
      border: 1px solid #ebebeb;
      font-family: 'Zona Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 44px;

      text-align: center;
      letter-spacing: 0.466667px;

      color: #212121;

      &:hover {
        color: #5f5f5f;
      }

      &.active {
        border: 1px solid #212121 !important;
        font-weight: 700;
      }

      &.gray {
        background: #f6f6f6;
        border: 1px solid #c8c8c8;
        color: #797979;
      }
    }
  }

  .size-tip {
    margin-top: 8px;
    font-family: Zona Pro, 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.466667px;
    color: #797979;
  }

  .size-description {
    // flex-shrink: 0;
    margin-top: 24px;
    text-align: center;
    color: #797979;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.87px;
    letter-spacing: 0.46666666865348816px;
  }
}

.size-group-dialog {
  .title-box {
    .title {
      font-size: 18px !important;
    }

    .desc {
      font-size: 12Px !important;
    }
  }
}



.mob-invalid-tip {
  text-align: center;
  font-family: "FZLanTingHeiS-R-GB";
  font-size: 14px;

  .btn {
    width: 160px;
    height: 50px;
    background: #212121;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}