.order-item {
  margin-top: 24px;


  .product-img {
    width: 180px;
    height: 180px;
    margin-right: 16px;
  }

  .product-info {
    width: calc(100% - 194px);

    .columnSelect {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .select-color {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-bottom: 8px;
      }
    }
  }

  .tips {
    padding: 5px 6px;
    background-color: #e0e0e0;
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 12px;
    color: rgba($color: #212121, $alpha: 0.6);
    line-height: 24px;
    text-align: center;
    margin-right: 8px;
  }

  .fontZoo {
    font-family: 'Zona Pro';
  }

  .font214 {
    color: rgba($color: #212121, $alpha: 0.4);
  }

  .fontRGB {
    font-family: 'FZLanTingHeiS-R-GB';
  }
}