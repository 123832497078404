.media_block_mob {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 99%;
  .mediaBox {
    width: 100%;
    overflow: hidden;
    height: auto;
    cursor: pointer;
    display: block;
    margin: auto;
    .media {
      transition: all 0.5s ease-out;
      height: auto;
      overflow: hidden;
      width: 100%;
    }
  }
  .scale {
    &:hover {
      transform: scale(1.2);
    }
  }
}
