.mobile-arrival-dialog {

    .ant-modal-body {
        padding: 24px 0;
        box-sizing: border-box;
    }

    .infoForm {

        img,
        button {
            width: 100px;
            height: 40px;
            vertical-align: middle;
        }
    }

    .ant-modal-close-x {
        color: #212121;
    }

    .dialog-content {
        margin-top: 15px;
        padding: 0 20px;
        box-sizing: border-box;
        max-height: 70vh;
        overflow-y: scroll;
    }

}