.size-group-dialog {
  .ant-modal-body {
    padding: 60px 24px 65px;

    .title-box {
      text-align: center;

      .title {
        font-size: 26px;
        line-height: 26px;
        color: #212121;
        font-weight: 600;
      }

      .desc {
        margin-top: 20px;
        font-size: 13px;
        line-height: 16px;
        color: #616161;
      }
    }

    .td1,
    .td2 {
      min-height: 50px !important;
      //line-height: 50px !important;
    }
  }

  .anticon-close {
    color: #212121;
    svg {
      height: 15px;
    }
  }

  &.mobile-page {
    margin: 0;
    max-width: 100vw;

    .ant-modal-body {
      padding: 30px 0;

      .main {
        padding-bottom: 4px;
      }
    }
  }

  .size-group-page {
    width: 300px;
    min-width: 100%;
  }
}
