.pc-wishs-share-page {
    font-family: 'FZLanTingHeiS-R-GB';
    max-width: 1440px;
    padding-bottom: 60px;
    margin: auto;

    .greetings-content {
        padding: 24px;

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.466667px;
            color: #242424;
            margin-bottom: 22px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .wishlist-wrapper {
        position: relative;
        // padding: 8px 0px 32px;
        padding-top: 8px;
        border-top: .5px solid #ccc;

        .wishlist-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // gap: 24px 8px;


            .wishlist-item {
                margin-top: 24px;
                border-bottom: .5px solid #ccc;

                &:not(:nth-child(2n)) {
                    width: 100%;
                    height: auto;
                    padding: 0 8px 24px 0;

                }
            }
        }

        .divider {
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0;
            width: 100%;
            height: .5px;
            background-color: #ccc;

            margin: 24px 0 32px;
        }

        .btn-wrapper {
            margin-top: 32px;
            display: flex;
            justify-content: center;

            button.balck-bg-btn {
                width: 352px;
                height: 50px;
                font-family: 'FZLanTingHeiS-R-GB';
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                background: #242424;
                color: #FFFFFF;
            }
        }

        @media only screen and (min-width: 1440px) {
            .wishlist-content {
                grid-template-columns: repeat(3, 1fr);

                .wishlist-item {
                    margin-top: 24px;
                    border-bottom: .5px solid #ccc;

                    &:not(:nth-child(3n)) {
                        width: 100%;
                        height: auto;
                        padding: 0 8px 24px 0;

                    }
                }
            }
        }
    }
}

.pc-wish-share-page {
    max-width: 1360px;
    height: auto;
    margin: auto;

    .bottom-image-wrapper{
        margin-bottom: 50px;
    }

    .image-wrapper {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .wish-share-info {
        font-family: 'FZLanTingHei-M-GBK';
        width: 100%;
        display: flex;

        .share-info {
            width: 50%;
        }

        .info-left {
            position: relative;
            border-right: 2px solid rgba(33, 33, 33, .6);

            .left-title-first {
                position: absolute;
                left: 48px;
                top: 160px;
                // margin: 0 48px;
                font-weight: 400;
                font-size: 32px;
                line-height: 32px;
                color: #D0A898;
                transform-origin: 0 0;
                transform: rotate(-90deg)
            }

            .left-good-info {
                margin: 0 74px 0 128px;

                .good-image-wrapper {
                    cursor: pointer;
                    display: block;
                    // width: 475px;

                    img {
                        aspect-ratio: 1;
                        height: 475px;
                    }
                }

                .good-base-info {
                    text-align: left;
                    margin: 16px 0 24px 0;

                    .base-info-title {
                        display: block;
                        cursor: pointer;
                        font-family: 'Zona Pro';
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 36px;
                        color: #212121;
                    }

                    .base-info-desc {
                        margin-top: 8px;
                        word-break: break-word;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: #424242;
                    }

                    .base-info-attr {
                        font-family: FZLanTingHei-M-GBK;
                        margin-top: 8px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #212121;

                        .attr-label {
                            margin-right: 4px;
                        }
                    }
                }

                .left-title-second {
                    font-family: FZLanTingHei-M-GBK;
                    display: block;
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                    color: #D0A898;
                    margin-bottom: 16px;
                }
            }
        }

        .info-right {
            font-family: FZLanTingHei-M-GBK;

            .sharing-words-content {
                margin: 196px auto 56px;

                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 48px;
                    text-align: center;
                    color: #000000;
                }

                .first-line {
                    .receiver-name {
                        color: #D0A898;
                        text-decoration: underline;
                    }
                }

                .third-line {
                    color: #D0A898;
                }
            }

            .brand-title {
                text-align: center;
                font-size: 32px;
                font-weight: 600;
                color: #000000;
                text-transform: uppercase;
            }

            .logo-wrapper {
                margin: 0 auto;
                width: 234px;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}