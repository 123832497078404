@import '@/style/mixin';
.AppointSuccess {
  height: 626px;
  padding-bottom: 0;

  .w367 {
    height: 626px;
    width: 367px;
    background: #f5f5f5;
    overflow-y: scroll;
    @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));
    .h341 {
      height: 341px;
    }
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .w301 {
    width: 301px;
    height: 56px;
  }

  .blackback {
    background-color: #212121;
    color: #fff;
  }

  .ant-modal-content {
    height: 100%;
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;
  }

  .fx {
    width: 100%;
    height: 100%;
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 36px 24px 24px;

    .container {
      flex: 1;
      overflow: auto;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        width: 0.06944rem;
        height: 0.06944rem;
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-transition: all .2s;
        transition: all .2s;
        border-radius: 0.06944rem;
      }
    }
  }
}