.searchBlocks_mob {
  overflow-x: scroll;
  overflow-y: scroll;
  padding-bottom: 10px;

  .good_item {
    margin-right: 2px;
    width: 98px;

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }

    .hot_img {
      width: 98px;
      height: 98px;
      margin-bottom: 12px;
    }

    p {
      width: 98px;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}