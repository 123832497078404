.jcform {
  .flexItem {
    .ant-form-item {
      display: inline-block;
      width: 210px;
      margin-bottom: 0;
      vertical-align: middle;
    }
    img,
    button {
      width: 100px;
      height: 40px;
      vertical-align: middle;
    }
  }
  .dob .ant-form-item-control-input-content {
    display: flex;
    .input-select {
      width: 106px;
      cursor: pointer;
    }
  }
  .input-select:last-child {
    margin-right: 0;
  }
  .sbumitBtn {
    font-family: 'FZLanTingHei-M-GBK';
    letter-spacing: 0.47px;
  }
  .ant-checkbox-wrapper {
    font-size: 13px;
    color: rgba(33, 33, 33, 0.6);
    letter-spacing: 0.47px;
  }
  .c212 {
    color: rgba(33, 33, 33, 0.6);
  }
}
