.FourTabMOB {
  width: 100%;
  font-family: 'FZLanTingHeiS-R-GB';

  .tabBox {
    width: 100%;
    height: 121px;
    background-color: #fafafa;

    &:last-child {
      margin-bottom: 0;
    }

    .mt16 {
      margin-top: 12px;
    }

    .mt8 {
      margin-top: 4px;
    }

    .detaile {
      width: 80px;
      height: 40px;
      background-color: #212121;
      line-height: 40px;
      margin-top: 11px;
    }
  }
}