.pictureBlock_mob {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  display: flex;
  height: 80px;

  .picBox_mob {
    width: 80px;
    height: 80px;

    img {
      display: block;
    }

    .pictures_mob {
      width: 100%;
    }
  }

  .leftBox_mob {
    background-color: #fff;
    padding-left: 16px;
    flex: 1;
    display: flex;
    align-items: center;
  }
}