@import 'ShareCard.scss';
@import 'ShareList.scss';

.share-card-drawer,
.share-card-drawer-second,
.share-list-drawer,
.share-list-drawer-second {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    padding: 0;
                    border: none;

                    .ant-drawer-header-title {
                        height: 31px;
                        justify-content: flex-end;

                        button.ant-drawer-close {

                            margin-right: 4px;
                        }
                    }
                }

                // .ant-drawer-body {
                //     overflow-y: scroll;
                // }
            }
        }
    }
}