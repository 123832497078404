.MyInformationMob {
  width: 100%;
  margin-top: 24px;
  .topRemind {
    font-family: 'FZLanTingHeiS-R-GB';
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .seeInform {
      border-bottom: 1px solid #212121;
      cursor: pointer;
    }
    .leftRequest {
      opacity: 0.6;
      i {
        color: #c1281b;
        margin-right: 8px;
      }
    }
  }
  .dob .ant-form-item-control-input-content {
    display: flex;

    .input-select {
      width: 131px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .w335 {
    width: 335px;
    .iconfont {
      color: #212121;
    }
    .ant-input[disabled] {
      -webkit-text-fill-color: #212121;
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
    // 无内容时，placeholder的颜色
    .ant-input[disabled].placeholder {
      opacity: 0.5;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: #212121;
    }
    .ant-form-item-label {
      label {
        color: rgba(33, 33, 33, 0.6);
        font-family: 'FZLanTingHeiS-R-GB';
        font-size: 13px;
      }
    }
    .ant-select-selector {
      border: none;
      background-color: #f3f3f3;
    }
    .ant-input {
      border: none;
      background-color: #f3f3f3;
    }
    .ant-select-selection-placeholder {
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
      color: rgba(33, 33, 33, 0.6);
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      background-color: #f3f3f3;
      color: rgba(33, 33, 33, 0.6);
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #f3f3f3;
      color: rgba(33, 33, 33, 0.6);
    }
    .ant-input {
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
      color: #212121;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #f3f3f3;
    }
  }
  .op6 {
    .ant-input[disabled] {
      opacity: 0.6;
    }
    .ant-select-disabled .ant-select-selection-item {
      opacity: 0.6;
    }
  }
  .leftl {
    width: fit-content;
    text-align: left;
    line-height: 1;
    .ant-form-item-control-input {
      min-height: auto;
    }
    .ant-checkbox-inner {
      border: none;
      background-color: #eeeeee;
    }
    .ant-checkbox-wrapper {
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
      line-height: 18px;
      color: rgba(33, 33, 33, 0.6);
      a {
        color: #212121;
        opacity: 1;
        border-bottom: 1px solid #212121;
      }
    }
  }
  .leftl.is_agree {
    .ant-checkbox-wrapper {
      align-items: flex-start;
    }
  }
  .sbumitBtn {
    width: 100%;
  }
  .show {
    position: relative;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: openForm;
  }
  @keyframes openForm {
    0% {
      top: -110px;
      opacity: 0;
    }
    60% {
      top: -50px;
      opacity: 0.3;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
}
