.jcm-swiper-roat-new {

	.swiper-cntent {
		display: flex !important;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.swiper-top {
			position: relative;

			img {
				min-height: 50px;
				width: 100%;
				height: 100%;
				display: block;
			}

			.swiper-dots {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				flex-direction: row;

				.dots {
					cursor: pointer;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: #ACACAC;
				}

				.slick-active {
					background: #000;
				}
			}
		}

		.swiper-bottom {
			padding: 0 24px;
			// left: 0;
			display: flex;
			// align-items: center;
			justify-content: center;
			flex-direction: column;
			z-index: 999;

			.layer_title {
				font-size: 20PX;
				margin-top: 15px;
				word-wrap: break-word;
				// text-align: center;
				font-weight: 600;
			}

			.layer_subtitle {
				font-size: 13PX;
				margin-top: 15px;
				word-wrap: break-word;
				// text-align: center;
			}

			.layer_describe {
				font-size: 13PX;
				margin-top: 15px;
				word-wrap: break-word;
				// text-align: center;
			}

			.layer_link_title {
				// margin-top: 15px;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 42px;
				letter-spacing: 0.466667px;
				background-color: #FFFFFF;
				cursor: pointer;
				text-align: center;
				display: inline-block;
				position: relative;
				text-decoration: none;
				text-transform: uppercase;
				padding: 0;
				color: #212121 !important;
				font-weight: 600;
			}

			.w2b {
				margin: 15px 0 1px 0;
			}

			.mp2b {
				margin: 15px 0 1px 0;
			}

			.p2b {
				margin: 15px 0 1px 0;
			}

			.b2b {
				margin: 15px 0 1px 0;
			}

			.b2u {
				width: fit-content;
			}

			.layer_link_title::after {
				content: '';
				background: #000;
				width: 100%;
				height: 1.5px;
				position: absolute;
				bottom: 2px;
				left: 50%;
				transform: translateX(-50%);
				transition: .1s;
				pointer-events: none;
			}
		}

		.left {
			align-items: flex-start;
			text-align: left;
		}

		.right {
			align-items: flex-end;
			text-align: right;
		}

		.middle {
			align-items: center;
			text-align: center;
		}

		.fm {
			align-items: center;
			text-align: center;
		}

		.locate-left {
			left: 0;
		}

		.locate-right {
			right: 0;
		}
	}
}