.mob-pedding {
  font-family: 'FZLanTingHeiS-R-GB';
  padding: 0 40px;

  .pedding-title {
    margin: 20px 0;
    text-align: center;
  }

  .pedding-container {
    .leftImg {
      width: 295px;
    }

    .right {
      width: 100%;
      text-align: center;
      margin-top: 24px;

      img {
        width: 40px;
        height: 40px;
        margin: auto;
      }

      .orderId {
        margin: 24px auto 20px;
      }

      ul {
        color: #5f5f5f;
        font-size: 14px;

        li {
          margin-bottom: 20px;
        }
      }

      a {
        cursor: pointer;
      }

      button {
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
      }
    }
  }
}

.againPayDrawerMob {
  .ant-drawer-title {
    color: #212121;
    margin: 20px auto 32px;
    font-size: 16px;
    text-align: center;
  }

  .ant-drawer-content-wrapper {
    max-height: calc(100vh - 100px);
    min-height: 370px;
    height: fit-content !important;
  }

  .ant-drawer-body {
    padding-top: 0;
    text-align: center;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;

  }

  .ant-drawer-header {
    padding-bottom: 0;
    border-bottom: none;
  }

  .ant-drawer-content {
    height: auto;
  }

  .btn {
    width: 160px;
    height: 50px;
    background: #212121;
  }

  ul {
    text-align: left;
  }

}