.w100 {
  width: 100%;
}

.wfc {
  width: fit-content;
  height: fit-content;
}

.w50 {
  width: 50%;
}

.w1440 {
  // min-height: 600px;
  max-width: 1440px;
  padding: 20px 20px;
  box-sizing: content-box;
}

.w310 {
  width: 310px;
}

.h100 {
  height: 100%;
}

.vh100 {
  height: 100vh;
}

.h32 {
  height: 32px;
  line-height: 32px;
}

.h40 {
  height: 40px;
  line-height: 40px;
}

.h60 {
  height: 60px;
  line-height: 60px;
}

.hauto {
  height: auto;
}

.w100hauto {
  width: 100%;
  height: auto;
}

// margin
.ma {
  margin: auto;
}

.mb0 {
  margin-bottom: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mr5 {
  margin-right: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml7 {
  margin-left: 7px;
}

.mb7 {
  margin-bottom: 7px;
}

.mt7 {
  margin-top: 7px;
}

.mr7 {
  margin-right: 7px;
}

.ml8 {
  margin-left: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.mr8 {
  margin-right: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mr12 {
  margin-right: 12px;
}

.ml12 {
  margin-left: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mt16 {
  margin-top: 16px;
}

.mr15 {
  margin-right: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mr25 {
  margin-right: 24px;
}

.ml24 {
  margin-left: 24px;
}

.mr24 {
  margin-right: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt25 {
  margin-top: 25px !important;
}

.mr25 {
  margin-right: 25px;
}

.ml25 {
  margin-left: 25px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mr30 {
  margin-right: 30px;
}

.ml30 {
  margin-left: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt32 {
  margin-top: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.mt35 {
  margin-top: 35px;
}

.mb32 {
  margin-bottom: 32px;
}

.mr35 {
  margin-right: 35px;
}

.ml35 {
  margin-left: 35px;
}

.mb35 {
  margin-bottom: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mr40 {
  margin-right: 40px;
}

.ml40 {
  margin-left: 40px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb44 {
  margin-bottom: 44px;
}

.mt50 {
  margin-top: 50px;
}

.mr50 {
  margin-right: 50px;
}

.ml50 {
  margin-left: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mb60 {
  margin-bottom: 60px;
}

.ml100 {
  margin-left: 100px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mt100 {
  margin-top: 100px;
}

.mb100 {
  margin-bottom: 100px;
}

// padding
.pd5 {
  padding: 5px;
}

.pdt5 {
  padding-top: 5px;
}

.pdl5 {
  padding-left: 5px;
}

.pdr5 {
  padding-right: 5px;
}

.pdb5 {
  padding-bottom: 5px;
}

.pd10 {
  padding: 10px;
}

.pdt10 {
  padding-top: 10px;
}

.pdl10 {
  padding-left: 10px;
}

.pdr10 {
  padding-right: 10px;
}

.pdb10 {
  padding-bottom: 10px;
}

.pdb18 {
  padding-bottom: 18px;
}

.pd15 {
  padding: 15px;
}

.pdt15 {
  padding-top: 15px;
}

.pdl15 {
  padding-left: 15px;
}

.pdr15 {
  padding-right: 15px;
}

.pdb15 {
  padding-bottom: 15px;
}

.pdt16 {
  padding-top: 16px;
}

.pdl16 {
  padding-left: 16px;
}

.pdr16 {
  padding-right: 16px;
}

.pdb16 {
  padding-bottom: 16px;
}

.pd20 {
  padding: 20px;
}

.pdt20 {
  padding-top: 20px;
}

.pdl20 {
  padding-left: 20px;
}

.pdr20 {
  padding-right: 20px;
}

.pdb20 {
  padding-bottom: 20px;
}

.pd24 {
  padding: 24px;
}

.pdt24 {
  padding-top: 24px;
}

.pdl24 {
  padding-left: 24px;
}

.pdr24 {
  padding-right: 24px;
}

.pdb24 {
  padding-bottom: 24px;
}

.pd25 {
  padding: 25px;
}

.pdt25 {
  padding-top: 25px;
}

.pdl25 {
  padding-left: 25px;
}

.pdr25 {
  padding-right: 25px;
}

.pdb25 {
  padding-bottom: 25px;
}

.pd30 {
  padding: 30px;
}

.pdt30 {
  padding-top: 30px;
}

.pdl30 {
  padding-left: 30px;
}

.pdr30 {
  padding-right: 30px;
}

.pdb30 {
  padding-bottom: 30px;
}

.pdt32 {
  padding-top: 32px;
}

.pdb32 {
  padding-bottom: 32px;
}

.pd35 {
  padding: 35px;
}

.pdt35 {
  padding-top: 35px;
}

.pdl35 {
  padding-left: 35px;
}

.pdr35 {
  padding-right: 35px;
}

.pdb35 {
  padding-bottom: 35px;
}

.pd40 {
  padding: 40px;
}

.pdt40 {
  padding-top: 40px;
}

.pdl40 {
  padding-left: 40px;
}

.pdr40 {
  padding-right: 40px;
}

.pdb40 {
  padding-bottom: 40px;
}

.pd50 {
  padding: 50px;
}

.pdt50 {
  padding-top: 50px;
}

.pdl50 {
  padding-left: 50px;
}

.pdl100 {
  padding-left: 100px;
}

.pdr50 {
  padding-right: 50px;
}

.pdb50 {
  padding-bottom: 50px;
}

// 行高
.lh15 {
  line-height: 15px;
}

.lh16 {
  line-height: 16px;
}

.lh17 {
  line-height: 17px;
}

.lh24 {
  line-height: 24px;
}

.lh26 {
  line-height: 26px;
}

.lh32 {
  line-height: 32px;
}

// 字号
.f10 {
  font-size: 10px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f17 {
  font-size: 17px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f21 {
  font-size: 21px;
}

.f22 {
  font-size: 22px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.f34 {
  font-size: 34px;
}

.f40 {
  font-size: 40px;
}

// 颜色
.colorfff {
  color: #fff;
}

.colorBlack {
  color: #000;
}

.color000 {
  color: #212121;
}

.color333 {
  color: #333;
}

.color666 {
  color: #666;
}

.color999 {
  color: #999;
}

.color616 {
  color: #616161;
}

.color363 {
  color: #363636;
}

.color757 {
  color: #757575;
}

.color707 {
  color: #707070;
}

.color1B1,
.color1b1 {
  color: #1b1b1b;
}

.colorA5A,
.colora5a {
  color: #a5a5a5;
}

.colorB7B,
.colorb7b {
  color: #b7b8b7;
}

.colorC3C,
.colorc3c {
  color: #c3c3c3;
}

.color212 {
  color: #212529;
}

.colorAD0,
.colorad0 {
  color: #ad0000;
}

.color2121 {
  color: rgba(33, 33, 33, 0.8);
}

.color216 {
  color: rgba(33, 33, 33, 0.6);
}

.color2424 {
  color: #242424;
}

.color4242 {
  color: #424242;
}

.colorred {
  color: #c1281b;
}

.color212121 {
  color: #212121;
}

.color21218 {
  color: rgba(#212121, 0.8);
}

// 字体加粗
.fbold {
  font-weight: bold;
}

// 字体不加粗
.fbold-no {
  font-weight: 500;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fwBold {
  font-weight: bold;
}

// 下划线
.txt-under {
  text-decoration: underline;
}

// 中划线
.txt-thr {
  text-decoration: line-through;
}

// 可点击
.txt-pointer {
  cursor: pointer;
}

// 字居中
.txt-center {
  text-align: center;

  &:hover {
    text-align: center;
  }
}

// 字居左
.txt-left {
  text-align: left;

  &:hover {
    text-align: left;
  }
}

// 字居右
.txt-right {
  text-align: right;

  &:hover {
    text-align: right;
  }
}

// 行高=1
.lineH1 {
  line-height: 1;
}

//块元素
.block {
  display: block;
}

//行内元素
.inline {
  display: inline;
}

.lineblock {
  display: inline-block;
}

//行内块元素

// 超出一行显示...
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

/* 超出两行显示...需要写宽度 */
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 超出三行显示...需要写宽度 */
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.disabled-btn {
  pointer-events: none;
  opacity: .5;
}

//字体
.fontZoo {
  font-family: 'Zona Pro', sans-serif;
}

.fontZooB {
  font-family: 'Zona Pro Bold', sans-serif;
}

.fontZooS {
  font-family: 'Zona Pro-SemiBold', sans-serif;
}

.FZLanTing-Regular {
  font-family: 'FZLanTing-Regular', sans-serif;
}


// .fontRGB {
//   font-family: 'FZLanTingHeiS-R-GB', 'ZonaPro', sans-serif;
// }

.fontGBK {
  // font-family: 'FZLanTingHei-M-GBK', 'ZonaPro', sans-serif;
  font-weight: 600;
}