.mobile-header-box {
  position: relative;
}

.transparent-div {
  // position: fixed;
  // top: 0;
}

.mobile-header {
  width: 100%;
  z-index: 999;
  // position: fixed;
  // left: 0;
  // top: 0;

  .top_black_block {
    width: 100%;
    height: 26px;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .block_sale {
      padding: 0 34px;
      width: 100%;
      text-align: center;
      color: #fff;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;

      // fixbug: 2023-1-3 11:57
      a:hover {
        color: #fff;
      }

      h2 {
        font-size: 12px;
      }
    }

    .closeIcon {
      padding: 0 8px;
      cursor: pointer;
    }
  }


  .hide-shopping {
    // display: none;
    position: absolute !important;
    top: -30px;
  }

  .mob-navigation-header {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.5s ease;
    background-color: transparent;
    color: #fff;
    padding: 22px 12px;
    transition: background-color 0.3s ease;

    &.nav-no-style {
      background-color: transparent;
      color: #fff;
      // transition: background-color 0.5s ease;
    }


    &.scroll-default {

      .navigation-title img {
        // width: 40.02vw;
        height: 26px;
        max-height: 36PX;
        width: auto;
        display: block;
        transition: height 0.3s ease;
      }
    }

    &.scroll-down {
      align-items: center;
      background-color: #fff;
      color: #212121;
      transition: background-color 0.5s ease;

      .navigation-title img,
      .logo-svg {
        // width: 40.02vw;
        height: 26px;
        max-height: 36PX;
        width: auto;
        transition: height 0.1s ease;
      }
    }

    &.nav-transparent-small {
      align-items: center;
      // padding: 16px 0;
      background-color: transparent;
      color: #212121;

      .navigation-title img,
      .logo-svg {
        height: 26px;
        max-height: 36PX;
        width: auto;
        transition: height 0.3s ease;
      }
    }

    &.scroll-up {

      .left-nav,
      .right-nav {
        align-self: flex-start;
      }
    }

    &.mob-navigation-hide {
      display: none;
    }

    .navigation-title {
      flex: 1;
      display: flex;
      justify-content: center;
      /* 水平居中 */
      align-items: center;

      img {
        // width: 40.02vw;
        height: 26px;
        max-height: 36PX;
        width: auto;
        transition: font-size 0.3s ease;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }

      .logo-svg {
        height: 26px;
        max-height: 36PX;
      }
    }

    &.nav-white {
      background-color: #fff;
      color: #212121 !important;
      transition: background-color 0.5s ease;
    }



    .left-nav,
    .right-nav {
      display: flex;
      flex: 0 1 20%;

      ul {
        list-style: none;
        display: flex;
        gap: 8px;

        li {
          height: 24px;
          line-height: 1.1;

          .iconfont {
            font-size: 24px;
          }

          a {
            color: inherit;
            text-decoration: none;
            transition: color 0.3s ease;
            font-size: 14px;

            // &:hover {
            //   color: #888;
            // }
          }

          &:last-of-type {
            position: relative;

            span {
              position: absolute;
              left: 18px;
              top: 6px;
              font-size: 10px;
              // font-family: 'FZLanTingHeiS-R-GB';
              // font-weight: 600;
            }
          }
        }
      }
    }

    .right-nav {
      justify-content: end;
    }
  }

  .hide {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: close;
  }

  .closeTab {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-name: closeAll;
  }

  .open_banner {
    width: 100%;
    // position: absolute;
    transition: top 0.5s ease;
  }

  @keyframes close {
    0% {
      height: 30px;
      opacity: 1;
    }

    100% {
      opacity: 0;
      height: 0;
    }
  }

  @keyframes closeAll {
    0% {
      top: 0;
      opacity: 1;
      height: 110px;
    }

    100% {
      opacity: 0;
      top: -50px;
      height: 0;
    }
  }

  @keyframes openAll {
    0% {
      top: -50px;
      opacity: 0;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes openBtn {
    0% {
      opacity: 0;
      // right: calc( 100% - 60px );
      width: 0;
    }

    95% {
      opacity: 0;
      // right: calc( 100% - 60px );
      width: 0;
    }

    100% {
      opacity: 1;
      right: 0;
      width: calc(100% - 314px);
    }
  }

  @keyframes closeBtn {
    0% {
      width: calc(100% - 314px);
      top: 0px;
    }

    100% {
      top: 0px;
      width: 0;
    }
  }

  .tab_pc {
    padding: 0 16px;
    width: 100%;
    height: 64px;
    // background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;


    .ab16 {
      display: flex;
      position: absolute;
      right: 10px;
    }

    .bags {
      position: relative;

      .num {
        position: absolute;
        top: 17px;
        right: -3px;
        font-weight: 600;
        font-size: 12px;
        transform: scale(0.8);
      }

      .num.right9 {
        right: -9px;
      }
    }

    .search {
      margin-right: 8px;
    }
  }

  .draw {
    width: 314px !important;

    .ant-drawer-inline {
      position: fixed !important;
    }

    .ant-drawer-mask {
      z-index: 50;
    }

    .ant-drawer-content-wrapper {
      width: 314px !important;
      margin: 0;
      z-index: 100;
    }

    .ant-drawer-body {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    .ant-drawer-header {
      padding: 0 24px;
      height: 64px !important;
      border-bottom: 0;
    }

    .ant-drawer-header-title {
      height: 64px !important;
      border-bottom: 1px solid #bdbdbd;
    }

    .drawerLogo-box {
      padding: 0 24px;
      background: #f5f5f5;

      >a {
        display: block;
        height: 64px;
        line-height: 64px;
        border-bottom: 1px solid #bdbdbd;

        >img {
          display: inline;
          width: 115.61px;
        }
      }
    }

    .open_btn {
      z-index: 90;
      position: absolute;
      left: 314px;
      top: 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
      animation-fill-mode: both;
      animation-name: openBtn;
    }

    .close_btn {
      position: absolute;
      left: 314px;
      top: 0px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      animation-duration: 0.2s;
      animation-timing-function: ease-out;
      animation-fill-mode: both;
      animation-name: closeBtn;
    }

    .noBtn {
      display: none;
      overflow: hidden;
      background-color: #212121;

      .icon {
        width: 10px;
        height: 10px;
      }

      width: calc(100% - 314px);
      height: 64px;
    }

    .top_list {
      padding: 24px !important;
      background-color: #f5f5f5;
    }

    .bottom_tab {
      padding: 24px;

      .one_bottom_tab {
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 32px;
        border-bottom: 1px solid #bdbdbd;

        .icon {
          width: 10px;
          height: 13px;
          margin-right: 8px;
        }

        .country {
          font-family: 'FZLanTingHeiS-R-GB';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #757575;
        }

        .anticon-right {
          position: absolute;
          right: 0;
          font-weight: 400;
        }
      }

      .three_center_tab {
        margin-top: 32px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-size: 14px;
        color: #212121;
        align-items: center;

        .icon1 {
          width: fit-content;
          height: fit-content;
          margin-right: 8px;
        }
      }

      .lastOne {
        padding-bottom: 32px;
        border-bottom: 1px solid #bdbdbd;

        .has-wish {
          width: 13px;
          height: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;

          .iconfont {
            font-size: 19px;
          }
        }
      }

      .last_tab {
        position: relative;
        margin-top: 32px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #212121;
        padding-bottom: 32px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #bdbdbd;
        margin-bottom: 88px;

        .anticon-right {
          position: absolute;
          right: 0;
          font-weight: 400;
        }
      }
    }

    .center_pictureBox {
      padding: 26px;
      padding-top: 0;
      background-color: #f5f5f5;
    }

    .tab_item {
      width: 100%;
      text-align: left;
      position: relative;
      // font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-size: 14px;
      color: #212121;
      margin-bottom: 37px;

      a {
        display: inline-block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        width: 100%;

        .anticon-right {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 400;
        }
      }
    }
  }

  .mobBagTab {
    padding: 26px 20px;
    display: flex;
    align-items: center;
    font-family: 'FZLanTingHeiS-R-GB';
    border-bottom: 1px solid #ebebeb;
    background: #fff;

    .logoName {
      flex: 1;

      .logo {
        margin: auto;
      }
    }
  }

  .tip_swiper {
    height: 26px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-right: 8px;
    margin-left: 8px;

    .tip_transform {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

  }

  .scroll-item {
    text-align: center;
    color: #fff;
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    box-sizing: border-box;
    white-space: nowrap;
    width: fit-content;
    min-width: 100%;
    white-space: nowrap;
    padding: 0 10px;

    a {
      color: #fff;

      :link {
        color: #fff;
        text-decoration: none;
      }

      :visited {
        color: #fff;
        text-decoration: none;
      }

      :hover {
        color: #fff;
        text-decoration: none;
      }

      :active {
        color: #fff;
      }
    }

    a::after {
      color: #fff;
    }
  }

  .left_inform {
    position: relative;
    background-color: #212121;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  .top_black_block {
    position: relative;
    transition: top 0.3s ease;

    .closeIcon {
      position: absolute;
      right: 0;
    }
  }
}