.pc-full-screen-swiper-plp {
  position: relative;
  width: 100%;
  padding-bottom: 35px;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .menu-left {
    left: 45px;
  }

  .menu-right {
    right: 45px;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 31px;
    height: 93px;
  }

  .ant-carousel {
    width: 100%;
    // min-height: 463px;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .slick-slide {
      padding: 0 1px;
    }

    .media_content .media {
      min-height: 306px;
    }
  }

  .slick-dots {
    bottom: 0px;
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(0, 0, 0, 0.1);

    & li {
      margin: 0;
    }

    &.dots-style0 li {
      width: 100%;
    }

    &.dots-style1 li {
      width: 100%;
    }

    &.dots-style2 li {
      width: 50%;
    }

    &.dots-style3 li {
      width: 33.33%;
    }

    &.dots-style4 li {
      width: 25%;
    }

    &.dots-style5 li {
      width: 20%;
    }

    &.dots-style6 li {
      width: 16.66%;
    }

    &.dots-style7 li {
      width: 14.29%;
    }

    button {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .slick-active {
      button {
        background-color: #212121 !important;
      }
    }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;
    display: block;
    // min-height: 464px;

    &::before {
      width: 100%;
      height: 100%;
      content: '';
      z-index: 9;
      position: absolute;
      left: 0;
      top: 0;
    }

    .slick-slide {
      // padding: 0 1px;
    }

    .sales_short_tips {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      padding: 5px 8px;
      background: #fafafa;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #212121;
    }

    .cart-love {
      z-index: 11;
      position: absolute;
      top: 10px;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding: 16px;

      width: 120px;
      height: 32px;
    }
  }
  .hover_video_img{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    padding:0 1px;
    // transition: opacity 0.5s;
  }
  .hover_show{
    z-index: 1;
    opacity: 1;
  }
  .swiper-desc {
    padding-top: 16px;

    .info {
      text-align: left;

      .title {
        // font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14PX;
        line-height: 18px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .price-box {
        margin-top: 8px;

        .price {
          height: 16px;

          // font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          letter-spacing: 0.466667px;

          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }

    .dot-box {
      margin-top: 16px;

      .dot {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid transparent;

        &.item:nth-of-type(1) {
          border: 1px solid rgba(170, 170, 170, 1);
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}