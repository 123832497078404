.tabs-page {
  .tabs-container {
    height: 14px;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.466667px;

    color: #212121;

    .name {
      margin: 0 4px;
    }

    .align-c:nth-last-of-type(1) {
      .name {
        color: #616161;
      }
    }
  }
}