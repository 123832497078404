.mob-forget {
  margin: auto;
  padding: 0 20px;
  margin-top: 24px;
  .forgetForm {
    margin: auto;
  }
  .flexItem {
    .ant-form-item {
      display: inline-block;
      width: 210px;
      margin-bottom: 0;
      vertical-align: middle;
    }
    img,
    button {
      width: 100px;
      height: 40px;
      vertical-align: middle;
    }
  }
}
