.checkout-address_mob {
  width: 100%;
  .first-radio{
    padding-right: 42px;
    display: inline-block;
  }
  .reset-radio{
    .ant-radio-wrapper{
      margin-right: 16px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input376 {
    width: 100%;
  }

  .default-addr {
    padding: 20px 16px;
    .ant-form {
      margin: 16px 0 0 0 !important;
      background-color: #fff;
      .dob .ant-select-selector{
        background-color: #f3f3f3!important;
      }
    }
  }
  .default-tag {
    background-color: #212121;
    color: #fff;
    padding: 2px 6px;
    margin-left: 15px;
  }

  .addrlist {
    padding: 20px 16px;
    background-color: #F3F3F3;
    margin-bottom: 16px;
    .w32 {
      width: calc(100% - 30px);
    }
    
    .address{
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .edit-address{
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    
  .edit-form-wrap{
    background-color: #fff;
    width: 100%;
    padding: 16px;
    margin-top: 16px;
    .ant-form{
      padding: 0;
      margin: 0;
      .dob{ // 修改省市区背景色
        .ant-form-item .ant-select-selector{
          background-color: #F3F3F3;
        }
      }
    }
  }

    .ant-form {
      // background: #fff;
      margin: 20px 0 0 -30px;
      padding: 16px 15px;
      // background-color: #f3f3f3;
    }
    .addressformMob .input335 {
      width: 100%;
    }
    .addressformMob .ant-input {
      // background-color: #fff;
    }
    .addressformMob
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #fff;
    }
    .sbumitBtn {
      width: 100%;
    }
    .sbumitBtn.mb20{
      margin-bottom: 0;
    }
    .cancel_btn {
      margin-top: 15px;
    }
    .btns {
      margin-bottom: 0;
    }
  }


  .select-other {
    color: rgba(33, 33, 33, 0.6);
    text-align: center;
  }

  .w120 {
    width: calc((100% - 16px) / 3);
  }
  .outBox {
    height: 120px;
    overflow: scroll;
    position: relative;
    .h20 {
      height: 40px;
      line-height: 40px;
    }
    .block {
      width: 100px;
      height: 40px;
      position: fixed;
      top: 65%;
      background-color: pink;
    }
  }
  .ant-btn.cancel_btn{
    margin-right: 0;
    border-color: #212121;
  } 
}
