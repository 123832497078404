.pc-footer {
  width: 100%;
  background: #fff;
  font-family: 'FZLanTingHeiS-R-GB';

  .footer-email-subscript {
    background: #FAFAFA;
    padding: 64px 0;
    text-align: center;
    font-size: 13px;

    h4 {
      font-size: 28px;
      line-height: 32px;
    }

    .eamil {
      width: 354px;
      margin: auto;
    }

    .step2 {
      width: 872px;
      margin: 64px auto 0;

      .inputWidth {
        width: 280px;
      }
    }
  }

  .pc-footer-pub {
    background: #fafafa;
    padding: 64px 0;

    .pub-content {
      margin: auto;
      text-align: center;
    }

    h4 {
      font-size: 28px;
      font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro-SemiBold';
      font-weight: 400;
      color: #212121;
      line-height: 33px;
    }
  }

  .pc-footer-content {
    color: #fff;
    background: #212121;
    width: 100%;
    margin: 0 auto;
    padding: 55px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;

    .left {
      width: 200px;
      height: auto;

      img {
        width: 44px;
        height: 56px;
      }
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      user-select: none;
    }

    .footer-content-clo {
      font-family: 'FZLanTingHeiS-R-GB';
      width: 240px;

      .inline {
        display: flex;
        margin-bottom: 8px;
        align-items: center;

        .img {
          width: 22px;
          height: 22px;
        }

        img {
          margin-left: -5px;
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }
      }

      .f12 {
        li {
          font-size: 12px;
          line-height: 24px;
        }
      }

      h3 {
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: bold;
      }

      ul {
        li {
          font-size: 14px;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .pc-footer-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: 110px;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    background: #212121;

    .left_img {
      display: flex;
      user-select: none;

      .a-tag {
        &:hover {
          color: white;
        }
      }

      i {
        cursor: pointer;
        margin-right: 32px;
        font-size: 24px;
        position: relative;

        .erweima {
          position: absolute;
          display: none;
        }

        &:hover .erweima {
          position: absolute;
          display: block;
          width: 210px;
          bottom: 30px;
          left: 15px;
          max-width: none; // cms 页面中img设置了max-width：100%，先做此兼容
        }
      }
    }

    .language {
      width: 120px;
      height: 50px;
      border: 1px solid #fff;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.466667px;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .pc-footer-bottom {
    color: #e0e0e0;
    background: #212121;
    padding: 16px 24px;
    height: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ul {
      display: flex;
      flex-direction: row;

      li {
        margin-right: 80px;

        a {
          display: flex;
          color: inherit;

          img {
            margin-right: 8px;
          }
        }
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal {
  .ant-modal {
    // width: 1025px !important;
    width: 100% !important;
  }

  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .modal_box {
    display: flex;
    justify-content: center;

    .main {
      display: flex;
    }

    .ad-img {
      width: 594px;
      height: 594px;
    }

    .informTab {
      position: relative;
      width: 431px;
      height: 594px;

      padding: 0 24px;
      background-color: #fafafa;

      .selectCountryPC {
        height: 280px;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          padding: 0 10px;
        }

        .searchWordPC {
          margin-top: 30px;
          width: 100%;
          border: 0 none;
          background: transparent;
          border-bottom: 1px solid #212121;

          .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            color: #616161 !important;
            background-color: #fff;
          }

          .searchIcon {
            width: 14px;
            height: 14px;
          }

          .ant-select-selection-item {
            font-size: 13px;
            color: #212121;
          }

          .dropDrawerPC {
            min-width: 100% !important;
            width: 100% !important;
            height: 191px;
            box-shadow: none;
            left: 0 !important;
            top: 46px !important;

            .rc-virtual-list-scrollbar-thumb {
              background-color: #212121 !important;
            }

            .rc-virtual-list-scrollbar {
              width: 4px !important;
              background-color: #eeeeee;
              display: block !important;
            }

            ::-webkit-scrollbar-track {
              background-color: #eeeeee;
            }

            .ant-select-item {
              font-family: 'FZLanTingHeiS-R-GB';
              font-size: 13px;
              color: #212121;
            }

            .ant-select-item-option-selected {
              background-color: #fff;
              color: #666666;
            }
          }
        }
      }

      .sand {
        margin-top: 50px;
        // font-family: 'FZLanTingHei-M-GBK';
        color: #212121;
      }

      .closeIcon {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 18px;
        font-size: 12px;
        color: #212121;
        font-weight: 600;
      }

      .language {
        margin-top: 25px;
        // font-family: 'FZLanTingHei-M-GBK';
        font-weight: 600;
        color: #212121;
      }

      .selectLanguage {
        width: 100%;
        margin-top: 10px;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 56px;

          .ant-select-selection-item {
            line-height: 56px;
          }
        }

        .ant-select-single {
          width: 100%;
        }

        .ant-select-selection-item {
          font-size: 13px;
          color: #212121;
        }

        .ant-select-selection-placeholder {
          padding-top: 8px;

        }

        .ant-select-selector {
          border: 1px solid #e0e0e0;
        }
      }

      .buy_btnPC {
        width: 100%;
        height: 56px;
        line-height: 56px;
        margin-top: 32px;
        color: #fafafa;
      }

      .noSlect {
        background-color: #5f5f5f;
      }

      .selected {
        background-color: #212121;
        cursor: pointer;
      }
    }
  }
}