.pc-oneSeat {
  margin: auto;
  // margin-bottom: 80px;
  width: 100%;
  position: relative;

  .reverse {
    flex-direction: row-reverse;
  }

  .oneSeat_block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;

    .img_video {
      width: 50%;

      a {
        display: block;

        .media {
          width: 100%;
        }
      }
    }

    .describe {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      text-align: center;
      // top: 100px;
      // opacity: 0;
      transition: all 0.3s 0.15s ease-in;

      .one-icon {
        margin-top: 40px;
        width: 115px;
        height: 115px;
      }

      .iconWord {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .leftFlex {
      padding-left: 100px;
      align-items: flex-start;

      .flow-model {
        text-align: left;
      }
    }

    .rightFlex {
      padding-right: 100px;
      align-items: flex-end;
      margin: auto;

      .flow-model {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .layer_button {
        align-items: flex-end;
        padding: 1px;
      }
    }

    .text_center{
      text-align: center !important;
      display: flex;
      flex-direction: column;
      align-items: center !important;
      align-items: center !important;
    }

    .showWord {
      top: 0;
      opacity: 1;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 0px;
  }

  .menu-right {
    right: 0px;
  }
}