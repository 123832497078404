.pc-full-screen-swiper-pdp-m {
  position: relative;
  width: 100%;

  .swiper-horizontal {
    padding-bottom: 5px;
  }

  .menu-left {
    left: 20px;

    &.external {
      left: -40px;
    }
  }

  .slick-track .slick-slide {
    padding: 0 4px;

    .swiper-item {
      width: 100%;
    }
  }

  .menu-right {
    right: 20px;

    &.external {
      right: -40px;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
    font-weight: 600;
  }

  .ant-carousel {
    width: 100%;
    touch-action: pan-y;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .media_content .media {
      min-height: 306px;
    }
  }

  .slick-dots {
    bottom: -24px;
    padding: 0 21px;
    margin-left: 0;
    margin-right: 0;

    li {
      width: 6px !important;
      height: 6px !important;

      button {
        margin: 0;
        margin-right: 10px;
        width: 6px !important;
        height: 6px !important;
        border-radius: 50%;
        background-color: rgba(33, 33, 33, 0.2) !important;
      }
    }

    .slick-active {
      button {
        background-color: rgba(33, 33, 33, 1) !important;
      }
    }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiper-slide {
    width: calc(50% - 2*2%);
    // margin: 0 2%;

    .swiperBanner {
      padding-left: 2px;
      padding-right: 2px;

      // width: 200px;
      .swiper-item {
        width: 100px;
        height: 100px;
        display: block;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .swiper-desc {
    padding-top: 16px;

    .info {
      text-align: center;

      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .price-box {
        justify-content: center;
        margin-top: 8px;

        .price {
          height: 12px;
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }
  }

  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}