.pc-hot-area {
    position: relative;

    video {
        display: block;
    }

    .spanTagBgStyle {
        display: block;
        text-align: center;
        font-size: 14px;
        border-radius: 2px;
        background-color: rgba(255, 0, 0, .3);
        cursor: pointer;
    }

    .pc_play_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        font-size: 72px;
        text-align: center;
        color: #ffffff;
    }
}