.pc-checkout {
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';
  margin-bottom: 40px;

  .title {
    margin-top: 40px;
    margin-bottom: 60px;
    line-height: 27px;
  }

  .banner {
    display: block;
  }

  .checkout-tip {
    text-align: center;
    margin-bottom: 60px;
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;
    font-weight: 500;

    .bold {
      font-weight: bold;
    }
  }

  .cus-form-item {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .sub-title {
      display: inline-block;
      width: 117px;
      // margin: 0 40px 24px 0;
    }
  }

  .checkout-left {
    textarea {
      width: 100%;
      height: 80px;
      padding: 15px;
      background: #F3F3F3;
      background-color: #F3F3F3;
      border: none;
      margin-top: 24px;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background: #F3F3F3;
    }

    .agree {
      margin: 40px 0;
      color: rgba(33, 33, 33, .6);
      align-items: flex-start;

      a {
        color: rgba(33, 33, 33, 1);
        text-decoration: underline;
        cursor: pointer;
      }

      .ant-checkbox+span {
        margin-top: 2px;
      }

      .agree-tip {
        overflow: hidden;
        transition: height .3s;
        transform: translateX(-32px);
      }
    }

    .checkoutBtn {
      display: block;
      width: 352px;
      height: 50px;
      margin: auto;
      font-size: 14px;
      letter-spacing: 0.466667px;
      font-family: 'FZLanTingHei-M-GBK';
    }

    .jc-radio-border {
      display: flex;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  .checkout-right {
    width: 400px;
    margin-left: 80px;
  }

  .jcwx-first-radio {
    padding-right: 42pX;
  }
}