@import '@/style/mixin';

.cartDialogMob {
  width: 100vw;

  .ant-drawer-content-wrapper {
    height: -webkit-fill-available !important;
    overflow-y: scroll;
  }

  .ant-drawer-header {
    padding-bottom: 0;
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.cart-container-mob {
  width: 100%;
  position: relative;

  .cart-tip {
    font-family: 'FZLanTingHeiS-R-GB', 'ZonaPro', sans-serif;
  }

  .icon-guanbi {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
  }

  .cart-swiper {
    width: 100%;
    box-sizing: border-box;
  }

  .info-container {
    text-align: left;
    height: 100%;
    padding: 64px 16px 30px;

    .info-main {
      // padding-right: 12px;
      height: 100%;
      @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));
    }

    .info {
      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .desc {
        margin-top: 8px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        letter-spacing: 0.466667px;

        color: #424242;
      }

      .product-tag {
        margin: 8px 8px 0 0;
        display: inline-block;
        padding: 8px;
        text-align: center;
        // min-width: 80px;
        background: #fff;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #212121;
        border: 1px solid #BDBDBD;
      }

      .price-box {
        margin-top: 8px;

        .price {
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          letter-spacing: 0.466667px;

          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }

    .color-box {
      .color-title {
        display: flex;
        align-items: center;
        height: 15px;

        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        letter-spacing: 0.466667px;

        color: rgba(33, 33, 33, 0.6);

        .title {
          color: #212121;
        }
      }

      .color-list {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-top: 8px;
          box-sizing: border-box;
          margin-right: 8px;
          width: 74px;
          height: 74px;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &.active {
            border: 1px solid #212121;
          }
        }
      }
    }

    .size-box {
      .size-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 15px;

        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        letter-spacing: 0.466667px;

        color: #424242;

        .title {
          color: #212121;
        }
      }


      .size-list {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 24px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #212121;
      }

      .size-balance {
        margin-top: 8px;
        height: 15px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        letter-spacing: 0.466667px;

        color: rgba(33, 33, 33, 0.8);
      }
    }

    .delivery-date {
      margin-top: 32px;
      height: 15px;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.466667px;
      color: rgba(33, 33, 33, 0.8);
    }

    .cart-add {
      margin-top: 15px;
      height: 56px;
      background: #5f5f5f;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.466667px;
      color: #ffffff;

      &.is-soldout {
        pointer-events: none;
        background-color: #fafafa;
        border: 1px solid #e3e3e3;
        color: #212121;
      }

      &.active {
        background: #212121;
      }

      &.arrival {
        border: 1px solid #E0E0E0;
        background-color: #fff;
        color: #212121;
      }
    }

    .disbtn {
      pointer-events: none;
      opacity: .5;
    }

    .no_click_btn {
      pointer-events: none;
    }

    .subscribe {
      margin-top: 16px;
      width: 100%;
      height: 40px;
      background: #212121;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #fff;
    }

    .share-box {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-popover {
        z-index: 1;
      }

      .ant-popover-content,
      .ant-popover-inner,
      .ant-popover-inner-content {
        width: 200px;
      }
    }

    .more {
      height: 15px;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.466667px;
      text-decoration-line: underline;
      color: rgba(33, 33, 33, 0.8);
      text-decoration: underline;
    }
  }
}