.pc-search {
    margin: auto;

    .swiper-container {
        width: 100%;
        padding: 16px 24px;
        box-sizing: border-box;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 24.75%);
        grid-gap: 0 0.3%;
        grid-auto-flow: row;
    }

    .search-result {
        .result-title {
            text-align: center;
            margin: 24px auto;
        }

        .pc-full-screen-swiper-plp {
            width: 100% !important;

            .ant-carousel,
            .swiperBanner {
                min-height: 265px;
            }
        }
    }


}