.popup-wheel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 94px;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.12);
    z-index: 9999;

    .notifyBox {
        font-family: 'FZLanTingHeiS-R-GB';
        width: 100vw;
        height: 100%;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i.iconfont {
            margin-right: 20px;
        }

        .box-right {
            flex: 1;
            color: #242424;

            .good-name {
                font-family: Zona Pro;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;

            }

            .tip {
                font-family: FZLanTingHeiS-R-GB;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                span {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

    }
}