.order-itemMob {

  .product-img {
    width: 100px;
    height: 100px;
    margin-right: 16px;
  }

  .product-info {
    width: calc(100% - 116px);

    .opa4 {
      opacity: .4;
    }

    .name {
      height: 13px;
      line-height: 13px;
    }

    .tips {
      margin-bottom: 4px;
    }

    .columnSelect {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .select-color {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-bottom: 6px;
      }
    }
  }

  .fontZoo {
    font-family: 'Zona Pro';
  }
}