.place_left {
  // margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
}

.twoSeat_title_left {
  display: flex;
  flex-direction: column;
  width: 30% !important;
  justify-content: center;
  align-items: center;
}

.twoSeat_title_top {
  text-align: center;
  margin: auto;
  // margin-bottom: 50px;
}

.place_centwe {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  height: auto;
}

.twoSeat_title {
  margin: auto;
}

.twoSeat_pc {
  width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 70px;

  .jc-carousel {
    margin-top: 35px;
  }
  .caroulBox {
    width: 70%;
    position: relative;

    .ant-carousel {
      width: 100%;
    }

    .scale {
      transform: scale(1.1);
    }

    .small {
      transform: scale(0.9);
    }
  }

  .main_title {
    font-style: normal;
    font-size: 40px;
    line-height: 40px;
    // margin-top: 35px;
  }
  .jc-title-mb {
    margin-bottom: 25px;
  }

  .Subtitle {
    // margin-bottom: 15px;
    font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .slick-track {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 30px;
    cursor: pointer;
  }

  .menu-left {
    left: -20px;
  }

  .menu-right {
    right: 10px;
  }

  // .slick-slide {
  //   &:last-child {
  //     .media_block {
  //       margin-right: 0;
  //     }
  //   }
  // }
}
