.pc-sync-wish-page {
    font-family: Zona Pro, sans-serif;
    // background: #fafafa;
    max-width: 1440px;
    padding: 0 0 20px;
    margin: 0 auto;

    .wish-page-content {
        padding: 0px 24px;

        .wishlist-header-block {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            flex-direction: column;
            padding: 24px 0;

            .wishlist-header-title {
                display: flex;
                align-items: center;

                &-1 {
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: #242424;
                }

                &-2,
                &-3,
                &-4 {
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #757575;
                }

                &-2 {
                    margin-left: 8px;
                }

                &-4 {
                    margin-left: 4px;
                }

                &-3 {
                    margin-left: 4px;
                    font-family: 'Zona Pro';
                    position: relative;
                    top: 1px;
                }
            }

            .wishlist-header-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .action-wrapper {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &:first-of-type {
                        margin-right: 5px;
                    }

                    .icon-gouwudai,
                    .icon-gouwudai1 {
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin-right: 2px;

                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .action-title {
                        font-family: 'FZLanTingHeiS-R-GB';
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #242424;
                    }
                }
            }
        }

        .warning-selectvaraint-banner {
            font-size: 14px;
            background: #fff;
            padding: 16px;
            font-weight: 300;
            line-height: 1.57;
            color: #212121;
            margin-bottom: 24px;
            text-align: center;

            .wishlist-page-banner-text-bold {
                font-weight: 700;
            }
        }

        .wishlist-wrapper {
            .wishlist-content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px 9px;
                margin: 0 0 24px;

                .wishlist-item {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    z-index: 11;
                }
            }

            // @media only screen and (min-width: 768px) and (max-width: 1439px) {
            //     .wishlist-content {
            //         grid-template-columns: repeat(2, 1fr);
            //     }
            // }

            @media only screen and (min-width: 1440px) {
                .wishlist-content {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        .no-have-wish {
            max-width: 1464px;
            height: 248px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                font-family: 'FZLanTingHeiS-R-GB';
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #212121;
                margin-bottom: 24px;
            }

            .btns-box {
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    cursor: pointer;

                    button {
                        font-family: 'FZLanTingHeiS-R-GB';
                        padding: 10px 20px;
                        width: fit-content;
                        min-width: 233px;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #D7D7D7;
                        color: #242424;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }
            }
        }

        .swiper-banner-4 {
            margin: 0 !important;
        }

        .unlogin-message-wrapper {
            font-size: 14px;
            background: #fff;
            padding: 16px 16px 31px;
            font-weight: 300;
            line-height: 1.57;
            color: #212121;
            margin-bottom: 24px;
            text-align: center;

            a {
                color: #000;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: #666;
                }
            }
        }
    }
}