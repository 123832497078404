.product-family {

  &-title {
    margin-bottom: 20px;
    font-family: FZLanTingHei-M-GBK;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.46666666865348816px;
  }


  .product-family-swiper {
    margin-top: 20px;
    position: relative;



    .swiperBanner {
      position: relative;
      padding-right: 6px;

      a {
        &:hover {
          cursor: pointer;
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      .swiper-desc {
        .info {
          text-align: left;

          .title {
            margin-top: 4px;
            font-family: Zona Pro;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-align: left;
            word-break: break-word;
            overflow: hidden;
            color: #000;
          }

          .price-box {
            justify-content: flex-start;
            margin-top: 4px;

            .price {
              font-family: Zona Pro;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.46666666865348816px;
              color: #212121;

              &.gray {
                margin-right: 8px;
                text-decoration: line-through;
                color: rgba(33, 33, 33, 0.4);
              }

              &.final_price {
                color: rgba(193, 40, 27, 1);
              }
            }
          }
        }
      }
    }

    .slick-dots {
      position: absolute;
      right: 8px;
      bottom: -52px;
      left: initial;
      margin: 0;

      li {
        flex: 1;
        margin: 0 -2px;
        background: #E0E0E0;
        height: 1px;
        width: 22px;

        button {
          margin: 0;
          height: 1px;
          background-color: #000 !important;
        }
      }
    }

    .swiper-menu {
      position: absolute;
      bottom: -32px;
      cursor: pointer;
      font-size: 12px;
      color: #333;

      &.menu-left {
        right: 35px;
      }

      &.menu-right {
        right: 8px;
      }
    }
  }
}