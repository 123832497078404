.good_card {
  .good_media {
    width: 100%;
  }
  .card_bolck {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .goodName {
      margin-bottom: 9px;
      font-size: 14px;
      font-family: 'Zona' 'Pro-SemiBold', 'Zona Pro';
      font-weight: 600;
      color: #212121;
      line-height: 16px;
    }
    .amount {
      font-size: 12px;
      font-family: 'Zona Pro';
      color: #212121;
      letter-spacing: 0.466667px;
      display: flex;
      .price {
        text-decoration-line: line-through;
        color: #212121;
        opacity: 0.4;
        margin-right: 8px;
      }
      .final {
        color: #c1281b;
      }
    }
  }
}
