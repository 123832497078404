@import '@/style/mixin';

.minicart {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);

  .icon-close {
    position: absolute;
    right: 26px;
    top: 26px;
    font-size: 12px;
  }

  .cart-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    padding-bottom: 0;


    &.cart-empty {
      justify-content: flex-start;
      
      .title {
        padding-top: 328/1080*100vh;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #242424;
      }

      .sub-title {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #757575;
      }

      .swiper-tip {
        margin-top: 24px;
        font-size: 14px;
        line-height: 22px;
        color: #212121;
        width: 100%;
      }

      .search-swiper {
        margin-top: 8px;
        text-align: center;

        .fx {
          min-height: 190px;
        }

        .ant-carousel {
          font-size: 12px;
          line-height: 13px;

          .search-swiper-item {
            img {
              margin: 0 auto;
              width: 146px;
              height: 146px;
            }
          }

          .product-name {
            font-weight: 600;
          }

          .slick-dots {
            display: none !important;
          }

        }

        .swiper-arrow {
          min-height: 22px;
          margin: 24px 0;

          .swiper-menu {
            font-size: 12px;
            margin-left: 24px;
          }
        }
      }
    }

    &.cart-has-goods {
      padding: 24px 0 0;

      .cart-header {
        width: 100%;
        padding: 0 24px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-text {
          &-1 {
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #242424;
          }

          &-2,
          &-3,
          &-4 {
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #757575;
          }

          &-2 {
            margin-left: 8px;
          }

          &-4 {
            margin-left: 4px;
          }

          &-3 {
            margin-left: 4px;
            font-family: 'Zona Pro';
            position: relative;
            top: 1px;
          }
        }

      }

      .cart-list {
        flex: 1;
        overflow-y: scroll;
        @include scrollbarSmall();
        padding: 0 24px;
        width: 100%;

        .cart-item {
          margin-bottom: 24px;
          position: relative;
          background: #FFFFFF;
          border-radius: 4px;
          overflow: hidden;
          display: flex;

          .product-img {
            width: 180px;
            height: 180px;
          }

          .product-info {
            flex: 1;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .product-name {
              word-break: break-all;
              font-weight: 600;
              font-size: 20px;
              line-height: 22px;
              letter-spacing: 0.15px;
              color: #242424;
            }

            .tag {
              position: absolute;
              top: 0;
              left: 0;
              background: #FAFAFA;
              padding: 5px 8px;
              font-family: 'FZLanTingHeiS-R-GB';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #212121;
          }
          
            .swatch-config {
              margin-top: 4px;

              &+.swatch-config {
                margin-top: 0;
              }

              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #242424;
            }

            &-footer {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              .price {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #242424;
              }

              .oldPrice {
                text-decoration-line: line-through;
                color: #212121;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 2px;
              }

              .finalPrice {
                color: #C1281B;
              }

              .del-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-decoration-line: underline;
              }
            }
          }
        }


      }

      .footer {
        padding: 16px 24px 24px;
        width: 100%;
        box-shadow: 0 -1px 12px rgba(0, 0, 0, .08);

        .total {
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #242424;

          .total-num {
            font-weight: 600;
            line-height: 24px;
          }
        }

        .payNowBtn {
          width: 100%;
          height: 56px;
          margin-top: 16px;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 1px;
          text-transform: capitalize;
          color: #FAFAFA;
        }

        .go-cart {
          display: block;
          margin-top: 16px;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.466667px;
          text-decoration-line: underline;
          color: #212121;
          text-align: center;
        }
      }

      .minCart_bottom {
        width: 100%;
        background: #fafafa;
        height: 48px;
        line-height: 48px;
        font-size: 12px;
        text-align: center;
        color: #616161;
      }
    }
  }

}