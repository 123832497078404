@import '@/style/mixin';

.mobile-ExclusiveServices {
  position: relative;
  // top: -70px;
  width: 375px;
  height: 596px;

  .contain {
    padding-top: 32px;
    padding-left: 20px;
  }

  .title {
    width: 105px;
    height: 25px;
  }

  .item {
    position: relative;
    margin-top: 40px;
    width: 312px;
  }

  .item0 {
    height: 76px;
  }

  .item1 {
    height: 76px;
  }

  .item2 {
    height: 100px;
  }

  .item3 {
    height: 73px;
  }

  .tel {
    position: absolute;
    // font-family: 'Zona Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.466667px;
    color: #FFFFFF;
    top: 84px;
    left: 38px;
    opacity: .9;
  }
}