@import '@/style/mixin';

.pc-cms-dialog {

    .ant-modal-body {
        padding: 40px 0px 48px;
        box-sizing: border-box;
    }

    .dialog-content {
        margin-top: 15px;
        padding: 0px 30px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: scroll;
        @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));
    }

    .ant-modal-close-x {
        color: #212121;
    }


}