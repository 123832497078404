.swiper-banner-4 {
  margin-top: 80px;
  padding: 24px 25px 30px 22px;
  background: #FFF;
  position: relative;

  &.mobile-page {
		padding: 24px 0 24px 16px;
    .nav-tab {
			margin-bottom: 16px;
    }
  }

  .loveWay-list {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .item {
      box-sizing: border-box;
      border-bottom: 2px solid #E0E0E0;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.466667px;

      color: #757575;
			padding-bottom: 10px;

      &.active {
        color: #212121;
        border-bottom: 2px solid #212121;
      }
    }
  }

  .four-swiper-container {
    margin-top: 32px;
  }
}