.mob-valet-pay {
    .valet-pay {
        text-align: center;
        margin-top: 36px;
        color: #212121;
        font-family: 'FZLanTingHeiS-R-GB';

        .tip {
            color: rgba(33, 33, 33, .8);
            line-height: 24px;
        }

        .paySum {
            margin: 20px auto;
            color: rgba(33, 33, 33, .8);

            span {
                color: #C1281B;
            }
        }

        .f24 {
            font-family: 'FZLanTingHei-M-GBK';
        }

        .jcform {
            width: 100%;
            margin: auto;
            text-align: left;

            .btn {
                width: 100%;
                height: 50px;
            }

            .confirm {
                margin-bottom: 12px;
            }
        }
    }
}