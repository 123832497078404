.pc-pay {
    margin: auto;
    font-family: 'FZLanTingHeiS-R-GB';
    padding-bottom: 160px !important;
.notShow{
    width:0;
    height:0;
    opacity:0;
}
    .pc-pending {
        .pay-tip {
            margin-top: 60px;
        }

        .pay-content {
            width: 100%;
            height: fit-content;
            box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.05);
            margin-top: 40px;
            padding: 30px;

            .code-box {
                width: 60%;
                margin: 50px auto 10px;

                .paycode {
                    width: 300px;
                    height: 300px;

                }

                .scan {
                    width: 276px;
                    height: 64px;
                    padding: 10px 78px;
                    background: #212121;
                    color: #fff;
                    margin: 14px 0 0 12px;
                    text-align: center;
                    line-height: 22px;
                }

                .right-box {
                    margin: -20px 130px;

                    .wxCodeImg {
                        width: 400px;
                    }

                    .code-swiper {
                        margin: 80px 130px;
                        width: 244px;

                        img {
                            width: 100%;
                        }
                    }
                }


            }

        }
    }

    .empty-page {
        height: 300px;

        p {
            margin-top: 100px;
            text-align: center;
        }
    }

}

.select-pay-container {
    width: 760px;
    text-align: center;
    width: max-content;

    .pay-methods {

        ul li {
            width: 220px;
        }
    }

    .confiram {
        width: 300px;
        height: 50px;
    }
}