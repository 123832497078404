.pc-full-screen-swiper-plp-m {
  position: relative;
  width: 100%;
  padding: 24px 0 32px;
  user-select: none;

  &.video {
    .media_content {
      display: block;
      font-size: 0;
    }
  }

  .menu-left {
    left: 45px;
  }

  .menu-right {
    right: 45px;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 31px;
    height: 93px;
  }

  .ant-carousel {
    width: 100%;
    min-height: 170px;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .slick-dots {
      z-index: 9;
    }

    .media_content .media {
      min-height: 306px;
    }
  }

  .slick-dots {
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(0, 0, 0, 0.1);

    & li {
      margin: 0;
    }

    &.dots-style0 li {
      width: 100%;
    }

    &.dots-style1 li {
      width: 100%;
    }

    &.dots-style2 li {
      width: 50%;
    }

    &.dots-style3 li {
      width: 33.33%;
    }

    &.dots-style4 li {
      width: 25%;
    }

    &.dots-style5 li {
      width: 20%;
    }

    &.dots-style6 li {
      width: 16.66%;
    }

    &.dots-style7 li {
      width: 14.29%;
    }

    button {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .slick-active {
      button {
        background-color: #212121 !important;
      }
    }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;

    .sales_short_tips {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      padding: 5px 8px;
      background: #fafafa;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #212121;
    }
  }

  .swiper-desc {
    padding-top: 16px;

    .info {
      text-align: left;

      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.466667px;
        word-wrap: break-word;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .price-box {
        margin-top: 8px;

        .price {
          height: 16px;

          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          letter-spacing: 0.466667px;

          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }

    .dot-box {
      margin-top: 16px;

      .dot {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}