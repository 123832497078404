.topTab {
  margin-top: 20px;
  width: 100%;
  .ant-collapse-header {
    height: 40px;
    padding: 0 16px !important;
    background-color: #212121;
  }
  .ant-collapse-header-text {
    width: 100%;
    height: 100%;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'FZLanTingHei-M-GBK';
  }
  .h38 {
    height: 38px;
    padding: 11px 16px;
    background-color: #eee;
    line-height: 16px;
    a {
      display: inline-block;
      width: 100%;
    }
  }
}
