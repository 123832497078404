.mob-threeSeat {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  // margin-bottom: 40px;

  .title {
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin: auto;
    margin-bottom: 20px;
  }

  .describe {
    margin: auto;
    margin-bottom: 15px;
  }

  .w50 {
    width: 49%;
    margin: auto;
    margin-right: 1%;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  .threeSeat_block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}