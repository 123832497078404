// 居中显示
@mixin display-center ($direction: column) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

// 文本超出省略号
@mixin ellipsis-text($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line ==1 {
    white-space: nowrap;
  }

  @else {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}

// 滚动条样式
@mixin scrollbar-color($color1, $color2) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color1;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color2;
    transition: all .2s;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color2;
  }


  &::-ms-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color1;
  }

  &::-ms-scrollbar-track {
    border-radius: 5px;
  }

  &::-ms-scrollbar-thumb {
    background-color: $color2;
    transition: all .2s;
    border-radius: 5px;
  }

  &::-ms-scrollbar-thumb:hover {
    background-color: $color2;
  }

  & {
    scrollbar-color: $color2 $color1;
    scrollbar-width: thin;
  }

  &::-o-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color1;
  }

  &::-o-scrollbar-track {
    border-radius: 5px;
  }

  &::-o-scrollbar-thumb {
    background-color: $color2;
    transition: all .2s;
    border-radius: 5px;
  }

  &::-o-scrollbar-thumb:hover {
    background-color: $color2;
  }
}

// 滚动条样式 JC小滚动条
@mixin scrollbarSmall {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: none;
  }

  &::-webkit-scrollbar-track {
    background-color: #EEEEEE;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #212121;
    transition: all .2s;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #212121;
  }

  // https://www.cnblogs.com/wazosky/articles/16206003.html
  // 火狐滚动条
  scrollbar-color: #212121 #EEEEEE;
  scrollbar-width: thin;
}