@import '@/style/mixin';

.appointShop {
  height: 626px;

  padding-bottom: 0;

  .ant-modal-content {
    height: 626px;
    overflow-y: auto;
  }

  .shop_box {
    height: 92%;
    overflow: hidden;
    overflow-y: auto;
    // @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1));

  }

  .go-back {
    width: 100px;
    cursor: pointer;
  }

  .goodImg {
    width: 367px;
    height: 342px;
  }

  .mr24 {
    margin-right: 24px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .fw600 {
    font-weight: 600;
  }

  .fw700 {
    font-weight: 700;
  }

  .maxH {
    height: 40px;
  }

  .color4242 {
    color: #424242;
  }

  .colorBD {
    color: #bdbdbd;
  }

  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.466667px;

    color: #212121;

    &.gray {
      margin-right: 8px;
      text-decoration: line-through;
      color: rgba(33, 33, 33, 0.4);
    }

    &.final_price {
      color: rgba(193, 40, 27, 1);
    }
  }

  .bottom {
    position: absolute;
    bottom: 17px;
    right: 16px;
  }

  .pd96 {
    padding: 32px 96px;

    .shopItem {
      width: 50%;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item_info {
        margin-bottom: auto;
      }

      .w240 {
        width: 240px;
        background: #ffffff;
        color: #212121;
        border: 1px solid #e0e0e0;
      }
    }
  }

  .ant-modal-body {
    padding: 0;
    min-height: 594px;
    height: 100%;

    .shop_box::-webkit-scrollbar {
      width: 0.06944rem;
      height: 0.06944rem;
      background-color: white;
    }

    .shop_box::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 1);
      -webkit-transition: all .2s;
      transition: all .2s;
      border-radius: 0.06944rem;
    }
  }

  .ant-modal-close-x {
    color: #212121;
  }

  .ant-pagination-item {
    a {
      color: #757575;
    }
  }

  .ant-pagination-item-active {
    border: none;

    a {
      color: #212121;
    }
  }
}