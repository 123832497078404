.jc-contact-form {
	padding: 0 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #FFF;
	margin: auto;
	max-width: 1440px;

	.contact-form-top {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 20px;

		.title {
			color: #212121;
			text-align: center;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: uppercase;
		}

		.tips {
			color: #212121;
			font-size: 14px;
			font-weight: 600;
			font-family: Zona Pro;
			line-height: normal;
			letter-spacing: 0.467px;
		}
	}

	.contact-form {
		margin-top: 64px;
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: center;


		.form-content {
			width: 55vw;
		}

		.modal-title {
			display: block;
			font-size: 16PX;
			line-height: 1;
			color: #212121;
			margin-bottom: 28px;
			margin-top: 24px;
			font-weight: 600;
		}

		.select1 {
			width: 29vw;
		}

		.modal-date {
			.input-select.date-select {
				background: #fff !important;
				width: auto;
				margin: 0;
				cursor: auto;
			}
		}

		.modal-date-month {
			label {
				opacity: 0;
			}
		}

		.icon-yiwen {
			font-size: 13px;
		}

		.tip-red {
			color: #C1281B;
			font-size: 13px;
		}

		.form-label {
			.ant-form-item-label label {
				font-weight: 600;
				font-size: 13px;
				color: #212121;
				line-height: 28px;
			}
		}

		.tooltip {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;

			span {
				:last-child {
					margin-top: -10px;
				}
			}
		}

		.jc-upload-custom-preview {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
		}

		.jc-upload-custom-upload-item {
			margin-top: 24px;
		}

		.jc-upload-preview {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
		}

		.jc-upload-file-name {
			display: inline-block;
			font-size: 13px;
			width: 10vw;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 20px;
		}

		.jc-upload-sign {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			margin-top: 40px;
		}

		.label-message {
			.ant-form-item-label label {
				font-weight: 600;
				font-size: 16PX !important;
			}
		}


		.privacy-clause {
			color: rgba(33, 33, 33, 0.60);
			font-size: 12px;
		}

		.ant-form-item-required,
		.ant-form-item-label label {
			font-weight: 600;
			font-size: 13px;
			letter-spacing: 0.47px;
		}

		.ant-input,
		.ant-select-selector,
		.ant-input-affix-wrapper {
			border: 1px solid #e0e0e0 !important;
			box-shadow: none;
			font-size: 13px;
		}

		.ant-input {
			&::placeholder {
				font-size: 13px;
			}
		}

		.dis-placeholder {

			/* 修改placeholder字体颜色 */
			.ant-input::placeholder {
				color: #212121 !important;
			}
		}

		/* 修改placeholder字体颜色 */
		.ant-input::placeholder {
			color: #929292 !important;
		}

		/* 修改placeholder字体颜色 */
		.ant-select-selection-placeholder {
			color: #929292;
		}

		.icon-xialajiantou {
			color: #212121;
			font-size: 20px;
		}

		.ant-radio-wrapper {
			margin-right: 40px;
		}
	}

	.contact-form-card {
		margin-top: 96px;
		margin-bottom: 110px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;

		.cards {
			display: flex;
			align-items: initial;
			justify-content: center;
			flex-direction: row;
			gap: 14px;
			width: inherit;

			.card {
				flex: 1;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				padding: 40px 20px;
				gap: 14px;
				background: #FAFAFA;

				.f600 {
					font-weight: 600;
				}

				span {
					align-self: flex-start;
					display: block;
					color: #212121;
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0.467px;
					font-family: Zona Pro;
				}

				// .card-3-span {
				// 	line-height: 21px;
				// }
			}
		}

		p {
			text-align: center;
			color: #212121;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0.467px;
			margin-top: 24px;
		}
	}

	.text-line {
		text-decoration-line: underline;
		word-break: break-word;
		;
	}

	.ant-btn {
		background: #212121;
		color: #fff;
	}

	.ant-input:focus {
		outline: none;
		// border-bottom: 1px solid white !important;
		box-shadow: none !important;
	}

	.ant-form-item {
		margin-bottom: 16px;
	}

	.ant-tooltip-inner {
		color: #000 !important;
		background: #fff !important;
	}

	.ant-btn[disabled] {
		background-color: #B1B0B0;
	}

	.ant-upload {
		pointer-events: none;
	}

	.ant-upload-btn {
		width: 90px;
		pointer-events: auto;
	}

	textarea {
		height: 130px;
	}

	.ant-select-selection-placeholder {
		font-size: 13px;
	}


	.jc-upload-file-del {
		pointer-events: auto;
	}

	.ant-select-dropdown {
		z-index: 99;
	}


	.mlr16 {
		margin-left: 16px;
		margin-right: 16px;
	}

}

.ant-tooltip-inner {
	color: #212121;
}

#form_Message {
	min-height: 130px;
}