.mob-Customerhtml{
    width: 100%;
    .ProList-title{
        line-height:calc( 24vw / 3.75);
        padding: 0 0 calc( 18vw / 3.75) calc( 25vw / 3.75);
        box-sizing: border-box;
        font-size: 24px;
        font-weight: bold;
        font-family: "Univers LT Std", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
}