.pc-full-screen-swiper-pdp {
  // position: relative;
  position: initial;
  margin-top: 16px;
  width: 100%;
  margin-left: -2px;

  .menu-left {
    right: 64px;
  }

  .menu-right {
    right: 32px;
  }

  .swiper-menu {
    position: absolute;
    bottom: 24px;
    transform: translateY(-50%);
    cursor: pointer;
    font-weight: 600;
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;
    // padding: 0 5px 30px;
    padding-bottom: 30px;

    a {
      display: block;

			&:hover {
				cursor: pointer;
			}
    }

    img {
      display: block;
    }
  }

  .swiper-desc {
    padding-top: 16px;

    .info {
      text-align: center;

      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.466667px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .price-box {
        justify-content: center;
        margin-top: 8px;

        .price {
          height: 16px;
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }
  }


  .slick-dots {
    position: absolute;
    right: 8px;
    left: auto;
    bottom: -38px;
    height: 32px;
    margin-left: 0;
    margin-right: 0;
    width: 16vw;

    li {
      flex: 1;
      margin: 0 -2px;
      background: #E0E0E0;
      height: 2px;

      button {
        margin: 0;
        height: 2px;
        background-color: #212121 !important;
      }
    }
  }
}