.mob-wishs-share-page {
    font-family: 'FZLanTingHeiS-R-GB';
    width: 100vw;
    padding-bottom: 60px;

    .greetings-content {
        padding: 24px 12px 20px;

        p {
            text-align: left;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: 0.466667px;
            color: #212121;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .wishlist-wrapper {
        border-top: .5px solid #ccc;

        .mob-wishlist-content {
            .wishlist-item {
                &:first-child {
                    padding: 20px 12px 16px;
                }

                padding: 16px 12px 16px;
                // opacity: .2;
                border-bottom: .5px solid rgba(0, 0, 0, .2);
            }
        }

        .btn-wrapper {
            box-sizing: border-box;
            width: 100vw;
            margin-top: 20px;
            padding: 0 12px;
            display: flex;
            justify-content: center;

            button.balck-bg-btn {
                width: 100%;
                max-width: 352px;
                height: 50px;
                font-family: 'FZLanTingHeiS-R-GB';
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                background: #212121;
                border: 1px solid #BDBDBD;
                color: #FFFFFF;
            }


        }
    }
}

.mob-wish-share-page {
    width: 100vw;
    height: auto;
    margin-bottom: 50px;

    .image-wrapper {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .wish-share-info {
        font-family: 'FZLanTingHei-M-GBK';
        width: 100%;
        display: flex;

        .share-info {
            width: 50%;
        }

        .info-left {
            position: relative;
            border-right: 1px solid rgba(33, 33, 33, .6);

            .left-title-first {
                position: absolute;
                left: 12px;
                top: 78px;
                // margin: 0 48px;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #D0A898;
                transform-origin: 0 0;
                transform: rotate(-90deg)
            }

            .left-good-info {
                margin: 0 8px 0 36px;

                .good-image-wrapper {
                    // width: 144px;

                    img {
                        aspect-ratio: 1;
                        height: 144px;
                    }
                }

                .good-base-info {
                    text-align: left;
                    margin: 8px 0 16px 0;

                    .base-info-title {
                        font-family: 'Zona Pro';

                        font-weight: 700;
                        font-size: 14px;
                        line-height: 16px;
                        color: #212121;
                    }

                    .base-info-desc {
                        word-break: break-word;
                        margin-top: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #424242;
                        word-break: break-word;
                    }

                    .base-info-attr {
                        font-family: FZLanTingHei-M-GBK;
                        margin-top: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #212121;

                        .attr-label {
                            margin-right: 4px;
                        }
                    }
                }

                .left-title-second {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #D0A898;
                    margin-bottom: 8px;
                }
            }
        }

        .info-right {
            .sharing-words-content {
                margin: 83px auto 24px;

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    color: #000000;
                }

                .first-line {
                    .receiver-name {
                        color: #D0A898;
                        text-decoration: underline;
                    }
                }

                .third-line {
                    color: #D0A898;
                }
            }

            .brand-title {
                text-align: center;
                font-size: 20px;
                font-weight: 400;
                color: #000000;
                text-transform: uppercase;
            }

            .logo-wrapper {
                margin: 0 auto;
                width: 24vw;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}