.search-swiper {
  width: 100%;
  // height: 240px;

  .ant-carousel {
    width: 100%;
    height: fit-content;

    .slick-dots-bottom {
      margin: 0;
      right: 0;
      bottom: -40px;
      left: auto;

      li {
        margin: 0;
        background: #E0E0E0;
        width: 28px;
      }

      .slick-active {
        background: #212121;

        button {
          color: #212121;
          background: #212121;
        }
      }
    }
  }

  .slick-list {
    .slick-slide {
      pointer-events: auto;
    }
  }

  .slick-track {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: nowrap;
  }


  .search-swiper-item {
    margin-right: 2px;
    // width: calc(100% / 3);
    height: fit-content;

    .product-name {
      word-break: break-word;
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img {
      width: 112px;
      height: 112px;
    }
  }

  .swiper-arrow {
    width: 100%;
    text-align: right;
    margin: 6px auto 20px;

    .icon-zuojiantou {
      margin-right: 34px;
    }
  }

}