.pc-navigation {
  width: 100%;
  box-sizing: border-box;

  .navigation-header {
    width: 100vw;
    // left: 0;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.5s ease;
    background-color: transparent;
    color: #212121;
    overflow: hidden;
    z-index: 1002;

    &.nav-no-style {
      background-color: transparent;
      color: #fff;
    }

    &.scroll-default {
      padding: 16px 0;

      .navigation-title img,
      .logo-svg {
        height: 81px;
        max-height: 81px;
        width: auto;
        transition: height 0.3s ease;
        // transition-delay: 0.4s;
      }
    }

    &.scroll-down {
      align-items: center;
      padding: 16px 0;
      background-color: #fff;
      color: #212121;
      transition: background-color 0.6s ease;

      .navigation-title img,
      .logo-svg {
        height: 33px;
        width: auto;
        transition: height 0.3s ease;
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1238px) {
      &.scroll-default {

        .navigation-title img {
          height: 33px;
          max-height: 33px;
          width: auto;
          transition: height 0.3s ease;
          // transition-delay: 0.4s;
        }
      }
    }

    &.nav-transparent-small {
      align-items: center;
      padding: 16px 0;
      background-color: transparent;
      color: #212121;

      .navigation-title img,
      .logo-svg {
        height: 33px;
        width: auto;
        transition: height 0.3s ease;
      }
    }

    &.scroll-up {
      // padding: 30px 0;
      padding: 16px 0;

      .left-nav,
      .right-nav {
        align-self: flex-start;
      }

      .navigation-title img {
        // width: 30.21vw;
        // width: 13.02vw;
        height: 33px;
        width: auto;
        transition: height 0.3s ease;
      }

    }

    // &.navigation-hide {
    //   display: none;
    // }

    .navigation-title {
      // flex: 1;
      display: flex;
      justify-content: center;
      /* 水平居中 */
      align-items: center;

      img {
        // width: 30.21vw;
        // width: 13.02vw;
        height: 33px;
        width: auto;
        transition: font-size 0.3s ease;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    &.nav-white {
      background-color: #fff;
      color: #212121 !important;
      transition: background-color 0.6s ease;
    }



    .left-nav,
    .right-nav {
      display: flex;
      flex: 1; // padding: 0 32px;
      align-self: baseline;
      height: 34px;

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 20px;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;

          .iconfont {
            margin-right: 8px;
            font-size: 24px;
            text-decoration: none !important;
          }

          a {
            color: inherit;
            // text-decoration: none;
            transition: color 0.3s ease;
            font-size: 14px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
              text-underline-offset: 4px;
            }
          }



          .active {
            // color: #888;
            text-decoration: underline;
            text-underline-offset: 4px;
          }

          &:last-of-type {
            position: relative;

            span {
              position: absolute;
              left: 19px;
              top: 6px;
            }
          }
        }
      }
    }

    .left-nav {
      padding-left: 30px;
      padding-top: 2px;

      .search-box {
        a {
          margin-top: 2px;
        }
      }

      .iconfont {
        height: 34PX;
      }
    }

    .right-nav {
      justify-content: flex-end;
      padding-right: 32px;

      ul {
        padding-right: 24px;
      }


      li {
        // cursor: pointer;

        &:hover {
          a {
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }


  }

  .header-cart-total {
    // font-family: 'FZLanTingHeiS-R-GB';
    font-size: 10px;
  }
}