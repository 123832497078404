.mobile-sync-wish-page {
    font-family: 'FZLanTingHeiS-R-GB';
    width: 100vw;
    height: auto;
    padding: 0 16px;
    margin: 0;

    .wish-page-content {

        .wishlist-header-block {
            display: flex;
            flex-direction: column;
            padding: 24px 0px;

            .wishlist-header-title {
                display: flex;
                align-items: center;
                // margin-bottom: 28px;

                &-1 {
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: #242424;
                }

                &-2,
                &-3,
                &-4 {
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #757575;
                }

                &-2 {
                    margin-left: 8px;
                }

                &-4 {
                    margin-left: 4px;
                }

                &-3 {
                    margin-left: 4px;
                    font-family: 'Zona Pro';
                    position: relative;
                    top: 1px;
                }
            }

            .wishlist-header-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 28px;

                .action-wrapper {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    .icon-gouwudai {
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;

                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            .warning-selectvaraint-banner {
                font-size: 14px;
                background: #fff;
                padding: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #242424;
                // margin-bottom: 24px;
                text-align: center;

                // .wishlist-page-banner-text-bold {
                //     font-weight: 700;
                // }
            }
        }


        .wishlist-wrapper {
            padding-top: 4px;
            // padding-bottom: 80px;

            .wishlist-content {
                display: block;
                margin: 0;

                .wishlist-item {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    z-index: 11;

                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }

            // @media only screen and (min-width: 768px) and (max-width: 1439px) {
            //     .wishlist-content {
            //         grid-template-columns: repeat(2, 1fr);
            //     }
            // }

            @media only screen and (min-width: 1440px) {
                .wishlist-content {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        .unlogin-message-wrapper {
            font-size: 14px;
            background: #fff;
            height: 60px;
            padding: 16px;
            font-weight: 300;
            line-height: 1.57;
            color: #212121;
            margin-top: 24px;
            text-align: center;

            a {
                color: #000;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: #666;
                }
            }
        }

        .no-have-wish {
            width: 100%;
            min-height: 136px;
            padding-bottom: 80px;

            .title {
                font-family: 'FZLanTingHeiS-R-GB';
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #212121;
                margin-bottom: 24px;
            }

            .btns-box {
                a {
                    display: block;
                    width: 100%;
                    cursor: pointer;

                    button {
                        font-family: 'FZLanTingHeiS-R-GB';
                        padding: 12px 24px;
                        width: 100%;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #D7D7D7;
                        color: #242424;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;

                        &:first-child {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .swiper-banner-4 {
            margin: 0 !important;
        }
    }
}