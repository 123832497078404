// L1轮播
.pc-full-screen-carousel {
  position: relative;
  width: 100%;
  // margin-bottom: 60px;

  .menu-left {
    left: 45px;
  }

  .menu-right {
    right: 45px;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 31px;
    height: 93px;
  }

  .ant-carousel {
    width: 100%;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .media_content .media {
      min-height: 306px;
      // background: url('../../../../assets/mobImg/loading.gif') center center no-repeat;
    }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;
  }

  .lower_left_corner {
    bottom: 20%;
  }
}

.bq-carousel-banner {

  cursor: pointer;

  .slick-dots-bottom {
    .slick-active {
      width: unset !important;
    }
  }

  .slick-dots {
    width: fit-content;
    bottom: 66px;
    text-align: center;
    margin: auto;

    >li {
      margin: 0;
      width: unset;
      margin-right: 12px;


      button {
        width: 0.1111112rem;
        height: 0.1111111rem;
        border-radius: 50%;
        background-color: #ACACAC;
        border: 1px solid #ACACAC;
        opacity: 1 !important;
      }

      &.slick-active {
        button {
          background-color: #000;
          border: 1px solid #000;
        }
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }
}