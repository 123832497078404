.pc-no-result {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    .searchInput {
        max-width: 560px;
        margin: auto;
        position: relative;

        .searchIcon {
            position: absolute;
            left: 4px;
            top: 3px;
            z-index: 2;
        }

        input {
            border: 0;
            border-bottom: 1px solid #757575;
            padding-left: 30px;
            margin-bottom: 32px;
        }

        .nofind {
            font-family: 'Zona Pro';
            color: #212121;
            font-weight: 600;
        }

        .other {
            font-family: 'FZLanTingHeiS-R-GB';
            color: #757575;
        }
    }

    .search-help {
        padding: 15px 0;
        background-color: #FAFAFA;
        margin: 32px auto;

        p {
            line-height: 24px;
        }

    }


    .adBanner {
        width: 100%;

        a {
            width: calc((100% - 6px) / 4);
        }

        img {
            width: 100%;
        }
    }
}