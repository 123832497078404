.pc-MoreSeat {
  // margin-bottom: 80px;
  width: 100%;
  position: relative;

  .reverse {
    flex-direction: row-reverse;
  }

  .MoreSeat_block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;

    .img_video {
      width: 50%;
      position: relative;

      a {
        display: block;

        .media {
          width: 100%;
        }
      }
    }

    .describe {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      text-align: center;
      // top: 100px;
      // opacity: 0;
      transition: all 0.3s 0.15s ease-in;

      .one-icon {
        margin-top: 40px;
        width: 115px;
        height: 115px;
      }

      .iconWord {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .leftFlex {
      padding-left: 100px;
      align-items: flex-start;

      .flow-model {
        text-align: left;
      }
    }

    .rightFlex {
      padding-right: 100px;
      align-items: flex-end;
      margin: auto;

      .flow-model {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .layer_button {
        align-items: flex-end;
        padding: 1px;
      }
    }

    .text_center{
      text-align: center !important;
      display: flex;
      flex-direction: column;
      align-items: center !important;
      align-items: center !important;
    }

    .showWord {
      top: 0;
      opacity: 1;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 0px;
  }

  .menu-right {
    right: 0px;
  }

  .icon-Frame2 {
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
  }

  .icon-a-huaban13 {
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
  }
}

.popBox {
  transform: translate(-5px 10px) !important;
  padding-left: 0;
  padding-top: 22px;

  .colorBox {
    width: 400px;
    height: fit-content;
    background-color: #fff;
    padding: 40px 10px 30px;
    margin: auto;

    .firstImg {
      width: 150px;
    }

    .blockOne {
      margin-right: 20px;
      margin-bottom: 30px;

      .colorImg {
        width: 60px;
        cursor: pointer;
      }
    }
  }

  .ant-popover-buttons {
    display: none;
  }

  .anticon-exclamation-circle {
    display: none;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-message {
    padding: 0;
  }

  .ant-popover-content {
    transform: translate(-18px, 0px);
  }
}