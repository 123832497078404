.goodInfo {
  word-break: break-all;
  .fw600 {
    font-weight: 600;
  }
  .fw700 {
    font-weight: 700;
  }
  .color4242 {
    color: #424242;
  }
  .colorBD {
    color: #bdbdbd;
  }
  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.466667px;

    color: #212121;

    &.gray {
      margin-right: 8px;
      text-decoration: line-through;
      color: rgba(33, 33, 33, 0.4);
    }

    &.final_price {
      color: rgba(193, 40, 27, 1);
    }
  }
}
