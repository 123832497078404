.jcm-store-self-collection-com {
  .jcm-tips {
    color: #212121;
    opacity: 0.6;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .jcm-from {
    display: flex;
    flex-direction: column;

    .ant-input {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .jcm-from-items {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }

    .jcm-click-area {
      display: flex;
      justify-content: space-between;
    }

    .jcm-form-item-flex-38 {
      width: 36vw;
      margin-right: 5px;
    }

    .jcm-form-item-flex-base {
      flex: 1;
    }

    .jcm-form-item-flex-1 {
      display: flex;
      justify-content: space-between;

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .jcm-form-bottom {
      display: flex;
      // justify-content: flex-start;
      margin-top: 20px;
    }

    .jcm-color-red {
      color: red;
    }
  }

  .jcm-load-mt {
    margin-top: -20px;
    color: #212121;
    opacity: .6;
  }

  .jcm-address-div {
    display: flex;
    align-content: center;
    flex-direction: column;
		gap: 9px;

    .jcm-address-span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .jcm-self-address-btn {
    width: 100%;
    align-content: center;
    line-height: 10px;
  }

  .icon-a-fasongdaohang-m {
    margin-right: 10px;
  }

  .jcm-content {
    margin-top: 10px;
  }

  .jcm-address-item {
    width: 100%;
    margin-bottom: 24px;
  }

  .jcm-address-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .jcm-text-over {
      width: 220px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 20px;
      text-overflow: ellipsis;
      color: rgba(33, 33, 33, 0.8);
      letter-spacing: 0.466667px;
    }
  }

  .jcm-address-icon {
    margin-right: 20px;
  }

  .jcm-flex-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .jcm-font-s {
    font-size: 14px;
    font-weight: 400;
  }

  .jcm-font {
    color: rgba(33, 33, 33, 0.8);
    font-size: 13px;
    line-height: 14px;
    font-weight: 400;

    .jcm-font-count {
      color: #000;
      font-weight: 500;
    }
  }

  .jcm-font-f {
    font-family: 'Zona Pro';
    word-break: break-all;
  }

  .jcm-tb-border {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    opacity: 0.6;
    padding: 12px 0;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .jcm-btn-w100 {
    flex: 0 1 27%;
    // width: 100px;
  }

  .jcm-mb-6 {
    margin-bottom: 6px;
  }

  .jcm-mt-8 {
    margin-top: 8px;
  }

  .jcm-mg-20 {
    margin: 20px 0;
  }

  .jcm-mt-20 {
    margin-top: 20px;
  }

  .jcm-mr-24 {
    margin-right: 24px;
  }

  .ant-collapse-header {
    padding: 0 !important;
    position: relative;

    .ant-collapse-arrow {
      right: 0 !important;
    }
  }

  .ant-collapse-item {
    padding: 20px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .ant-collapse-header-text {
    font-weight: 400;
    font-size: 16px;
    color: #212121;
  }

  .ant-form:nth-child():last-child {
    padding: 0 !important;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-input[disabled] {
    -webkit-text-fill-color: #212121;
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
    color: #212121;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-select-selector {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-input {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-select-selection-placeholder {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: rgba(#212121, 0.5);
  }

  .ant-input-affix-wrapper {
    background-color: #f3f3f3;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-input {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: #212121;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    border: none;
  }

  .ant-select-selection-item {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .jc-font-op-6 {
    opacity: .6;
  }
}