@import '@/style/mixin';

.AddressPC {
  height: inherit;

  .container_address {
    width: 350px;
    margin: auto;
    height: 100%;
    position: relative;
    margin-bottom: 220px;

    .addAddress {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #212121;
      color: #fff;
      font-family: 'FZLanTingHei-M-GBK';
    }

    .nullAdress {
      margin: auto;
      font-family: 'FZLanTingHeiS-R-GB';
      position: absolute;
      top: 40%;
      left: 40%;
      color: #212121;
    }
  }
  .haveAddress {
    margin-left: 100px;
    width: calc(100% - 100px);

    .addressBlocks {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .oneBlock {
        margin-right: 24px;
        margin-bottom: 24px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .topSelect {
      display: flex;
      justify-content: space-between;

      .left {
        text-decoration-line: underline;
        cursor: pointer;
      }

      .rightBtn {
        cursor: pointer;
        width: 103px;
        height: 50px;
        background-color: #212121;
        color: #fff;
        text-align: center;
        line-height: 50px;
      }
    }

    .informBlock {
      background-color: #f3f3f3;
      width: 336px;
      min-height: 222px;
      position: relative;
      font-family: 'FZLanTingHeiS-R-GB';

      .mh{
        min-height:20px;
      }
      .detailAddress {
        width: 300px;
        min-height: 47px;
        // overflow: hidden;
        word-break: break-all;
        margin-bottom: 10px;
      }

      .ant-radio-input:focus+.ant-radio-inner {
        box-shadow: none; //按钮点击去除投影效果
      }

      .close {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .w10 {
        width: 16px;
        height: 16px;
      }

      .edit {
        margin-left: 8px;
        text-decoration: underline;
      }

      .ab60 {
        margin-bottom: 10px;
      }

      // .default {
      //   position: absolute;
      //   bottom: 20px;
      // }
    }
  }
}


.addressModal {
  @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    border: none;
  }

  .ant-select-selection-item {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .w120 {
    width: 120px;
    margin-right: 8px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .dob .ant-form-item-control-input-content {
    display: flex;
  }

  .dob .ant-form-item-control-input-content {
    display: flex;

    .input-select {
      width: 131px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-input[disabled] {
    -webkit-text-fill-color: #212121;
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    letter-spacing: 0.466667px;
    font-family: 'Zona Pro';
    font-size: 13px;
    color: #212121;
  }

  .ant-form-item-label {
    label {
      color: rgba(33, 33, 33, 0.6);
      font-family: 'FZLanTingHeiS-R-GB';
      font-size: 13px;
    }
  }

  .ant-select-selector {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-input {
    border: none;
    background-color: #f3f3f3;
  }

  .ant-select-selection-placeholder {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: rgba(#212121, 0.5);
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
    color: rgba(33, 33, 33, 0.6);
  }

  .ant-input {
    font-family: 'FZLanTingHeiS-R-GB';
    font-size: 13px;
    color: #212121;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f3f3f3;
  }

  .newTitle {
    color: #212121;
    margin-bottom: 32px;
    margin-top: 16px;
  }

  .tips {
    color: rgba(33, 33, 33, 0.6);
    font-family: 'FZLanTingHeiS-R-GB';
    margin-bottom: 20px;

    span {
      color: #c1281b;
    }
  }

  .ant-modal {
    width: 496px !important;
  }

  .ant-modal-body {
    padding: 24px 60px;
    max-height: 594px;
    overflow-y: scroll
  }

  .addressform {
    // overflow-y: scroll;
    @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

    .input376 {
      width: 376px;
    }

    .sbumitBtn {
      width: 180px;
      height: 50px;
      font-family: 'FZLanTingHei-M-GBK';
      font-size: 14px;
      letter-spacing: 0.466667px;

      &:first-child {
        background-color: #fff;
        color: #212121;
        margin-right: 13px;
      }
    }
  }
}