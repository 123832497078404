.pc-fourSeat {
  height: auto;
  // margin-bottom: 20px;

  .leg5 {
    margin-right: 8px;
  }

  .slick-dots-bottom {
    bottom: -18px;
  }

  .title {
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .describe {
    margin-bottom: 15px;
  }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #e0e0e0;
    }

    button {
      background-color: #212121 !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;
    }

    .slick-active {
      width: 8px !important;
      background-color: #212121;
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    cursor: pointer;
    height: 30px;
  }

  .menu-left {
    left: 20px;

    &.external {
      left: -40px;
    }
  }

  .menu-right {
    right: 20px;

    &.external {
      right: -40px;
    }
  }

  .slick-slide {
    &:last-child {
      .media_block {
        margin-right: 0px;
      }
    }
  }
}

.fourCarou {
  position: relative;
  // .slick-track {
  //   width: 100% !important;
  // }
}

.place_left_four {
  width: 100%;
  display: flex;
  align-items: center;

  .fourCarou {
    width: 70%;
  }

  .four_title_left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
}

.place_centwe_four {
  width: 100%;

  .four_title_top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}