.pc-home-plp-m {
  padding: 16PX 0;

  .mobile-plp-top-ad {
    display: block;
    width: calc(100% - 32px);
    margin: 0px 16px 24px;

    img {
      width: 100%;
    }
  }

  .good-collection {
    margin-top: 16px;

    .collection-name {
      margin: 0;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      color: #212121;
    }

    .swiper-container {
      padding-top: 0;
    }

    .load-more {
      // width: fit-content;
      text-align: center;
      border-radius: 0;
      font-size: 13px;
      line-height: 1;
      font-weight: 400;
      padding: 10px;
      margin: 22px 16px 32px;
      color: #212121;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #ebebeb;
    }

  }

  .swiper-container {
    padding: 0 16PX;
    box-sizing: border-box;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 49.5%);
    grid-gap: 0 1%;

    .slick-slide {
      padding: 1px
    }
  }

  .no-data {
    height: 256px;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 256px;
    letter-spacing: 0.466667px;
    text-align: center;

    color: #212121;
  }
}