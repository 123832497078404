.jc-swiper-roat-new {

	.swiper-cntent {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		position: relative;
		flex: '0 0 auto';
		width: 100%;
		margin-right: 16px;

		.left {
			align-items: flex-start;
			text-align: left;
		}

		.right {
			align-items: flex-end;
			text-align: right;
		}

		.middle {
			align-items: center;
			text-align: center;
		}

		.fm {
			align-items: center;
			text-align: center;
			position: relative;
		}

		.locate-left {
			left: 0;
		}

		.locate-right {
			right: 0;
		}

		.swiper-left {
			height: 100%;
			position: absolute;
			// left: 0;
			display: flex;
			// align-items: center;
			justify-content: center;
			flex-direction: column;
			z-index: 99;
			padding: 0 70px;

			.layer_title {
				font-size: 40PX;
				line-height: 1.2;
				letter-spacing: 5px;
				color: #212121;
				word-wrap: break-word;
				font-weight: 600;
			}

			.layer_subtitle {
				font-size: 13PX;
				line-height: 20px;
				color: #212121;
				margin-top: 20px;
				word-wrap: break-word;
			}

			.layer_describe {
				font-size: 13PX;
				line-height: 20px;
				color: #212121;
				margin-top: 20px;
				word-wrap: break-word;
			}

			.layer_link_title {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.466667px;
				background-color: #FFFFFF;
				cursor: pointer;
				text-align: center;
				display: inline-block;
				position: relative;
				text-decoration: none;
				text-transform: uppercase;
				padding: 0;
				color: #212121 !important;
			}

			.w2b {
				margin: 25px 0 0;
			}

			.mp2b {
				margin: 25px 0 0 0;
			}

			.p2b {
				margin: 25px 0 0 0;
			}

			.b2b {
				margin: 25px 0 0 0;
			}

			.layer_link_title::after {
				content: '';
				background: #000;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: -4px;
				left: 50%;
				transform: translateX(-50%);
				transition: .1s;
				pointer-events: none;
			}

			.layer_link_title:hover::after {
				width: 0;
			}
		}

		.swiper-right {
			position: relative;
			cursor: pointer;


			a {
				display: block;
			}

			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.swiper-dots-right {
			position: absolute;
			bottom: 30px;
			transform: translate(0, -50%);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			flex-direction: row;
			z-index: 999;
		}

		.swiper-dots {
			position: absolute;
			bottom: 30px;
			right: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			flex-direction: row;
			z-index: 999;
			transform: translate(0, -50%);


		}

		.dots {
			cursor: pointer;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: #ACACAC;
		}

		.slick-active {
			background: #000;
		}
	}

	.swiper-wrapper {
		padding-left: 1px;
		padding-right: 1px;
	}
}