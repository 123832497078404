.mob-cart {
  width: 100%;
  color: #212121;
  .cart-header {
    padding: 24px 16px;

    &-text {
      &-1 {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #242424;
      }

      &-2,
      &-3,
      &-4 {
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #757575;
      }

      &-2 {
        margin-left: 8px;
      }

      &-4 {
        margin-left: 4px;
      }

      &-3 {
        margin-left: 4px;
        font-family: 'Zona Pro';
        position: relative;
        top: 1px;
      }
    }

  }


  .go-shopping {
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    border-bottom: 1px solid currentColor;
    cursor: pointer;
  }

  .cart-footer {
    padding: 24px 16px 80px;
  }

  .top94 {
    top: 94px;
  }

  .top64 {
    top: 64px;
  }


}

.cartDrawerMob {
  position: fixed;

  .ant-drawer-content-wrapper {
    height: 200px !important;
  }

  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 0 20px;
  }

  .btns {
    font-family: 'FZLanTingHeiS-R-GB';
    width: 160px;
    height: 50px;
    border: 1px solid rgba($color: #000000, $alpha: 0.4);

    &:first-child {
      background-color: #212121;
      color: #fff;
    }
  }
}