.mob-sixSeat {
  // margin: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 60px;
  position: relative;
  width: 100%;

  .good_card {
    margin-right: 8px;
  }

  .title {
    text-decoration: none;
    font-family: 'FZLanTingHei-M-GBK';
    font-size: 20px;
    color: #212121;
    margin-bottom: 20px;
    text-align: center;
  }

  .describe {
    text-align: center;
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 19px;
    color: #616161;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-list {
    margin: auto;
    box-sizing: border-box;

    .slick-track {
      margin: auto;
      box-sizing: content-box;

      .slick-slide {
        &:nth-child(3n) {
          .media_block_mob {
            margin-right: 0;
          }
        }
      }
    }
  }

  .swiper-menu {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 12px;
    height: 24px;
  }

  .menu-left {
    left: 20px;
  }

  .menu-right {
    right: 20px;
  }

  .sixSeat_block {
    width: fit-content;

    .media {
      width: calc(100vw / 3);
    }
  }

  .slick-dots {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #e0e0e0;
    }

    button {
      background-color: #212121 !important;
      width: 10px !important;
      height: 10px !important;
      border-radius: 50% !important;
    }

    .slick-active {
      width: 10px !important;
      background-color: #212121;
    }
  }

  .slick-dots-bottom {
    bottom: -20px;
  }
}