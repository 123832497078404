.checkout-address {
    .mb12{
        margin-bottom: 12px;
    }
    .reset-radio{
        .ant-radio-wrapper{
            height: 76px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .first-radio{
        padding-right: 42px;
        display: inline-block;
    }
    .input376 {
        width: 100%;
    }
    .edit-address{
        cursor: pointer;
    }
    .addrlist,
    .default-addr {
        width: 100%;
        background-color: #F3F3F3;

        p {
            margin-bottom: 6px;

            .default-tag {
                background-color: #212121;
                color: #fff;
                padding: 2px 8px;
                margin-left: 15px;
            }
        }
    }

    .default-addr {
        padding: 24px!important;
        .ant-form{
            width: 100%!important;
            margin: 10px 0 0!important;
        }
    }

    .addrlist {
        min-height: 114px;
        padding: 24px 24px 24px 16px;

        .ant-form {
            width: 103%;
            padding: 20px;
            background: #fff;
            margin: 20px 0 0 -25px;
        }
        .address{
            height: 76px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .region{
            margin: 0;
            line-height: 1;
        }
    }

    .select-other {
        padding-top: 8px;
        color: rgba(33, 33, 33, .6);
        text-align: center;
    }

    .w120 {
        width: calc((100% - 16px) / 3);
    }

    .sbumitBtn {
        display: block;
        margin: auto;
        width: 352px;
    }

    // 不要删除，调整  @pc/addressForm  编辑地址的取消和保存按钮的
    .btns {
        margin-top: 4px;
        margin-bottom: 4px!important;
    }
}