.input-select {
  width: 100px;
  position: relative;
  margin-right: 10px;

  &.date-select{
    background: #f3f3f3 !important;
    input{
      background: transparent !important;
      z-index:2;
    }
  }
  input {
    width: 100%;
  }

  .arrIcon {
    position: absolute;
    right: 10px;
    top: 4px;
  }

  .select-content {
    width: 100%;
    position: absolute;
    top: 39px;
    left: 0;
    max-height: 175px;
    overflow-y: scroll;
    border: 1px solid #707070;
    border-top: 0;
    background-color: #fff;
    z-index: 99;

    p {
      height: 35px;
      line-height: 35px;
      padding: 0;
      padding-left: 10px;
      box-sizing: border-box;
    }

    p:hover {
      background-color: #707070;
    }
  }
}