.mob-order-info {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  padding-top: 0;
  li {
    padding: 20px 0;
    border-bottom: 1px solid #f0f0f0;

    .product-img {
      width: 100px;
      height: 100px;
      margin-right: 16px;
    }

    .product-info {
      width: calc(100% - 116px);

      .sku {
        color: rgba(33, 33, 33, 0.4);
      }

      .swatch_image {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }
  }

  .total-info {
    p {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .line {
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
