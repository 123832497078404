.mob-wish-card-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    height: auto;
    max-height: none;
    // background-color: #fff;
    position: relative;
    text-align: left;

    .product-image {
        position: relative;
        max-width: 100px;
        max-height: 100px;
        align-self: flex-start;

        .image-tip {
            font-size: 11px;
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 5px;
            color: #212121;
            line-height: 18px;
            letter-spacing: .25px;
            font-style: normal;
            background: #fafafa;
            display: block;
            z-index: 1;
            pointer-events: none;
        }

        .image-link {
            position: relative;
            top: 0;
            bottom: 0;
            max-height: 100%;
            color: #000;
            text-decoration: underline;
            background-color: transparent;

            .img-tag-wrapper {
                img.img-tag {
                    border: 0;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                }
            }
        }
    }

    .product-info {
        // display: flex;
        // flex-direction: column;
        position: relative;

        .info-base {
            font-family: 'Zona Pro';
            margin: 4px 0px 14px 12px;
            display: flex;
            justify-content: space-between;

            .name-and-sku-wrapper {
                flex: 1;

                .info-name {
                    margin-right: 8px;
                    margin-bottom: 8px;

                    a.name-link {
                        font-size: 14px;
                        color: #242424;
                        font-weight: 600;
                        line-height: 22px;
                        letter-spacing: .15px;
                    }
                }

                .info-sku {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.2px;
                    color: #757575;
                }
            }

            .price-wrapper {
                flex-basis: 44px;
                font-family: 'Zona Pro';

                span {
                    display: block;
                }

                .price-label-and-value-com {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #212121;
                }

                .price-label {
                    margin-bottom: 4px;
                    font-family: 'FZLanTingHei-M-GBK';
                    @extend .price-label-and-value-com
                }

                .single-price {
                    @extend .price-label-and-value-com
                }

                .old-and-final-price {

                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;

                    .old-price {
                        margin-bottom: 4px;
                        color: #242424;
                        opacity: 0.4;
                        text-decoration: line-through;

                    }

                    .final-price {
                        color: #C1281B;
                    }
                }
            }
        }

        .attr-content {
            display: flex;
            align-items: baseline;
            // align-items: center;
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #242424;
            padding-left: 12px;

            .attr-label {
                min-width: 28px;
                // min-width: 8vw;
                margin-right: 12px;
            }

            .swatch-image-wrapper {
                display: flex;

                img {
                    margin: auto;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .color-name {
                word-break: break-word;
                margin-left: 4px;
            }

            .empty-seat {
                width: 82px;
            }

            .size-name {
                font-family: 'Zona Pro';
            }
        }

        .info-color {
            margin-bottom: 4px;
        }

        .product-add-to-bag {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 80px;

            .add-to-bag-btn {
                cursor: pointer;
                padding: 4px 6px;
                font-size: 12px;
                font-weight: 400;
                // line-height: 16px;
                letter-spacing: 1px;
                color: #242424;
                background: #fff;
                width: 100%;
                height: auto;
                box-sizing: border-box;
                border: 1px solid #d7d7d7;
                margin: 0;

                &:disabled,
                &:disabled:hover {
                    cursor: default;
                    background: #fafafa;
                    border: 1px solid #bdbdbd;
                    color: #bdbdbd
                }

                &:hover {
                    background: #f5f5f5
                }
            }
        }
    }


}