.good_card_mob {
  width: 100%;

  .good_media_mob {
    width: 100%;

    a {
      display: block;
    }
  }

  .card_bolck {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .goodName {
      margin-bottom: 9px;
      font-size: 13px;
      font-family: 'Zona''Pro-SemiBold', 'Zona Pro';
      font-weight: 600;
      color: #212121;
      line-height: 16px;
    }

    .amount {
      font-size: 12px;
      font-family: 'Zona''Pro-Regular', 'Zona Pro';
      font-weight: 400;
      line-height: 16px;
    }
  }

  .alileft {
    align-items: flex-start;
  }

  .alicenter {
    align-items: center;
    text-align: center;
    margin-right: 10px;
  }
}