.pc-order-info {
    width: 100%;
    background: #FAFAFA;
    padding: 24px 24px 20px;

    li {
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid #F0F0F0;

        .product-img {
            width: 100px;
            height: 100px;
            margin-right: 16px;
        }

        .product-info {
            width: calc(100% - 116px);

            .sku {
                color: rgba(33, 33, 33, .4);
            }

            .swatch_image {
                width: 16px;
                height: 16px;
                border-radius: 50%;

            }
        }

        &:first-child {
            padding-top: 0px;

        }
    }

    .fx-sb {
        span {
            margin-top: 8px;
        }
    }

    .total-info {
        p {
            margin-bottom: 22px;
            display: flex;
            justify-content: space-between;
            line-height: 1;
        }

        .line {
            border-bottom: 1px solid #F0F0F0;
        }

        .line+p {
            margin-top: 26px;
            margin-bottom: 6px;
        }
    }
}