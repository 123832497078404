.CountdownBanner {
  // margin-bottom: 60px;

  .countBox {
    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .logos {
      margin-bottom: 30px;
    }

    .time {
      font-weight: 600;
      font-size: 24px;
    }

    .timeDescribe {
      font-weight: 600;
    }

    .subtitle {
      margin-bottom: 15px;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.466667px;
    }

    .describe {
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      color: #212121 !important;
      font-size: 12px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

.closeModalCountP {
  .btn {
    width: 150px;
    height: 50px;
    background: #212121;
    color: #fff;
  }
}