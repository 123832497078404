.page-help-m {
  padding: 16px;

  .main {
    margin: 0 auto;

    .content {
      margin-top: 32px;
    }
  }
}