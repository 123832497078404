:root {
  --height: 'calc(100vh - 102px)';
  --top: '102px';
}

$Height: var(--height);
$Top: var(--top);

.country_drawer {
  width: 100%;
  height: 100%;

  .ant-drawer-inline {
    position: fixed !important;
  }

  .top_banner_countryDraw {
    position: relative;
    font-family: 'FZLanTingHeiS-R-GB';
    color: #212121;

    .backIcon {
      font-size: 12px;
      font-weight: 600;
    }

    .logo {
      position: absolute;
      right: 0;
      width: 17px;
      height: 22px;
    }

    .back {
      margin-left: 22px;
    }
  }

  .drawer_content {
    overflow: hidden;
    padding: 24px;
    font-family: 'FZLanTingHei-M-GBK';

    .category_title {
      margin-bottom: 10px;
      color: #212121;
    }

    .selectCountry {
      height: 266px;
    }

    .buy_btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      color: #fafafa;
    }

    .noSlect {
      background-color: #5f5f5f;
    }

    .selected {
      background-color: #212121;
      cursor: pointer;
    }

    .language {
      width: 100%;

      .ant-select {
        width: 100%;
      }

      .ant-select-borderless {
        width: 100%;
      }

      .ant-select-selection-item {
        font-size: 13px;
        color: #212121;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #e0e0e0;
      }
    }

    .searchWord {
      width: 100%;
      border: 0 none;
      background: transparent;
      border-bottom: 1px solid #212121;

      .ant-select-item {
        color: #212121;
        font-size: 13px;
      }

      .ant-select-item-option-content {
        color: #212121;
        font-size: 13px;
      }

      .searchIcon {
        width: 14px;
        height: 14px;
      }

      .ant-select-selection-item {
        font-size: 13px;
        color: #212121;
      }
    }
  }

  .ant-drawer-body {
    // overflow: hidden;
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    width: 314px !important;
    position: absolute;
    left: 0;
  }
}

.dropDrawer {
  min-width: 100%;
  height: 191px;
  box-shadow: none;

  .rc-virtual-list-scrollbar-thumb {
    background-color: #212121 !important;
  }

  .rc-virtual-list-scrollbar {
    width: 4px !important;
    background-color: #eeeeee;
  }

  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
  }
}

.bottom_drawer {
  width: 100% !important;

  .ant-drawer-content-wrapper {
    height: $Height !important;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
    top: $Top;
  }

  .ant-drawer-header {
    border-bottom: none;
  }

  .dropDrawer {
    width: 100% !important;
  }
}