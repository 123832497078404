// L1轮播
.mob-full-screen-carousel {
  position: relative;
  width: 100%;
  // margin-bottom: 40px;

  .jcm-task-video-play-btn {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    .jcm-task {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #fff;
      opacity: .2;
    }

    .iconfont {
      position: absolute;
      left: 35%;
      border-radius: 1px;
      font-size: 18px;
      color: #fff;
    }
  }

  .menu-left {
    left: 45px;
  }

  .menu-right {
    right: 45px;
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 31px;
    height: 93px;
  }

  .ant-carousel {
    width: 100%;

    .carousel-item {
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
    }

    .slick-dots {
      z-index: 9;
    }

    // .media_content .media {
    //   min-height: 306px;
    //   background: url('../../../../assets/mobImg/loading.gif') center center no-repeat;
    // }
  }

  .bottom {
    background-color: #212121;
    color: #fff;
    height: 291px;

    .text {
      margin: 10px 0 24px;
    }

    .btn {
      color: #fff;
      margin-right: 30px;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .swiperBanner {
    position: relative;
  }

  .lower_left_corner {
    bottom: 16%;
  }
}

.mob-bq-carousel-banner {
  .slick-dots-bottom {
    .slick-active {
      width: unset !important;
    }

    li:last-child {
      width: fit-content !important;
    }
  }

  .slick-dots {
    width: fit-content;
    bottom: 35px;
    text-align: center;
    margin: auto;

    >li {
      margin: 0;
      width: unset;
      height: 8px;
      margin-right: 12px;

      button {
        width: 0.1rem;
        height: 0.0972222rem;
        border-radius: 50%;
        background-color: #ACACAC;
        border: 1px solid #ACACAC;
        opacity: 1 !important;
      }

      &.slick-active {
        button {
          background-color: #000;
          border: 1px solid #000;
        }
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }
}