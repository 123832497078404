.pc-success {
  .success-title {
    margin: 40px 0 80px;
    text-align: center;
  }

  .success-container {
    .leftImg {
      width: 50%;

      img {
        width: 100%;
      }
    }

    .valet-button {
      display: flex;
      justify-content: center;

      a {
        display: block;
      }
    }

    .right {
      width: 40%;
      text-align: center;

      .iconfont {
        font-size: 60px;
        margin: auto;

      }

      .orderId {
        margin: 40px auto 40px;
      }

      ul {
        color: #5f5f5f;
        font-size: 14px;

        li {
          margin-bottom: 30px;
          line-height: 24px;
        }
      }

      a {
        cursor: pointer;
      }

      button {
        width: 180px;
        height: 50px;
      }
    }
  }
}

.select-pay-container {
  text-align: center;
  width: max-content;
  margin: auto;

  .pay-methods {

    ul li {
      width: 220px;
    }
  }

  .confiram {
    width: 300px;
    height: 50px;
  }
}