.pc-thirdBind {
    margin: auto;
    .notShow{
        width:0;
        height:0;
        opacity:0;
      }
    .pc-thirdBind-title {
        height: 27px;
        line-height: 27px;
    }

    .prossing {
        text-align: center;
        margin: 100px;
    }

    .container {
        width: 500px;
        margin: 0 auto 100px;

        .jcform {
            text-align: left;

            .dob .ant-form-item-control-input-content {
                .input-select {
                    width: 160px;
                }

                & :last-child {
                    margin-right: 0 !important;
                }
            }

            .sbumitBtn {
                width: 500px;
            }
        }
    }
    
    .tobind{
        color: #616161
    }
}