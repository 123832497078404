.tabs-page-pc {
  padding: 0 24px 0 20px;

  .tabs-container {
    height: 14px;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.466667px;

    color: #212121;

    .name {
      margin: 0 4px;
    }

    .align-c:nth-last-of-type(1) {
      .name {
        color: #616161;
      }
    }
  }

  .desc-container {
    .desc-title {
      height: 23px;

      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.466667px;
      color: #212121;
    }

    .desc-main {
      margin: 12px auto 0;
      padding-bottom: 10px;
      width: 960px;
      text-align: center;

      .desc {
        position: relative;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        letter-spacing: 0.466667px;
        color: #616161;

        &.active {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &::before {
            padding: 30px 0 0;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 45%);
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -14px;
          left: 0;
          width: 100%;
          margin: 0;
          text-align: center;
          padding: 6px 0 0;
          background-color: rgba($color: #fff, $alpha: 0);
          z-index: 1;
        }
      }

      .btn {
        margin-top: 10px;
        text-decoration: underline;
      }
    }
  }
}