.appointBoxPerson {
  height: 594px;

  .leftPic {
    width: 594px;
    height: 594px;
  }

  .right_content {
    padding: 0 24px 36px;
    position: relative;
    .mb16 {
      margin-bottom: 16px;
    }
    .fw600 {
      font-weight: 600;
    }
    .fw700 {
      font-weight: 700;
    }
    .color4242 {
      color: #424242;
    }
    .colorBD {
      color: #bdbdbd;
    }
    .price {
      font-family: 'Zona Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;

      letter-spacing: 0.466667px;

      color: #212121;

      &.gray {
        margin-right: 8px;
        text-decoration: line-through;
        color: rgba(33, 33, 33, 0.4);
      }

      &.final_price {
        color: rgba(193, 40, 27, 1);
      }
    }

    .provice {
      position: relative;
      .surname {
        position: absolute;
        left: 16px;
        top: 10px;
      }
    }

    .w384 {
      width: 384px;
    }
    .w184 {
      width: 184px;
    }
    .mr16 {
      margin-right: 16px;
    }

    .ant-input-affix-wrapper {
      padding: 3px 16px;
    }

    .bottom {
      position: absolute;
      bottom: 17px;
      right: 16px;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x {
    color: #212121;
  }
  .ant-form-item {
    margin-bottom: 10px;
    .ant-form-item-label > label::after {
      content: '';
    }
    .ant-form-item-label .ant-form-item-required::after {
      content: '*';
      display: inline-block !important;
      color: #c1281b;
      font-size: 0.22222rem;
    }
    .ant-row {
      flex-direction: column;
      .ant-form-item-control-input-content {
        .ant-checkbox-wrapper {
          width: fit-content !important;
        }
      }
      label {
        height: 30px;
        width: 100%;
        font-size: 13px;
        color: #757575;
      }
    }
  }
}
