.pc-resetPassword {
    margin: auto;

    .resteForm {
        width: 500px;
        margin: 0 auto 100px;

        button {
            width: 500px;
            height: 50px;
        }
    }

}