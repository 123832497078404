.MobPersonCenter {
  font-family: 'FZLanTingHeiS-R-GB';
  .notShow{
    width:0;
    height:0;
    opacity:0;
}
  .main {
    padding: 0 20px;
  }

  .mumberName {
    font-family: 'Zona Pro';
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .bor000 {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
  }

  .container {
    width: 100%;
  }
}