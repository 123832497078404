.share-card-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-body {
                padding: 0;
                font-family: 'FZLanTingHeiS-R-GB';
                // display: flex;

                // .share-card-left {
                //     width: 626px;
                //     height: 626px;

                //     img {
                //         width: 100%;
                //         object-fit: contain;
                //     }
                // }

                .share-card-top {
                    width: 100vw;
                    height: auto;
                    padding: 29px 24px 24px;

                    .right-title {

                        color: #212121;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.18px;
                        margin-bottom: 24px;
                    }

                    .form-wrapper {
                        .ant-form {
                            .have-bg-items {
                                margin-bottom: 26px;
                                background: #F6F6F6;
                                padding: 24px;

                                .ant-form-item {
                                    margin-bottom: 9px;
                                }

                                .plain-text-form-item {
                                    margin-bottom: 0px !important;
                                    padding-bottom: 9px;

                                    &:last-child {
                                        padding-bottom: 0px;
                                    }

                                    .ant-row {
                                        .ant-col {
                                            .ant-form-item-control-input {
                                                min-height: fit-content;

                                                .ant-form-item-control-input-content {
                                                    .plain-text {
                                                        font-size: 14px;
                                                    }

                                                    .top-plain-text {
                                                        font-size: 20px;
                                                        font-weight: 400;
                                                        // line-height: 36px;
                                                        line-height: 36px;
                                                        text-align: center;
                                                        letter-spacing: 0.18px;
                                                    }

                                                    label.ant-checkbox-wrapper {
                                                        font-size: 13px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .ant-form-item {
                                .ant-row {
                                    .ant-col {
                                        .ant-form-item-control-input {
                                            min-height: fit-content;

                                            .ant-form-item-control-input-content {

                                                label.ant-checkbox-wrapper {
                                                    font-size: 13px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0px;
                                }
                            }

                        }

                        button.generate-wish-btn {
                            font-family: 'FZLanTingHeiS-DB-GB';
                            padding: 18px;
                            font-size: 12.68px;
                            font-weight: 600;
                            line-height: 14px;
                            letter-spacing: 1px;
                            color: #fafafa;
                            background: #242424;
                            width: 100%;
                            height: 51px;
                            box-sizing: border-box;
                            border: 1px solid #242424;

                            &:hover {
                                color: #bdbdbd;
                            }
                        }
                    }
                }
            }
        }
    }
}

.share-card-drawer-second {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            .ant-drawer-body {
                padding: 0;
                font-family: 'FZLanTingHeiS-R-GB';

                .share-card-drawer-second-top {
                    width: 100vw;
                    height: auto;
                    padding: 29px 24px 24px;

                    .right-title {
                        word-break: break-word;
                        color: #212121;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 36px;
                        letter-spacing: 0.18px;
                    }

                    .link-and-copy-wrapper {
                        margin: 84px 0;
                        border: 1px solid #E0E0E0;
                        padding: 4px;
                        display: flex;
                        justify-content: space-between;

                        .link-box {
                            flex: 1;

                            font-family: 'Zona Pro';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12.68px;
                            line-height: 25px;
                            color: #242424;
                        }

                        .copy-icon-wrapper {
                            width: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .operate-btns {
                        display: flex;
                        flex-direction: column;

                        button.generate-wish-btn {
                            font-family: 'FZLanTingHeiS-DB-GB';
                            padding: 18px;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 1.25px;
                            color: #fafafa;
                            background: #242424;
                            width: 100%;
                            height: 51px;
                            box-sizing: border-box;
                            border: 1px solid #242424;

                            &:hover {
                                color: #bdbdbd;
                            }

                            &:not(:first-child) {
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.share-card-drawer-wish-card {
    display: flex;
    font-family: 'Zona Pro';

    .product-image {
        position: relative;
        max-width: 164px;
        max-height: 164px;
        align-self: flex-start;

        // .image-tip {
        //     font-size: 11px;
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     margin: 0;
        //     padding: 5px;
        //     color: #212121;
        //     line-height: 18px;
        //     letter-spacing: .25px;
        //     font-style: normal;
        //     background: #fafafa;
        //     display: block;
        //     z-index: 1;
        //     pointer-events: none;
        // }

        .image-link {
            background-color: transparent;

            .img-tag-wrapper {
                img.img-tag {
                    border: 0;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                }
            }
        }
    }

    .product-info {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        .info-name-and-price {
            font-family: Zona Pro, sans-serif;
            padding: 0 16px;
            margin-bottom: 16px;

            .info-name {
                font-size: 20px;
                color: #f1f1f1;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: .13px;
                margin-bottom: 4px;

                a.name-link {
                    text-decoration: none;
                    color: #212121;
                }
            }

            .info-price {
                font-size: 12.6659px;
                padding: 0;
                font-weight: 700;
                line-height: 20px;
            }

            .old-and-final-price {
                color: #000000;

                .old-price {
                    opacity: 0.4;
                    text-decoration: line-through;

                }

                .final-price {
                    margin-left: 8px;
                    color: #C1281B !important;
                }
            }
        }

        @mixin colorAndSizeCss {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            // letter-spacing: .5px;
            color: #242424;


            // .attr-label,
            // .color-name {
            //     color: #242424;

            //     font-weight: 700;
            //     font-size: 12px;
            //     line-height: 16px;
            // }

            .attr-label {
                font-weight: 700;
                margin-right: 8px;
            }
        }

        .attr-info {
            padding: 0 16px;
            margin-bottom: 8px;

            .attr-content {
                @include colorAndSizeCss;

                .attr-value {
                    margin-right: 4px;
                }

                .swatch-image-wrapper {

                    display: flex;

                    img {
                        margin: 0 auto;
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }


            }




        }


    }
}

.first-share-card-bottom {
    margin-bottom: 110px;
}

.second-share-card-bottom {
    margin-bottom: 84px;
}