.OrderPC {
  width: 100%;
  height: inherit;
  .haveOrder {
    margin-left: 100px;
    width: calc(100% - 100px);
    height: 100%;
    .noOrder {
      font-family: 'FZLanTingHeiS-R-GB';
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }
    .topTab {
      display: flex;
      justify-content: space-between;
      padding: 10px 60px 40px 60px;
      border-bottom: 1px solid #e5e5e5;
      li {
        color: #999999;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .selected {
        border-bottom: 1px solid #333;
        color: #333;
      }
    }
    .OrderBlockPC {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      font-family: 'FZLanTingHeiS-R-GB';
      padding: 24px;
      display: flex;
      justify-content: space-between;
      .mr16 {
        margin-right: 16px;
        width: 120px;
        height: 120px;
      }
      .rightInform {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        .mt21 {
          margin-bottom: 21px;
        }
        .textunder {
          text-decoration: underline;
          margin-bottom: 17px;
          cursor: pointer;
        }
        .blackBlock {
          cursor: pointer;
          width: 92px;
          height: 40px;
          background: #212121;
          text-align: center;
          line-height: 40px;
        }
        .account {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}
