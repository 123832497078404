.buy-tip.ant-collapse {
    margin-top: 24px;
    font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;

    .ant-collapse-item {
        margin-top: 8px;

        .ant-collapse-header {
            background-color: #fff;
            padding: 24px 16px !important;
            line-height: 24px;
            color: #212121;
            
            .ant-collapse-arrow {
                right: 0 !important;
            }

            .iconfont {
                font-size: 20px !important;
                right: 16px !important;
            }
        }

        .ant-collapse-content-box {
            padding: 0 !important;
        }

        .buy-tip-box {
            padding-top: 0;
            margin-top: 0;
        }

    }

    .buy-tip-box {
        padding-left: 20px;
        background-color: #fff;
        padding: 24px 16px;
        margin-top: 8px;

        .item {
            line-height: 22px;
            font-size: 14px;

            &:not(:first-child) {
                margin-top: 8px;
            }

            .chat-btn {
                text-decoration: underline;
            }
        }
    }
}