.modal {
  .ant-modal {
    // width: 1025px !important;
    width: 100% !important;
  }

  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .modal_box {
    display: flex;
    justify-content: center;

    .main {
      display: flex;
    }

    .ad-img {
      width: 594px;
      height: 594px;
    }

    .informTab {
      position: relative;
      width: 431px;
      height: 594px;

      padding: 0 24px;
      background-color: #fafafa;

      .selectCountryPC {
        height: 280px;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          padding: 0 10px;
        }

        .searchWordPC {
          margin-top: 30px;
          width: 100%;
          border: 0 none;
          background: transparent;
          border-bottom: 1px solid #212121;

          .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            color: #616161 !important;
            background-color: #fff;
          }

          .searchIcon {
            width: 14px;
            height: 14px;
          }

          .ant-select-selection-item {
            font-size: 13px;
            color: #212121;
          }

          .dropDrawerPC {
            min-width: 100% !important;
            width: 100% !important;
            height: 191px;
            box-shadow: none;
            left: 0 !important;
            top: 46px !important;

            .rc-virtual-list-scrollbar-thumb {
              background-color: #212121 !important;
            }

            .rc-virtual-list-scrollbar {
              width: 4px !important;
              background-color: #eeeeee;
              display: block !important;
            }

            ::-webkit-scrollbar-track {
              background-color: #eeeeee;
            }

            .ant-select-item {
              font-family: 'FZLanTingHeiS-R-GB';
              font-size: 13px;
              color: #212121;
            }

            .ant-select-item-option-selected {
              background-color: #fff;
              color: #666666;
            }
          }
        }
      }

      .sand {
        margin-top: 50px;
        // font-family: 'FZLanTingHei-M-GBK';
        color: #212121;
      }

      .closeIcon {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 18px;
        font-size: 12px;
        color: #212121;
        font-weight: 600;
      }

      .language {
        margin-top: 25px;
        // font-family: 'FZLanTingHei-M-GBK';
        font-weight: 600;
        color: #212121;
      }

      .selectLanguage {
        width: 100%;
        margin-top: 10px;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 56px;

          .ant-select-selection-item {
            line-height: 56px;
          }
        }

        .ant-select-single {
          width: 100%;
        }

        .ant-select-selection-item {
          font-size: 13px;
          color: #212121;
        }

        .ant-select-selection-placeholder {
          padding-top: 8px;

        }

        .ant-select-selector {
          border: 1px solid #e0e0e0;
        }
      }

      .buy_btnPC {
        width: 100%;
        height: 56px;
        line-height: 56px;
        margin-top: 32px;
        color: #fafafa;
      }

      .noSlect {
        background-color: #5f5f5f;
      }

      .selected {
        background-color: #212121;
        cursor: pointer;
      }
    }
  }
}

// ant-modal 10001
.ant-select-dropdown {
  z-index: 10002;
}