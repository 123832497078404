.AddressMob {
  width: 100%;
  margin-top: 24px;
  font-family: 'FZLanTingHei-M-GBK';
  .container_address {
    margin: auto;
    height: 100%;
    .addAddress {
      width: 100%;
      background-color: #212121;
    }
    .nullAdress {
      height: 136px;
      line-height: 177px;
      text-align: center;
    }
  }
  .haveAddress {
    width: 100%;
    .addressBlocks {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .oneBlock {
        margin-bottom: 24px;
      }
    }
    .topSelect {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    .rightBtn {
      cursor: pointer;
      width: 100%;
      height: 40px;
      background-color: #212121;
      color: #fff;
      text-align: center;
      line-height: 40px;
    }
    .informBlock {
      background-color: #f3f3f3;
      width: 336px;
      min-height: 222px;
      position: relative;
      font-family: 'FZLanTingHeiS-R-GB';
      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none; //按钮点击去除投影效果
      }
      .mh{
        min-height:22px;
      }
      .detailAddress{
        min-height:40px;
        margin-bottom: 10px;
      }
      .font {
        font-family: 'Zona Pro';
      }
      .close {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
      .w10 {
        width: 16px;
        height: 16px;
      }
      .edit {
        margin-left: 8px;
        text-decoration: underline;
      }
    }
  }
}

.drawerMob {
  width: 100% !important;
  font-family: 'FZLanTingHeiS-R-GB';
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .ant-drawer-header {
    border-bottom: none;
    padding: 22px 20px 0;
  }
  .ant-drawer-body {
    padding: 0 20px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .newTitle {
    margin-bottom: 22px;
    letter-spacing: 0.466667px;
  }
  .tips {
    color: rgba(33, 33, 33, 0.6);
    font-family: 'FZLanTingHeiS-R-GB';
    margin-bottom: 20px;
    span {
      color: #c1281b;
    }
  }
  .ant-modal {
    width: 496px !important;
  }
  .addressform {
    .input376 {
      width: 376px;
    }
    .sbumitBtn {
      width: 180px;
      height: 50px;
      font-family: 'FZLanTingHei-M-GBK';
      font-size: 14px;
      letter-spacing: 0.466667px;
      &:first-child {
        background-color: #fff;
        color: #212121;
        margin-right: 13px;
      }
    }
  }
}
