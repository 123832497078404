.nav-bar {
  .tabs-item {
    box-sizing: border-box;
    padding: 0 40px;
    width: 246px;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #fff;

    .label {
      font-family: 'FZLanTingHei-M-GBK';
      font-size: 13px;
      line-height: 60px;

      letter-spacing: 0.466667px;
      color: #444444;
      color: #212121;

      &.h2 {
        font-weight: 600;
      }

      &.block {
        display: block;
      }

      &.active {
        color: #D0A898;
      }
    }

    &.main:hover .label {
      color: #666;
    }
  }
}