.size-guide-popup {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0;


  .size-guide-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: FZLanTingHei-M-GBK;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.31px;
    letter-spacing: 0.25px;
    color: #212121;

    .iconfont {
      transform: rotate(180deg);
      font-size: 20px;
      margin-right: 8px;
      color: #797979;
    }
  }

  .desc {
    margin-top: 24px;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.87px;
    letter-spacing: 0.46666666865348816px;
    color: #797979;
  }

  .title {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: FZLanTingHei-M-GBK;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.31px;
    letter-spacing: 0.46666666865348816px;
    color: #000;
  }

  .size-group-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #212121;
    gap: 16px;

    .main {
      font-family: FZLanTingHeiS-R-GB;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.03px;
      letter-spacing: 0.46666666865348816px;
      text-align: left;

    }

    .spliter {
      width: 1px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .selector {
      font-family: FZLanTingHei-M-GBK;
      font-size: 13px;
      font-weight: 400;
      line-height: 14.88px;
      letter-spacing: 0.46666666865348816px;
      text-align: left;
    }

    .icon-xiajiantou {
      font-size: 12px;
    }
  }

  .size-value {
    flex: 1;
    overflow-y: auto;
    margin-top: 16px;

    .size-value-table {
      display: flex;

      .size-value-column {
        flex: 1;

        .size-value-item {
          border-bottom: 1px solid #EBEBEB;
          padding: 18px 0;
          font-family: Zona Pro, FZLanTingHei-M-GBK;
          font-size: 13px;
          font-weight: 400;
          line-height: 14.88px;
          letter-spacing: 0.05em;
          text-align: center;

          &:first-child {
            background: #F6F6F6;
          }
        }
      }
    }
  }
}

.size-group-title-popover {
  .adm-popover-arrow {
    display: none;
  }

  .adm-popover-inner {
    background: #848484;
    border-radius: 12px;

    .adm-popover-menu-list {
      min-width: 189px;

      .adm-popover-menu-item {
        padding-left: 0;

        .adm-popover-menu-item-text {
          padding: 11px 16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          border-color: #545458A6;
        }
      }
    }
  }
}