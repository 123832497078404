.share-card-modal {
    .ant-modal {
        .ant-modal-content {
            height: 626px;

            .ant-modal-body {
                font-family: 'FZLanTingHeiS-R-GB';
                display: flex;

                .share-card-left {
                    width: 626px;
                    height: 626px;

                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }

                .share-card-right {
                    width: 432px;
                    height: auto;
                    padding: 66px 24px 68px;

                    .right-title {

                        color: #212121;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.18px;
                    }

                    .form-wrapper {
                        .ant-form {
                            font-family: 'FZLanTingHeiS-R-GB';

                            .special-check-form {
                                font-size: 14px;

                                .share-check-box {
                                    align-items: center;

                                    .ant-checkbox {
                                        align-self: flex-start;
                                    }
                                }

                            }

                            .have-bg-items {
                                margin: 28px 0 26px;
                                background: #F6F6F6;
                                padding: 30px 24px 0px;

                                .plain-text-form-item {
                                    margin-bottom: 0px;
                                    padding-bottom: 20px;

                                    .ant-row {
                                        .ant-col {
                                            .ant-form-item-control-input {
                                                min-height: fit-content;

                                                .ant-form-item-control-input-content {
                                                    .plain-text {
                                                        font-weight: 400;
                                                        font-size: 14px;
                                                    }

                                                    .top-plain-text {
                                                        font-size: 24px;
                                                        font-weight: 400;
                                                        // line-height: 36px;
                                                        line-height: 25px;
                                                        text-align: center;
                                                        letter-spacing: 0.18px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        button.generate-wish-btn {
                            padding: 11px 24px;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 1.14;
                            letter-spacing: 1px;
                            color: #fafafa;
                            background: #242424;
                            width: 100%;
                            height: 56px;
                            border: 1px solid #242424;

                            &:hover {
                                color: #bdbdbd;
                            }
                        }
                    }
                }
            }
        }
    }
}

.share-card-modal-second {
    .ant-modal {
        .ant-modal-content {
            height: 626px;

            .ant-modal-body {
                font-family: 'FZLanTingHeiS-R-GB';
                display: flex;

                .share-card-modal-second-left {
                    width: 626px;
                    height: 626px;

                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }

                .share-card-modal-second-right {
                    width: 432px;
                    height: auto;
                    padding: 90px 24px 93px;

                    .right-title {
                        word-break: break-word;
                        color: #212121;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.18px;
                    }

                    .link-and-copy-wrapper {
                        margin: 105px 0;
                        border: 1px solid #E0E0E0;
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;

                        .link-box {
                            flex: 1;

                            font-family: 'Zona Pro';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #242424;
                        }

                        .copy-icon-wrapper {
                            width: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .operate-btns {
                        display: flex;
                        flex-direction: column;

                        button.generate-wish-btn {
                            padding: 11px 24px;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 1.14;
                            letter-spacing: 1px;
                            color: #fafafa;
                            background: #242424;
                            width: 100%;
                            height: 56px;
                            border: 1px solid #242424;

                            &:hover {
                                color: #bdbdbd;
                            }

                            &:not(:first-child) {
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}