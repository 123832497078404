.appointBox {
  //height: 640px;

  .leftPic {
    width: 594px;
    min-height: 626px;
  }

  .ant-modal-body {
    height: 626px;
    overflow: hidden;
    overflow-y: auto;
  }

  .goods_container {
    width: 100%;
    height: 100%;
  }

  .form_box {
    width: 100%;
    padding: 0 24px;
    position: relative;
    height: 93%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
  }

  .form_box::-webkit-scrollbar {
    width: 0.06944rem;
    height: 0.06944rem;
    background-color: white;
  }

  .form_box::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 1);
    -webkit-transition: all .2s;
    transition: all .2s;
    border-radius: 0.06944rem;
  }

  .goback {
    position: absolute;
    top: 0;
    left: 25px;
  }

  .right_content {
    position: relative;
    width: calc(100% - 594px);
    height: 100%;
    padding-top: 50px;
    box-sizing: border-box;

    .ant-form-item-label>label::after {
      content: '';
    }

    .ant-form-item-label .ant-form-item-required::after {
      content: '*';
      display: inline-block !important;
      color: #c1281b;
      font-size: 0.22222rem;
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-row {
        flex-direction: column;

        label {
          height: 30px;
          width: 100%;
          font-size: 13px;
          color: #757575;
        }
      }
    }

    .mb16 {
      margin-bottom: 16px;
    }

    .fw600 {
      font-weight: 600;
    }

    .fw700 {
      font-weight: 700;
    }

    .color4242 {
      color: #424242;
    }

    .colorBD {
      color: #bdbdbd;
    }

    .price {
      font-family: 'Zona Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;

      letter-spacing: 0.466667px;

      color: #212121;

      &.gray {
        margin-right: 8px;
        text-decoration: line-through;
        color: rgba(33, 33, 33, 0.4);
      }

      &.final_price {
        color: rgba(193, 40, 27, 1);
      }
    }

    .w384 {
      width: 384px;
    }

    .w184 {
      width: 184px;
    }

    .mr16 {
      margin-right: 16px;
    }

    .provice {
      position: relative;

      .surname {
        position: absolute;
        left: 16px;
        top: 10px;
      }
    }

    .ant-input-affix-wrapper {
      padding: 3px 16px;
    }

  }

  .bottom {
    position: absolute;
    bottom: 17px;
    right: 16px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    color: #212121;
  }
}

// .ant-modal-wrap {
//   overflow-y: hidden;
// }