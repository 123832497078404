@import '@/style/mixin';

.pc-ExclusiveServices {
  position: relative;
  margin-top: 26px;
  margin-bottom: 26px;
  width: 100%;
  height: 385px;

  .exclusiveServicesContain {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
  }

  .item {
    position: relative;
    width: 224px;
    height: 177px;
  }

  .tel {
    position: absolute;
    font-family: 'Zona Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.466667px;
    color: #FFFFFF;
    top: 140px;
    left: 90px;
    opacity: .7;
  }
}