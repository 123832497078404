@import '@/style/mixin';

.minicart-mobile {

  .empty_minCart {
    .empty_minCart_content {
      height: 80px;
      line-height: 80px;
      background: #fff;
    }
  }

  .minCart_bottom {
    margin-top: 32px;
    background: #333;
    color: #fff;
    height: 40px;
    line-height: 40px;
  }

  .hasgoods {
    .header {
      display: flex;
      align-items: center;
      padding: 16px;
      background-color: #424242;
      color: #fff;

      &-tip {
        flex: 1;
        text-align: left;
        margin-left: 4px;
        font-size: 14px;
        line-height: 24px;
      }

      .icon-addSuccess{
        font-size: 24px;
      }
      .icon-guanbi11{
        color: #fff;
      }
    }

    li {
      .product-img {
        width: 159px;
        height: 159px;
      }

      .product-info {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        line-height: 24px;
        color: #212121;
        
        .title-box {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .product-name {
            flex: 1;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }

          .close {
            width: 20px;
            font-size: 20px;
          }
        }

        .sku {
          color: rgba(33, 33, 33, .4);
        }

        .swatch_image {
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }

        .price {
          margin-top: 8px;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .payNowBtn-box {
      padding: 0 20px;

      .payNowBtn {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-family: 'FZLanTingHei-M-GBK';
        color: #FFFFFF;
        background: #212121;
      }
    }

    .look-cart {
      border: 1px solid #D7D7D7;

      font-size: 14px;
      line-height: 16px;
      padding: 20px 0;
      text-align: center;

      letter-spacing: 1px;
    }
  }
}