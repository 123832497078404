.tabs-page-shop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-family: 'FZLanTingHeiS-R-GB';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: 0.466667px;

  color: #212121;

  .tabs-container {
    height: 14px;

    .name {
      margin: 0 4px;
    }

    .align-c:nth-last-of-type(1) {
      .name {
        color: rgba(33, 33, 33, 0.8);
      }
    }
  }
}