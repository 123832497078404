.mob-thirdBind {
  margin: auto;
  padding: 0 20px;
  margin-top: 24px;
  .notShow{
    width:0;
    height:0;
    opacity:0;
  }
  .prossing {
    text-align: center;
  }
  .container {
    width: 100%;
    margin: auto;
    .title {
      margin-bottom: 16px;
    }
    .jcform {
      text-align: left;
      .dob .ant-form-item-control-input-content {
        .input-select {
          width: 160px;
        }
        & :last-child {
          margin-right: 0 !important;
        }
      }
      .sbumitBtn {
        width: 100%;
      }
    }
  }
}
