.mob-success {
  font-family: 'FZLanTingHeiS-R-GB';
  padding: 0 40px;

  .success-title {
    margin: 24px 0 20px;
    text-align: center;
  }

  .valet-btn {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    a {
      display: block;
      width: 45%;
    }
  }

  .success-container {
    .leftImg {
      width: 295px;
    }

    .right {
      width: 100%;
      text-align: center;
      margin-top: 24px;

      img {
        width: 40px;
        height: 40px;
        margin: auto;
      }

      .orderId {
        margin: 24px auto 20px;
      }

      ul {
        color: #5f5f5f;
        font-size: 14px;

        li {
          margin-bottom: 20px;
          line-height: 18px;
        }
      }

      a {
        cursor: pointer;
      }

      button {
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
      }
    }
  }
}