.CountdownBannerMob {
  // margin-bottom: 40px;

  .content {
    margin-top: 20px;

    .title {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .logo {
      width: 180px;
      height: 100px;
      margin-bottom: 20px;
    }

    .time {
      font-weight: 800;
      font-size: 26px;
      margin-bottom: 10px;
    }

    .timeDescribe {
      font-weight: 800;
      font-size: 12px;
    }

    .subtitle {
      color: #212121;
      margin-bottom: 20px;
      font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      padding: 0 20px;
    }

    .describe {
      font-family: 'FZLanTingHeiS-R-GB', 'Zona Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 20px;
      text-align: center;
      padding: 0 20px;
    }
  }
}
