@import '@/style/mixin';

.miniLogin {
  .mini-login-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 0;

    span {
      text-decoration: underline;
      text-underline-offset: 4px;
      font-size: 16px;
      cursor: pointer;
      font-weight: 600;
    }

    i {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .password-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .userInfo {
    width: 356px;
    padding: 24px;
    // font-family: 'FZLanTingHeiS-R-GB';
    text-align: left;
    overflow-y: scroll;
    @include scrollbar-color(#fff, rgba(0, 0, 0, 0.2));

    .ant-form-item {
      margin-bottom: 15px;
      vertical-align: middle;
      box-sizing: border-box;

      .ant-input {
        border: 1px solid #BDBDBD !important;
        background-color: #fff !important;
        box-sizing: border-box;
      }

      .msg-code-img {
        height: 40px;
        border: 1px solid #BDBDBD;
        border-left: none;
      }

      .ant-input-group-addon {
        padding: 0;
      }

      button {
        height: 40px;
      }
    }

    .register-btn {
      border-color: #212121;

      .regi_btn {
        font-family: 'FZLanTingHei-M-GBK';
        color: #212121;
      }
    }

    .register-btn:hover {
      border-color: #5F5F5F;
      background-color: #5F5F5F;

      .regi_btn {
        color: #fff;
      }
    }

    .tips {
      // font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      // font-weight: 600;
      color: #212121;
    }

    .share {
      color: #212121;
      // font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      // font-weight: 600;

      li {
        margin-top: 17px;
        line-height: 22px;
        height: 22px;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          margin-right: 8px;
          font-size: 20px;
          font-family: none;
          font-weight: bold;
        }

      }

      .shareItem {
        // font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
      }
    }

    .image-otp {
      .ant-input-group-addon {
        width: 100px !important;
      }
    }
  }

  .mini-login-box {
    height: 94vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7vh;

    .mini-login-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      span {
        text-decoration: underline;
        text-underline-offset: 4px;
        cursor: pointer;
      }
    }

  }

  // @media screen and (max-height: 800px) {
  //   .userInfo {
  //     max-height: calc(100vh - 100px);
  //   }
  // }

  // @media screen and (min-height: 800px) {
  //   .userInfo {
  //     max-height: calc(100vh - 100px);
  //   }
  // }
}