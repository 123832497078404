// 重置html
@import './style/reset.scss';
// flex封装
@import './style/flex.scss';
// 常用字号、颜色、margin、padding、按钮等
@import './style/global.scss';
// ant组件样式
@import './style/antd.scss';

.App {
  position: relative;
  letter-spacing: 0.466667px;

  .main {
    position: relative;
  }

  .app-container {
    min-height: 500px;
  }
}

#live800iconlink {
  display: none;
}

.pc-email-dialog {
  width: 798px;
  font-size: 12px;
  background-color: #ffffff;
  z-index: 100;
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 999;

  h4 {
    font-size: 24px;
  }

  .text-left {
    text-align: end;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .step1 {
    width: 450px;
    padding: 20px 25px;
    box-sizing: border-box;
    position: relative;
    text-align: left;

    .pc_email_subscript .eamil {
      width: 100%;
    }
  }

  .step2 {
    width: 798px;
    margin: 0 auto 64px;
    text-align: center;
    padding: 0 32px;
    box-sizing: border-box;

    .inputWidth {
      width: 234px;
    }
  }

  .step3 {
    width: 798px;
    text-align: center;
    padding: 0 32px 60px;
    box-sizing: border-box;
  }
}

.mob-email-dialog-box {
  width: 100%;

  .step1 {
    width: calc(100% - 68px);
    margin: 20px auto 0;
    background-color: #fff;
    padding-bottom: 20px;
    max-height: calc(100% - 150px);
    overflow-y: auto;

    img {
      width: 100%;
    }

    .step1-content {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
    }
  }

  .step2 {
    background-color: #fff;
    padding: 20px 20px 150px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
  }

  .step3 {
    width: 100vw;
    padding: 0 20px 100px;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;

    .step3_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.cookieAccept {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 40px;
  left: 32px;
  width: 760px;
  height: 80px;
  background: #212121;
  box-sizing: border-box;
  padding: 20px 24px;
  z-index: 100;

  .cookieAcceptText {
    width: 460px;
    height: 40px;
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.466667px;
    color: #ffffff;

    a {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .cookieAcceptButton {
    width: 214px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;

    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.466667px;
    color: #212121;
    text-align: center;
    cursor: pointer;
  }
}

.cookieAccept-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  width: 375px;
  height: 56px;
  background: #212121;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 10px 20px;
  z-index: 100;

  .cookieAcceptText {
    position: relative;
    top: -2px;
    width: 292px;
    height: 34px;
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* or 150% */

    letter-spacing: 0.466667px;
    color: #ffffff;
  }

  .cookieAcceptButton {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.ant-modal-mask-none {
  .ant-modal-mask {
    display: none;
  }

  // 去除重复shadow
  .ant-modal-content {
    box-shadow: none;
  }
}

.col21 {
  color: #212121 !important;
}

.jc-title-h1 {
  font-style: normal;
  font-size: 40px;
  line-height: 40px;
  color: #212121;
}

.jc-title-h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.466667px;
  color: #212121;
}

.jc-title-p {
  font-style: normal;
  font-weight: 400;
  color: #212121 !important;
  font-size: 14px;
  line-height: 20px;
}

.jcm-title-h1 {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.466667px;
  color: #212121;
}

.jcm-title-h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.466667px;
  color: #212121;
}

.jcm-title-p {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
}

.carl_banner {
  .jc-title-h1 {
    font-style: normal;
    font-size: 40px;
    color: #212121;
  }

  .jc-title-h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.466667px;
    color: #212121;
    opacity: .6;
  }

  .jc-title-p {
    font-style: normal;
    font-weight: 400;
    color: #212121 !important;
    font-size: 14px;
    line-height: 20px;
    opacity: .6;
  }
}

.carl_banner {
  .jcm-title-h1 {
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .jcm-title-h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.466667px;
    color: #212121;
    opacity: .6;
  }

  .jcm-title-p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    opacity: .6;
  }
}

.white_center {
  text-align: center;

  .jc-title-h1 {
    color: #fff !important;
    font-size: 40px;
    text-align: center;
    font-style: normal;
  }

  .jc-title-h2 {
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
  }

  .jc-title-p {
    font-style: normal;
    text-align: center;
    font-size: 14px;
    color: #fff !important;
    line-height: 20px;
  }
}

.white_center {
  text-align: center;

  .jcm-title-h1 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.466667px;
    color: #fff !important;
  }

  .jcm-title-h2 {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.466667px;
    color: #fff !important;
  }

  .jcm-title-p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.466667px;
    color: #fff !important;
  }
}

.jc-over-br {
  overflow-wrap: break-word;
}

.jc-flex-vertical {
  display: flex;
  align-items: center;
  justify-content: center
}

.jc-flex-full-btn {
  display: flex;
}

.white_line_hover_no,
.text_line_hover_no {
  width: max-content;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: 0.466667px;
  background: transparent !important;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  font-weight: 600;

  &::after {
    content: '';
    background: #fff;
    width: 100%;
    height: 1.5px;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    transition: .1s;
    pointer-events: none;
  }

  &:hover::after {
    width: 0;
  }
}

.white_line_hover_no {
  color: #fff !important;
  margin-top: -10px;

  &::after {
    background: #fff;
  }
}

.text_line_hover_no {
  color: #000 !important;
  margin-top: -10px;

  &::after {
    background: #000;
  }
}

.lower_left_corner {
  position: absolute;
  padding: 0 0.13889rem;
  color: #fff;
  width: auto;
  transform: none;
  text-align: left;
  top: auto;

  .jc-title-h1,
  .jc-title-h2,
  .jc-title-p,
  .jcm-title-h2,
  .jcm-title-h1,
  .jcm-title-p {
    color: #fff;
  }

}

.transparent_hover_white {
  width: 120px;
  height: 42px;
  color: #fff !important;
  background-color: transparent !important;
  border: 1px solid #fff;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  letter-spacing: 0.466667px;
  margin: auto;
  transition: all 0.2s ease-in;

  &:hover {
    border-color: #FFFFFF !important;
    color: #000 !important;
    background-color: #FFFFFF !important;
  }
}


.title_click_able_and_hover_text_grey {
  .jc-title-h1 {
    cursor: pointer;

    &:hover {
      color: #585757;
    }
  }
}

// 以下为outcss 中的css样式 ，改h1，h2,P为jcm-title-h1,jcm-title-h2,jcm-title-p
@media screen and (min-width:1024px) {
  .half50_.h1_left .jc-title-h1 {
    font-style: normal;
    font-size: 40px;
    line-height: 40px;
    color: #212121;
  }

  .half50_.h1_left .jc-title-h2 {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }

  .half50_.h1_left .jc-title-p {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }

  .explore .jc-title-h1 {
    font-size: 40px;
    line-height: 40px;
    color: #212121;
  }

  .explore .jc-title-h2 {
    font-size: 40px;
    line-height: 37px;
    color: #212121;
  }

  .explore .jc-title-p {
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }

  .lower_left_corner {
    left: 4%;
    bottom: 15%;
  }

  .transparent_hover_white {
    width: 240px;
  }
}


@media screen and (min-width:360px) and (max-width: 1023px) {
  .half50_.h1_left .jcm-title-h1 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .half50_.h1_left .jcm-title-h2 {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.466667px;
    color: #212121;
    text-align: center;
  }

  .text-left {
    text-align: left !important;
  }

  .half50_.h1_left .jcm-title-p {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .text-left {
    text-align: left !important;
  }

  .full_btn .jcm-title-h2 {
    text-align: left !important;
  }

  .full_btn .jcm-title-p {
    text-align: left !important;
  }

  .explore .jcm-title-h1 {
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }

  .explore .jcm-title-h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
  }

  .explore .jcm-title-p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #212121;
  }

  .lower_left_corner {
    left: 4%;
    bottom: 10%;
  }

  .transparent_hover_white {
    width: 120px;
  }
}