.mob-swiper-banner {
  width: 100%;
  height: auto;
  background: #fff;

  .swiper-container {
    height: auto;
    // height: calc(100vh -calc( 240vw / 3.75));
    position: relative;
  }

  .carousel-item {
    width: 100%;
    height: auto;
    // height: calc(100vh -calc( 240vw / 3.75));
    position: relative;
    color: #fff;

    .item-img {
      width: 100%;
      height: auto;
      min-height: calc(180vw / 3.75);
    }

    .item-title {
      min-width: calc(280vw / 3.75);
      min-height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .main-title {
        font-size: calc(24vw / 3.75);
        font-weight: 600;
        text-transform: unset;
        line-height: calc(22vw / 3.75);
        letter-spacing: calc(1vw / 3.75);
        margin: calc(24vw / 3.75) auto;
        color: inherit;
      }

      .sub-title {
        font-size: calc(14vw / 3.75);
        font-weight: bold;
        font-stretch: expanded;
        margin: calc(20vw / 3.75) 0;
        color: inherit;
      }

      .sub-title-ex {
        font-size: calc(12vw / 3.75);
      }
    }

    .series {
      font-size: calc(14vw / 3.75);
      text-align: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      position: relative;
      top: calc(40vw / 3.75);

      .btn {
        display: block;
        width: max-content;
        margin-bottom: 20px;
        font-size: calc(12vw / 3.75);
        color: #fff;
        border-bottom: 1px solid #fff;
      }
    }
  }

  .bottom {
    height: calc(178vw / 3.75);
    color: #fff;
    background: #212121;

    a {
      color: #fff;
      font-family: "Microsoft YaHei";
      // font-weight: bold;
    }

    a+a {
      margin-left: calc(14vw / 3.75);
    }

    .title {
      font-size: calc(20vw / 3.75);
    }

    .text {
      height: calc(36vw / 3.75);
      font-size: calc(13vw / 3.75);
      margin-top: calc(22vw / 3.75);
      letter-spacing: calc(1vw / 3.75);
    }

    .series {
      font-size: calc(14vw / 3.75);
      margin-top: calc(20vw / 3.75);
    }
  }

  .swiper-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(20vw / 3.75);
    height: calc(52vw / 3.75);
  }

  .menu-left {
    left: calc(15vw / 3.75);
  }

  .menu-right {
    right: calc(15vw / 3.75);
  }
}