.AppointSuccessM {
  .ant-drawer-mask{
    background: white;
  }
  
  .ant-drawer-content-wrapper {
    position: relative;
  }
  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .bottom {
    margin-bottom: 17px;
    .colorBD {
      color: #bdbdbd;
    }
  }
  .h341 {
    width: 132px;
    height: 132px;
    margin-right: 16px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .w301 {
    width: 100%;
    height: 56px;
  }
  .blackback {
    background-color: #212121;
    color: #fff;
  }
  .ant-drawer-body {
    padding: 0 20px;
  }
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }
  .ant-drawer-header {
    padding: 13px 0 0 0;
    border: none;
    .ant-drawer-close {
      color: #212121;
    }
  }
}
