.mob-checkout {
  margin: auto;
  font-family: 'FZLanTingHeiS-R-GB';
  // padding: 0 20px;
  color: #212121;

  .checkout-title {
    line-height: 1;
    margin: 24px 0 32px;
  }

  .banner {
    display: block;
  }

  .fx-col {
    padding: 0 20px;
  }

  .checkout-tip {
    font-size: 14px;
    font-family: 'FZLanTingHeiS-R-GB';
    margin: 16px auto 32px;
  }

  .invoice-header {
    height: 24px;
    display: flex;
    align-items: center;
  }

  .sub-title {
    display: inline-block;
    width: 106px;
  }

  .checkout-left {
    textarea {
      width: 100%;
      height: 80px;
      padding: 15px;
      background-color: #f3f3f3;
      border: none;
    }

    .textarea-form {
      margin-top: 20px;
      margin-bottom: 32px;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background: #F3F3F3;
    }

    .agree {
      margin: 20px 0;
      color: rgba(33, 33, 33, 0.6);
      align-items: flex-start;

      a {
        color: rgba(33, 33, 33, 1);
        text-decoration: underline;
        cursor: pointer;
      }

      .ant-checkbox+span {
        padding-right: 0;
        margin-top: 2px;
      }

      .agree-tip {
        overflow: hidden;
        transition: height .3s;
        transform: translateX(-32px);
        margin-top: 8px;
      }
    }

    .checkoutBtn {
      display: block;
      height: 50px;
      width: 100%;
      margin: auto;
    }
  }

  .checkout-right {
    margin-top: 13px;
    width: 100%;
  }

  .jcwx-first-radio {
    padding-right: 42pX;
  }
}