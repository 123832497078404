@import "./iconfont/iconfont.scss";


// 方正兰亭黑
@font-face {
  font-family: 'FZLanTing-Regular';
  src: url('../font/FZLanTingRegular.TTF') format('opentype');
}

@font-face {
  font-family: 'Zona Pro-SemiBold';
  src: url('../font/ZonaPro-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Zona Pro';
  src: url('../font/ZonaPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'FZLanTingHeiS-R-GB';
  src: url('../font/ZonaPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'FZLanTingHei-M-GBK';
  src: url('../font/ZonaPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Zona Pro Bold';
  src: url('../font/ZonaPro-Bold.otf') format('opentype');
}

// @font-face {
//     font-family: 'Font Icon';
//     src: url(../fonts/icons/font-icons.woff) format("woff");
//     font-style: normal;
//     font-weight: 400;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     font-display: swap
// }

// @font-face {
//     font-family: ProximaNova;
//     src: url(../fonts/proxima/light-webfont.woff) format("woff");
//     font-style: normal;
//     font-weight: 300;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     font-display: swap
// }

// @font-face {
//     font-family: ProximaNova;
//     src: url(../fonts/proxima/reg-webfont.woff) format("woff");
//     font-style: normal;
//     font-weight: 500;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     font-display: swap
// }

// @font-face {
//     font-family: ProximaNova;
//     src: url(../fonts/proxima/sbold-webfont.woff) format("woff");
//     font-style: normal;
//     font-weight: 600;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     font-display: swap
// }

// @font-face {
//     font-family: Fzltxihjw;
//     src: url(fonts/proxima/fzltxihjw.eot);
//     src: url(../fonts/proxima/fzltxihjw.eot) format('embedded-opentype'), url(../fonts/proxima/fzltxihjw.ttf) format('truetype'), url(../fonts/proxima/fzltxihjw.woff) format('woff'), url(../fonts/proxima/fzltxihjw.svg) format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'zonapro-regular';
//     src: url('../fonts/zonapro/zonapro-regular-webfont.woff2') format('woff2'), url('../fonts/zonapro/zonapro-regular-webfont.woff2') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'zonapro-semibold';
//     src: url('../fonts/zonapro/zonapro-semibold-webfont.woff2') format('woff2'), url('../fonts/zonapro/zonapro-semibold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap
// }