.AppointSuccessP {
  height: 594px;
  .container {
    width: fit-content;
    margin: auto;
  }
  .w367 {
    height: 594px;
    width: 367px;
    background: #f5f5f5;
    .h341 {
      height: 341px;
    }
  }

  .mb16 {
    margin-bottom: 16px;
  }
  .w301 {
    width: 301px;
    height: 56px;
  }
  .blackback {
    background-color: #212121;
    color: #fff;
  }
  .ant-modal-body {
    padding: 0;
    padding-top: 40px;
    height: 594px;
  }
  .ant-modal-close-x {
    color: #212121;
  }
  .main {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;

    .container {
      flex: 1;
      overflow: auto;
      margin-bottom: 10px;
      
      &::-webkit-scrollbar {
        width: 0.06944rem;
        height: 0.06944rem;
        background-color: white;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-transition: all .2s;
        transition: all .2s;
        border-radius: 0.06944rem;
      }
    }
  }
}
