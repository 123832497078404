.product-family-mobile {
  
  &-title {
    margin-bottom: 20px;
    font-family: FZLanTingHei-M-GBK;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.88px;
    letter-spacing: 0.46666666865348816px;
    text-align: left;
  }

  .swiper-slide {

    .swiperBanner {
      margin-right: 4px;

      .swiper-item {
        // width: 100px;
        // height: 100px;
        display: block;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .swiper-desc {
    padding-top: 4px;

    .info {
      text-align: left;

      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1.25px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .price-box {
        justify-content: flex-start;
        margin-top: 0;

        .price {
          margin-top: 4px;
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.466667px;
          color: #212121;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }
  }

  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;

  }
}