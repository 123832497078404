.ad-box {
  overflow: hidden;
  position: relative;
  margin-bottom: 6px;
  width: 100%;
  grid-column-start: span 2;
  min-height: 30vw;//防止换行广告没有撑开

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 18px;
    max-width: 94%;

    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    letter-spacing: 0.466667px;

    color: #212121;
    background: #F3F3F3;

    &:hover {
      color: #fff;
      background: #5F5F5F;
    }
  }
}

.ad-box-m {
  overflow: hidden;
  position: relative;
  margin-top: 24px;
  width: 100%;
  height: 226px;
  grid-column-start: span 2;
  background: #D9D9D9;



  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 13px;
    width: 94%;

    text-align: center;

    background: #F3F3F3;

    .text {
      line-height: 15px;
      font-size: 13px;
      color: #212121;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.466667px;
    }

    &:active {
      background: #5F5F5F;

      .text {
        color: #fff;
      }
    }
  }
}