.appointPersonM {
  // overflow-y: scroll;

  .ant-drawer-mask {
    background: white;
  }

  .label {
    font-size: 13px;
    color: #757575;
    font-weight: bold;
    height: 30px;
  }

  #is_sign_help {
    padding-left: 30px;
  }

  .proviceS {
    width: 100%;
    height: 40px;
    border: 1px solid #707070;
    padding: 0 16px;
  }

  .ant-drawer-content-wrapper {
    position: relative;
  }

  .privacy {
    font-size: 13px;
    color: rgba(33, 33, 33, 0.6);
  }

  .potop {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .bottom {
    margin-bottom: 17px;

    .colorBD {
      color: #bdbdbd;
    }
  }

  .h341 {
    width: 132px;
    height: 132px;
    margin-right: 16px;
  }

  .color4242 {
    color: #424242;
  }

  .mr16 {
    margin-right: 16px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .w254 {
    width: 100%;
    height: 56px;
    background-color: #212121;
    color: #fff;
  }

  .name {
    position: relative;

    .surname {
      position: absolute;
      top: 12px;
      left: 16px;
      color: #757575;
    }
  }

  .w290 {
    width: 290px;

    .ant-form-item-control-input-content {
      position: relative;
    }
  }

  .ant-drawer-body {
    padding: 0 20px;
  }

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-header {
    padding: 13px 0 12px 0;
    border: none;

    .ant-drawer-close {
      color: #212121;
    }
  }

  .ant-input {
    &::placeholder {
      color: #757575 !important;
      font-size: 13px;
    }
  }

  .ant-select-selection-placeholder {
    color: #757575 !important;
    font-size: 13px;
  }
}