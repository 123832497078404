@import '@/style/mixin';

.wish-item-wrapper {
    display: grid;
    grid-template-columns: 225px 1fr;
    height: auto;
    max-height: none;
    background-color: #fff;
    position: relative;
    text-align: left;

    .product-image {
        position: relative;
        max-width: 225px;
        min-height: 225px;
        align-self: flex-start;

        .image-tip {
            z-index: 11;
            position: absolute;
            top: 0;
            left: 0;
            background: #FAFAFA;
            padding: 5px 8px;
            font-family: 'FZLanTingHeiS-R-GB';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #212121;
        }

        .image-link {
            position: relative;
            top: 0;
            bottom: 0;
            max-height: 100%;
            color: #000;
            text-decoration: underline;
            background-color: transparent;

            .img-tag-wrapper {
                img.img-tag {
                    border: 0;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                }
            }
        }
    }

    .product-info {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        .icon-wrapper {
            position: absolute;
            top: 0px;

            .iconfont {
                cursor: pointer;
                font-size: 24px;
            }
        }

        .share-btn {
            right: 40px;
        }

        .wish-btn {
            right: 12px;
        }

        .info-name-and-price {
            font-family: "Zona Pro", sans-serif;
            padding: 0 16px;
            margin-bottom: 16px;

            .info-name {
                font-size: 20px;
                color: #f1f1f1;
                font-weight: 400;
                line-height: 1.1;
                letter-spacing: .15px;
                margin-bottom: 4px;

                a.name-link {
                    word-break: break-word;
                    text-decoration: none;
                    color: #212121;
                }
            }

            .info-price {
                font-size: 14.04px;
                padding: 0;
                font-weight: 300;
                line-height: 1.57;
            }

            .old-and-final-price {
                color: #212121;

                .old-price {
                    opacity: 0.4;
                    text-decoration: line-through;

                }

                .final-price {
                    margin-left: 8px;
                    color: #C1281B !important;
                }
            }
        }

        @mixin colorAndSizeCss {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.33;
            letter-spacing: .5px;
            color: #212121;
            margin-bottom: 8px;

            .attr-label {
                font-weight: 400;
                margin-right: 8px;
            }

            .wish-icon-arrow {
                position: relative;
                width: 8px;
                height: 8px;
                top: -3px;

                &::before {
                    font-size: 6px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .attr-info {
            padding: 0 16px;

            .attr-content {
                @include colorAndSizeCss;

                .attr-value {
                    margin-right: 4px;
                }

                .attr-value-size {
                    color: #242424;
                    font-family: "FZLanTingHeiS-R-GB";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }

                .swatch-image-wrapper {
                    display: flex;

                    img {
                        margin: 0 auto;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }

                .color-name {
                    color: #242424;
                    font-family: 'FZLanTingHeiS-R-GB';
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .attr-value-wrapper {
                box-sizing: border-box;
                position: absolute;
                z-index: 1;
                left: 0;
                bottom: 40px;
                width: 100%;
                margin: 0;
                padding-left: 16px;
                padding-right: 8px;
                // padding: 16px 8px 16px 16px;
                // max-height: 105px;
                border: 1px solid #d7d7d7;
                border-bottom: none;
                background: #fff;
                overflow-y: auto;
                transition: all .3s ease-in;
                @include scrollbarSmall;

                .attr-list {
                    height: 100%;

                    li {
                        height: auto;
                        width: 100%;
                        margin: 0 0 16px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .size-item {
                            justify-content: space-between;
                        }

                        .attr-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            text-align: left;
                            font-size: 12px;
                            color: #5f5f5f;
                            background-color: transparent;
                            border: 0;
                            padding: 0px;
                            line-height: normal;
                            letter-spacing: .25px;
                            cursor: pointer;

                            .item-value {
                                margin-right: 8px;
                                min-width: 50px;
                            }

                            .item-value-image-wrapper {
                                display: flex;
                                margin-right: 8px;

                                img.swatch-image {
                                    margin: 0 auto;
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                }
                            }

                            .item-msg {
                                font-family: 'FZLanTingHeiS-R-GB';
                                font-size: 12px;
                                line-height: 18px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }



                        .attr-item__selected {
                            color: #212121;
                            font-weight: 700;
                        }

                        .attr-item__unselected {
                            background-color: #f5f5f5;
                        }
                    }

                }
            }

            .show-attr-wrapper {
                max-height: 105px;
                padding-top: 16px;
                padding-bottom: 16px;
                // transition: all 1s ease-in;
            }

            .hide-attr-wrapper {
                // display: none;
                max-height: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                border-top-color: transparent;
                // border-top: 1px solid transparent;
                // transition: all 1s ease-in;
            }


        }

        .product-add-to-bag {
            margin-top: auto;

            .add-to-bag-btn {
                cursor: pointer;
                padding: 11px 24px;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.14;
                letter-spacing: 1px;
                color: #242424;
                background: #fff;
                width: 100%;
                border: 1px solid #d7d7d7;
                margin: 0;

                &:disabled,
                &:disabled:hover {
                    cursor: default;
                    background: #fafafa;
                    border: 1px solid #bdbdbd;
                    color: #bdbdbd
                }

                &:hover {
                    background: #f5f5f5
                }
            }
        }
    }
}

.tooltip-common {
    top: 26px !important;

    .ant-tooltip-content {
        // padding: 0px;

        .ant-tooltip-arrow {
            display: none;
        }

        .ant-tooltip-inner {
            min-width: unset;
            min-height: unset;
            padding: 0px;
            color: #000000;
            width: fit-content;
            padding: 4px 7px;
            // background: #FFFFFF;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }
    }
}

// .share-tooltip {
//     top: 290px !important;
// }