@import '@/style/mixin';

.cartDialog {
  .ant-modal-body {
    padding: 0;
  }
}

.cart-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .cart-tip {
    font-family: 'FZLanTingHeiS-R-GB', 'ZonaPro', sans-serif;
  }

  .cart-swiper {
    width: 594px;
    box-sizing: border-box;
  }

  .info-container {
    text-align: left;
    height: 626px;
    width: 408px;
    box-sizing: border-box;
    margin-left: 24px;
    padding: 64px 0px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-main {
      overflow: auto;
      margin-right: 10px;
      padding-right: 14px;
      width: calc(100% - 10px);
      @include scrollbarSmall;
    }

    .info {
      .title {
        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.18px;
        color: #212121;
        word-break: break-word;
        overflow: hidden;
      }

      .desc {
        margin-top: 8px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.25px;

        color: #424242;
      }

      .product-tag {
        position: absolute;
        top: 0;
        left: 0;
        background: #e0e0e0;
        padding: 8px;
        text-align: center;
        // min-width: 80px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #000;
      }

      .price-box {
        margin-top: 8px;

        .price {
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #242424;

          &.gray {
            margin-right: 8px;
            text-decoration: line-through;
            color: rgba(33, 33, 33, 0.4);
          }

          &.final_price {
            color: rgba(193, 40, 27, 1);
          }
        }
      }
    }

    .color-box {
      margin-top: 36px;

      .color-title {
        display: flex;
        align-items: center;
        height: 15px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        letter-spacing: 0.466667px;

        color: rgba(33, 33, 33, 0.6);

        .title {
          color: #212121;
        }
      }

      .color-list {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-top: 8px;
          box-sizing: border-box;
          margin-right: 8px;
          width: calc((100% - 32px) / 5);
          height: 74px;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &:nth-of-type(5n) {
            margin-right: 0;
          }

          &.active {
            border: 1px solid #212121;
          }
        }
      }
    }

    .size-box {
      margin-top: 32px;
      margin-bottom: 20px;

      .size-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.25px;
        color: #424242;

        .title {
          color: #212121;
        }
      }

      .size-list {
        display: grid;
        grid-template-columns: repeat(7, 51px);
        gap: 4px;
        margin-top: 8px;

        .item {
          box-sizing: border-box;
          height: 51px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          font-family: 'Zona Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 51px;
          text-align: center;
          letter-spacing: 0.466667px;
          color: #212121;

          &:hover {
            color: #5f5f5f;
          }

          &.gray {
            background: #f5f5f5;
            border: 1px solid rgba(0, 0, 0, 0.2);
            color: rgba(33, 33, 33, 0.6);
          }

          &.active {
            background: #fff;
            border: 1px solid #212121;
            font-weight: 600;
          }
        }
      }

      .size-balance {
        margin-top: 8px;
        height: 15px;
        font-family: 'FZLanTingHeiS-R-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.466667px;
        color: rgba(33, 33, 33, 0.8);
      }
    }

    .delivery-date {
      margin-bottom: 5px;
      height: 15px;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.466667px;
      color: rgba(33, 33, 33, 0.8);
    }

    .cart-section {
      position: relative;
      margin-right: 10px;
      padding-right: 14px;
      padding-top: 15px;

      .cart-tip {
        margin: 0 0 8px;
        font-size: 13px;
      }
    }

    .cart-box {
      position: relative;
    }

    .size-balance-tips {
      width: 100%;
      height: 15px;
      margin-top: 10px;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.466667px;
      color: rgba(33, 33, 33, 0.8);
      background-color: #fff;
    }

    .disbtn {
      pointer-events: none;
      opacity: .5;
    }

    .cart-add {
      height: 56px;
      background: #5f5f5f;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;

      &.active {
        background: #212121;
      }

      &.arrival {
        border: 1px solid #E0E0E0;
        background-color: #fff;
        color: #212121;
        cursor: pointer;
      }

      &.is-soldout {
        background-color: #fafafa;
        border: 1px solid #e3e3e3;
        color: #212121;
      }
    }

    .subscribe {
      margin-top: 16px;
      width: 100%;
      height: 56px;
      background: #212121;
      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;

      color: #fff;
    }

    .share-box {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .more {
      margin-top: 16px;
      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.466667px;
      text-decoration-line: underline;
      color: #212121;
      text-decoration: underline;
    }
  }
}