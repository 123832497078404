.share-list-modal {
    .ant-modal {
        .ant-modal-content {
            height: 626px;

            .ant-modal-body {
                font-family: 'FZLanTingHeiS-R-GB';
                display: flex;

                .share-list-left {
                    box-sizing: border-box;
                    width: 591px;
                    height: 626px;
                    padding: 68px 24px 0px;
                    background: #F5F5F5;

                    .left-title {
                        color: #212121;
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 32px;
                        margin-bottom: 32px;
                    }

                    .link-operate-area {
                        box-sizing: border-box;
                        height: 135px;
                        background: #ffffff;
                        padding: 16px 16px 9px;

                        .link-title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #757575;
                        }

                        .link-and-copy-wrapper {
                            box-sizing: border-box;
                            border: 1px solid #E0E0E0;
                            margin: 9px 0px;
                            padding: 6px 10px 6px 18px;
                            display: flex;
                            justify-content: space-between;

                            .link-box {
                                flex: 1;
                                font-family: 'Zona Pro';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: #242424;
                            }

                            .copy-icon-wrapper {
                                width: 32px;
                                height: 22px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .operate-icons {
                            display: flex;

                            span.icon-item {
                                cursor: pointer;
                                width: 32px;
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 16px;

                                i.iconfont {
                                    width: 100%;
                                    height: 100%;
                                    font-size: 24px;
                                }

                                a {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    i.iconfont {
                                        width: 100%;
                                        height: 100%;
                                        font-size: 24px;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0px;
                                }
                            }
                        }
                    }
                }

                .share-list-right {
                    box-sizing: border-box;
                    width: 435px;
                    height: 626px;
                    padding: 68px 24px 70px;

                    .right-title {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 24px;
                        color: #242424;
                    }

                    .form-wrapper {
                        margin-top: 30px;

                        .ant-form {
                            font-family: 'FZLanTingHeiS-R-GB';

                            .ant-form-item {
                                .ant-row {
                                    .ant-col {
                                        .ant-form-item-control-input {
                                            min-height: fit-content;

                                            .ant-form-item-control-input-content {
                                                .plain-text {
                                                    font-weight: 400;
                                                    font-size: 14px;
                                                }

                                                .top-plain-text {
                                                    font-size: 24px;
                                                    font-weight: 400;
                                                    // line-height: 36px;
                                                    line-height: 25px;
                                                    text-align: center;
                                                    letter-spacing: 0.18px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .form-item-btn {
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }


                        }


                    }
                }
            }
        }
    }
}

.share-list-modal-second {
    .ant-modal {
        .ant-modal-content {
            height: 626px;

            .ant-modal-body {
                width: 100%;
                font-family: 'FZLanTingHeiS-R-GB';
                display: flex;

                .share-list-content {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 139px 242px 123px;

                    .share-list-title {
                        text-align: center;
                        color: #212121;
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 32px;
                    }

                    .link-and-copy-wrapper-second {
                        box-sizing: border-box;
                        margin: 86px 0px 70px;
                        border: 1px solid #E0E0E0;
                        padding: 12px 10px 12px 16px;
                        display: flex;
                        justify-content: space-between;

                        .link-box {
                            flex: 1;

                            font-family: 'Zona Pro';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #242424;
                        }

                        .copy-icon-wrapper {
                            width: 32px;
                            height: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

.wish-list-operate-btns {
    font-family: 'FZLanTingHeiS-DB-GB';
    display: flex;
    flex-direction: column;

    button.wish-btn {
        padding: 20px auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1px;
        width: 100%;
        height: 56px;
    }

    button.generate-wish-btn {

        color: #fafafa;
        background: #242424;

        border: 1px solid #242424;

        &:hover {
            color: #bdbdbd;
        }

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    button.cancel-wish-btn {
        margin-top: 16px;
        color: #242424;
        background: #ffffff;

        border: 1px solid #D7D7D7;
    }
}

.weixin-overlay-class,
.douyin-overlay-class {

    .ant-popover-content {
        .ant-popover-inner {
            .ant-popover-inner-content {
                width: 200px;
                height: 236px;
                box-sizing: border-box;
                position: relative;
                padding: 10px 28px;

                span.iconfont {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                .weixin-template,
                .douyin-template {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-family: 'FZLanTingHeiS-R-GB';

                    font-weight: 400;


                    .pop-title {
                        line-height: 24px;
                        font-size: 14px;
                        color: #212121;
                        margin-bottom: 10px;
                    }

                    .pop-image {
                        display: block;
                        width: 126px;
                        height: 126px;
                    }

                    .pop-desc {
                        margin-top: 16px;
                        text-align: center;
                        color: #242424;
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.weixin-overlay-class {
    left: 20px !important;

    .ant-popover-content {
        .ant-popover-arrow {
            left: 40% !important;
        }
    }
}

.douyin-overlay-class {
    left: 20px !important;

    .ant-popover-content {
        .ant-popover-arrow {
            right: 32% !important;
        }
    }
}