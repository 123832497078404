/**
  自定义主题解决不了的样式，写在这个文件里
*/
.ant-btn-primary {
  box-shadow: none;
  text-shadow: none;
}

// checkbox样式一，默认样式
.ant-checkbox .ant-checkbox-inner {
  background-color: rgba(238, 238, 238, 1);
  border-color: rgba(238, 238, 238, 1);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid rgba(51, 51, 51, 1);
  border-top: 0;
  border-left: 0;
  top: 42%;
}


.ant-checkbox-wrapper {
  align-items: center;
}

.ant-checkbox+span {
  transform: translateY(3px);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #eee;
}

// .ant-checkbox-checked .ant-checkbox-inner::after {
//   display: none;
// }
// .ant-checkbox .ant-checkbox-inner {
//   border-color: #212121;
// }
// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: #fff;
//   border-radius: 2px;
//   overflow: hidden;
// }
// .ant-checkbox-checked .ant-checkbox-inner::before {
//   content: "";
//   width: 70%;
//   height: 70%;
//   position: absolute;
//   background-color: #212121;
//   left: 15%;
//   top: 15%;
// }
.ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
  visibility: hidden;
}

.jcform {
  .ant-form-item {
    margin-bottom: 20px;

    .ant-input {
      height: 40px;
    }
  }

  .ant-select-selector,
  .ant-input {
    background: #f3f3f3 !important;
    border: none !important;
  }

  .ant-input-group-addon {
    padding: 0;
    border: none;
    background: none;
  }

  .ant-form-item-label {
    height: 15px;
    line-height: 15px;
    margin-bottom: 8px;
    padding: 0;
  }

  .ant-radio-inner {
    border-color: #212121;
  }

  .ant-form-item-label>label,
  .ant-form-item .ant-select-selection-placeholder {
    color: rgba(33, 33, 33, 0.6);
    font-size: 13px;
    letter-spacing: 0.47px;
  }

  .ant-form-item .ant-form-item-control-input-content {
    & ::-webkit-input-placeholder {
      color: rgba(33, 33, 33, 0.6);
      font-size: 13px;
      letter-spacing: 0.47px;
    }
  }

  .ant-form-item-explain-error {
    height: 14px;
    line-height: 14px;
    margin: 8px 0 20px;
  }
}

// 重置单选框样式
.ant-radio-wrapper {
  align-items: center; // 按钮和文字居中

  .ant-radio-input:focus+.ant-radio-inner {
    box-shadow: none; //按钮点击去除投影效果
  }

  .ant-radio-inner {
    border-color: #212121;
  }

  .ant-radio+span {
    padding-right: 0;
    padding-left: 8px;
    transform: translateY(3px); // 调整文字位置
    font-size: 14px;
  }
}


// checkbox样式二
.type2 {
  .ant-checkbox-checked .ant-checkbox-inner::before {
    width: 170%;
    height: 1px;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    position: absolute;
    left: 0;
    top: 95%;
  }
}

// 表单将*隐藏，:改为*
.ant-form-item-label {
  line-height: 1 !important;

  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-required::after {
    content: '*';
    display: inline-block !important;
    color: #c1281b;
    font-size: 16px;
  }
}

// 下拉选择
.ant-select {
  .ant-select-arrow {
    .anticon-down {
      color: #212121;
    }
  }
}

//禁止按钮样式
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #5f5f5f;
  color: #333;
  border: none;
}

// 移动端
@media screen and (max-width: 575px) {

  // 表单-标签的样式
  .ant-form-item-label {
    font-size: 16px;
    color: #212529;
    font-weight: bold;
    padding: 0;
    line-height: 20px;
    height: 26px;

    .ant-form-item-required,
    .ant-form-item-no-colon {
      height: 26px;
      line-height: 20px;
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector {
  box-shadow: 0 0 3px 1px #68a8e0;
}

// 去除input的外边框
.ant-input:focus {
  outline: none;
  // border-bottom: 1px solid white !important;
  box-shadow: none;
}

// 分割线样式
.ant-divider {
  border-top: 2px solid #a5a5a5 !important;
}

.ant-divider-horizontal {
  margin: 0px 0 24px !important;
}

// 去除下拉框的外边框
.ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

// 链接按钮
.ant-btn-link>span {
  &:hover {
    text-decoration: underline;
  }
}

// Tabs样式
.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  .ant-tabs-tab {
    background-color: #fff;
    color: #212121;
  }

  // .ant-tabs-tab-active {
  //   background-color: #212121;
  //   .ant-tabs-tab-btn {
  //     color: #fff;
  //   }
  // }
}

// disabled样式
.ant-picker.ant-picker-disabled {
  background: transparent;
}

.ant-picker-input>input[disabled] {
  -webkit-opacity: 1;
  opacity: 1;
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  color: #ccc;
}

.ant-input[disabled] {
  background-color: transparent;
  -webkit-opacity: 1;
  opacity: 1;
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

//安卓浏览器地址栏推上去的时候优化表现
.ant-drawer-mask {
  bottom: -100px;
}

.ant-modal-mask {
  bottom: -100px;
}

.ant-message {
  z-index: 100002;

  .ant-message-notice-content {
    background: rgba(33, 33, 33, 0.6) !important;
    border-radius: 2px !important;

    span {
      font-family: 'FZLanTingHeiS-R-GB';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.466667px;
      color: #FFFFFF;

      &:first-child {
        display: none !important;
      }
    }
  }
}

// drawer z-index:10000
.ant-modal-wrap {
  z-index: 10001;
}

// pc jc 弹框样式
.jc-warm-modal {
  .ant-modal-body {
    padding: 40px 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-close-x {
    color: #212121;
    font-size: 20px;
  }

  .ant-btn {
    width: 200px;
    height: 50px;
    font-size: 14PX;
  }

  .ant-btn-text {
    margin-right: 16px;
    background-color: #fff;
    color: #212121;
    border: 1px solid #212121;
  }

  .ant-btn-primary {
    background: #212121;
    color: #fff;
  }

  .jc-warm-tips {
    color: #212121;
    text-align: center;
    font-size: 18px;
    font-family: 'FZLanTingHeiS-R-GB';
    line-height: 18px;
  }

  .jc-warm-content {
    color: #616161;
    text-align: center;
    font-size: 14px;
    font-family: 'FZLanTingHeiS-R-GB';
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
}

// mob jc 弹框样式
.jcm-warm-modal {
  width: 89vw !important;

  .ant-modal-body {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-close-x {
    color: #212121;
    font-size: 16px;
  }

  .ant-btn {
    width: 35vw;
    height: 50PX;
    font-size: 14PX;
  }

  .ant-btn-text {
    margin-right: 16px;
    background-color: #fff;
    color: #212121;
    border: 1px solid #212121;
  }

  .ant-btn-primary {
    background: #212121;
    color: #fff;
  }

  .jcm-warm-tips {
    color: #212121;
    text-align: center;
    font-size: 16PX;
    font-family: 'FZLanTingHeiS-R-GB';
    line-height: 16PX;
  }

  .jcm-warm-content {
    color: #7A7D80;
    text-align: center;
    font-size: 13PX;
    font-family: 'FZLanTingHeiS-R-GB';
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-top: 20PX;
    margin-bottom: 24px;
  }
}