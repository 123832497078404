@import '@/style/mixin';

.GoodDetaile {
  height: 626px;

  .ant-modal-body {
    display: flex;
    align-items: center;
    padding: 0;

    .section-left {
      width: 594px;
      height: 626px;
      margin-right: 24px;
    }

    .section-right {
      flex: 1;
      overflow: auto;
      @include scrollbar-color(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1));

      .name {
        line-height: 24px;
        font-size: 24px;
        color: rgba(33, 33, 33, 1);
        font-weight: 600;
      }

      .address {
        line-height: 18px;
        font-size: 13px;
        color: rgba(66, 66, 66, 1);
      }

      .info {
        line-height: 18px;
        font-size: 13px;
        color: rgba(33, 33, 33, 1);

        .item {
          display: flex;
          align-items: center;

          &:not(:nth-of-type(1)) {
            margin-top: 14px;
          }
        }
      }

      .time {
        .title {
          line-height: 18px;
          font-size: 16px;
          color: rgba(33, 33, 33, 1);
          font-weight: 600;
        }

        .main {
          margin-top: 8px;
          line-height: 18px;
          font-size: 13px;
          color: rgba(66, 66, 66, 1);
        }
      }
    }
  }
}
