.section {
  margin-top: 30px;
  .title {
    font-size: 16px;
    color: #212121;
  }
  .main {
    margin-top: 20px;
    padding-bottom: 20px;
    overflow: auto;

    font-size: 14px;
    color: #212121;
    .tr {
      box-sizing: border-box;
      display: flex;

      font-weight: 500;
      &:not(:nth-of-type(1)) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
      .td1 {
        width: 155px;
        min-height: 60px;
        padding:10px 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        word-break:break-all;
        word-wrap:break-word;
        white-space:pre-wrap;
        line-height: 1.3;
        color: #212121;
      }

      .td2 {
        //display: inline-block;
        width: 84px;
        min-height: 60px;
        padding:10px 5px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        word-break:break-all;
        word-wrap:break-word;
        white-space:pre-wrap;
        line-height: 1.3;
        color: #212121;
      }
    }
    .gray {
      background-color: #f5f5f5;
    }
  }
}
