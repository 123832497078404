.page-help {
  padding: 36px 0;
  background: #f4f1f3;

  .section-help {
    margin: 0 auto;
    width: 80%;

    .content-help {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}