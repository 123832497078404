.jcform {
  .flexItem {
    .ant-form-item {
      display: inline-block;
      width: 210px;
      margin-bottom: 0;
      vertical-align: middle;
    }

    img,
    button {
      width: 100px;
      height: 40px;
      vertical-align: middle;
    }
  }

  .subscribed {
    margin-bottom: 15px !important;

    .ant-form-item-control-input {
      height: 24px;
      min-height: 24px;
    }
  }

  .agree {
    margin-bottom: 35px !important;

    .ant-form-item-control-input {
      height: 20px;
      min-height: 20px;
    }
  }

  .dob .ant-form-item-control-input-content {
    display: flex;

    .input-select {
      width: 95px;
      cursor: pointer;
    }
  }

  .icon-xialajiantou {
    color: #212121;
    font-size: 20px;
  }

  .sbumitBtn {
    font-family: 'FZLanTingHei-M-GBK';
    letter-spacing: 0.47px;
  }

  .ant-checkbox-wrapper {
    font-size: 13px;
    color: rgba(33, 33, 33, 0.6);
    letter-spacing: 0.47px;
  }

  .c212 {
    color: rgba(33, 33, 33, 0.6);
  }
}