.ant-notification {
    top: 126px !important;
    margin-right: 20px !important;

    .ant-notification-hook-holder {
        .my-wish-notify {
            width: auto !important;
            min-width: 276px;

            padding: 0 !important;

            .ant-notification-notice-content {
                height: 100%;

                .ant-notification-notice-message {
                    margin: 0 !important;
                }

                .ant-notification-notice-description {
                    height: 82px !important;

                    .notifyBox {
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 16px 56px 16px 16px;

                        i.iconfont {
                            margin-right: 10px;
                        }

                        .box-right {
                            flex: 1;
                            color: #242424;

                            .good-name {
                                font-family: Zona Pro;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;

                            }

                            .tip {
                                font-family: FZLanTingHeiS-R-GB;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;

                                span {
                                    cursor: pointer;

                                    text-decoration: underline;
                                }
                            }
                        }

                    }
                }
            }

            .ant-notification-notice-close {
                top: 6px !important;
                right: 6px !important;
            }
        }
    }
}