.customerHtml {
  width: 100%;
  // margin: auto;

  .explore-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    margin: 40px 0 22px;
    font-stretch: expanded;
  }
}