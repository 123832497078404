.FourTabPC {
  width: calc(100% - 240px);

  .tabBox {
    width: calc((100% - 75px) / 4);
    max-width: 246px;
    max-height: 256px;
    background-color: #fafafa;

    &:last-child {
      margin-right: 0;
    }
  }

  .w206 {
    width: 100%;
  }
}