.codeModal {
  box-sizing: border-box;
  width: 315px !important;

  .ant-modal-header {
    padding-bottom: 0;
    border-bottom: none;
  }

  .w140 {
    width: 140px;
    height: 140px;
  }

  .title {
    height: 20px;
    font-family: 'FZLanTingHei-M-GBK';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.466667px;
    color: #212121;
  }

  .ma {
    margin-top: 20px;
    width: 200px;
    height: 200px;
    margin-bottom: 16px;
    object-fit: contain;
  }

  .textBox {
    height: 60px;
  }
}