.ProductSeat {
  // margin-bottom: 60px;
  position: relative;
  width: 100%;

  .jc-title-mb{
    margin-bottom: 25px;
  }
  .jc-title {
    line-height: 28px;
  }
  .slick-list {
    .slick-track {
      display: flex;
      justify-content: center;
    }

    .slick-slide {
      max-width: 26.11vw;
    }
  }

  .productContent {
    margin: auto;
  }

  .title {
    font-style: normal;
    font-size: 40px;
    letter-spacing: 0.466667px;
    color: #212121;
  }
}

.noMargin {
  margin-bottom: 0 !important;
}